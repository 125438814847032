// this is an auto generated file. This will be overwritten

import { gql } from '@apollo/client';
import * as APITypes from './API';

type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onExperimentEvent = /* GraphQL */ `subscription OnExperimentEvent(
  $organizationId: String!
  $experimentId: String!
  $type: String
) {
  onExperimentEvent(
    organizationId: $organizationId
    experimentId: $experimentId
    type: $type
  ) {
    organizationId
    experimentId
    type
    time
    payload
    __typename
  }
}
` as GeneratedSubscription<APITypes.OnExperimentEventSubscriptionVariables, APITypes.OnExperimentEventSubscription>;
export const jobRunStatusChanged =
  /* GraphQL */ `subscription JobRunStatusChanged($organizationId: String!, $jobRunId: ID) {
  jobRunStatusChanged(organizationId: $organizationId, jobRunId: $jobRunId) {
    organizationId
    id
    jobRunId
    time
    payload {
      status
      logUrl
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<APITypes.JobRunStatusChangedSubscriptionVariables, APITypes.JobRunStatusChangedSubscription>;

export const onInstrumentEvent = gql`
  subscription OnInstrumentEvent($instrumentId: ID, $types: [String!]) {
    onInstrumentEvent(instrumentId: $instrumentId, types: $types) {
      ... on InstrumentExperimentSettingsUpdateSucceededV1 {
        comcellanomeorg
        source
        id
        time
        type
        data {
          settings {
            category
            variables {
              name
              type
              description
              value
            }
          }
          experiment {
            id
          }
        }
      }
    }
  }
`;
