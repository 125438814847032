import { useMemo } from 'react';

import { EChartType } from '@/types/charts';

import type { TPresetSettings } from './types';

export const useViolinChartSettingsPreset = (_chartDataList: TDatasetDetails[]): Nullable<Partial<TPresetSettings>> =>
  useMemo(
    () => ({
      plotType: EChartType.violin,
      isReady: true,
    }),
    []
  );
