import { FC, useMemo } from 'react';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { useExperimentContext, useParamsExperimentId } from '@/hooks';
import { removeDuplicates } from '@/helpers';
import { datasetsSelectors } from '@/store/slices/datasets';

import styles from './Assays.module.scss';
import AssayCard from './AssayCard';

const cn = classnames.bind(styles);

const Assays: FC = () => {
  const experimentId = useParamsExperimentId();
  const { currentExperimentAssays } = useExperimentContext();
  const assaysProperties = useSelector(datasetsSelectors.selectAssayProperties(experimentId));
  const includedChannels = useSelector(datasetsSelectors.selectIncludedChannels);
  const includedTimePoints = useSelector(datasetsSelectors.selectIncludedTimePoints);

  const assayList = useMemo<TAssay[]>(() => {
    const assays = removeDuplicates<TAssay>(Object.values(currentExperimentAssays).flat(), 'id');
    const assaysWithProps = assays
      .map((assay) => ({
        ...assay,
        properties: {
          ...assaysProperties.get(assay.name),
        },
      }))
      .filter((assay) => assay.properties?.timePoints?.some((el: string) => includedTimePoints.includes(el)))
      .filter((assay) => assay.properties?.channels?.some((el: string) => includedChannels.includes(el)));
    return assaysWithProps;
  }, [currentExperimentAssays, includedChannels, includedTimePoints, assaysProperties]);

  return (
    <div className={cn('assays__cards-container')}>
      {assayList.map((assay) => (
        <AssayCard key={assay.id} className={cn('assay-card__panel')} assay={assay} />
      ))}
    </div>
  );
};

export default Assays;
