import { min as getMinDate, max as getMaxDate } from 'date-fns';

import { DEFAULT_TITLE_FONT_LAYOUT } from '@/components/charts/DownloadChartButton/prepareLayouts';

import { getMinMax } from '@/helpers/arrays';
import { getAxisGap } from '@/helpers/axisScaleHelper/helpers';
import { capitalizeFirstLetter, toFixed } from '@/helpers';

import { LAYOUT_CONFIG } from '@/pages/Dataset/constants';
import { formatDateTime } from '@/pages/Home/helpers';
import { CATEGORICAL_D3_COLORSCALES } from '@/helpers/charts/colorscales';

import { TSensorsDataForTimeline } from '../HistoricalSensors/types';

export const DEFAULT_COLORSCALE = CATEGORICAL_D3_COLORSCALES.schemeCategory10;

const SENSORS_WITH_SQUARE_CHART = ['Pipette.TempCount1', 'Pipette.TempCount2'];

export const getXValues = (sensorData: TTelemetryBySensorFromServer[]) =>
  sensorData.map((sensor) => formatDateTime(sensor?.time, 'yyyy-MM-dd HH:mm') ?? '');

export const getYValues = (sensorData: TTelemetryBySensorFromServer[]) =>
  sensorData.map((sensor) => {
    if (sensor?.value && sensor?.type === 'string') {
      return capitalizeFirstLetter(String(sensor.value).toLowerCase() ?? '').replace('_', ' ');
    }

    return toFixed(Number(sensor?.value));
  });

export const isStringYAxis = (sensorData: TTelemetryBySensorFromServer[]) =>
  sensorData?.every((sensor) => sensor?.type === 'string');

export const getXAxisRange = (xValues: string[]) => {
  const dateObjects = xValues.map((x) => (x ? new Date(x) : new Date()));
  const minDate = getMinDate(dateObjects);
  const maxDate = getMaxDate(dateObjects);
  return [minDate, maxDate];
};

export const getYAxisRange = (yValues: (string | number)[], isString: boolean) => {
  if (isString) {
    const mapByKey = [...new Set(yValues).values()];

    // consider all unique string values when forming a range
    const maxRange = Object.keys(mapByKey).length - 1;
    return [-0.1, maxRange + 0.1];
  }
  const { min, max } = getMinMax(yValues as number[]);
  const valDiff = max - min;
  const gap = getAxisGap(valDiff);

  return [min - gap, max + gap];
};

export const getYAxisPostfix = (sensorData: TTelemetryBySensorFromServer[]) => {
  if (!sensorData.length || !sensorData[0]?.postfix) return '';

  return ` ${sensorData[0].postfix}`;
};

const getLayoutConfig = ({
  yAxisPostfix,
  index,
  xAxisRange,
  yAxisRange,
  isGeneralYAxisSetting,
}: {
  yAxisPostfix: string;
  index: number;
  xAxisRange: Date[];
  yAxisRange: Nullable<number[]>;
  isGeneralYAxisSetting: boolean;
}) => {
  const x = {
    ...LAYOUT_CONFIG().xaxis,
    color: '#000000',
    nticks: 5,
    type: 'date',
    title: {
      ...LAYOUT_CONFIG().xaxis.title,
      text: 'Time',
      font: {
        ...DEFAULT_TITLE_FONT_LAYOUT,
        size: 17,
      },
    },
    autorange: true,
    automargin: true,
    range: xAxisRange,
    ...(index === 0 && {
      rangeslider: {
        autorange: true,
        thickness: 0.1,
      },
    }),
    // https://plotly.com/javascript/tick-formatting/#tickformatstops-to-customize-for-different-zoom-levels
    tickformatstops: [
      {
        dtickrange: [null, 60000],
        value: '%Y-%m-%d %H:%M:%S',
      },
      {
        dtickrange: [60000, 3600000],
        value: '%Y-%m-%d %H:%M',
      },
      {
        dtickrange: [3600000, 86400000],
        value: '%y-%m-%d %H:%M',
      },
      {
        dtickrange: [86400000, 604800000],
        value: '%Y-%m-%d',
      },
      {
        dtickrange: [604800000, 'M1'],
        value: '%Y-%m-%d',
      },
      {
        dtickrange: ['M1', 'M12'],
        value: '%b %Y',
      },
      {
        dtickrange: ['M12', null],
        value: '%Y',
      },
    ],
  };

  const y = {
    ...LAYOUT_CONFIG().yaxis,
    griddash: 'dot',
    showgrid: !isStringYAxis,
    color: '#000000',
    ticksuffix: yAxisPostfix,
    fixedrange: true,
    title: {
      ...LAYOUT_CONFIG().yaxis.title,
      font: {
        ...DEFAULT_TITLE_FONT_LAYOUT,
        size: 17,
      },
    },
    automargin: true,
    autorange: true,
    range: yAxisRange,
    ...(index === 1 &&
      !isGeneralYAxisSetting && {
        side: 'right',
        overlaying: 'y',
      }),

    ...(index > 1 &&
      !isGeneralYAxisSetting && {
        showticklabels: false,
        overlaying: 'y',
      }),
  };

  return { x, y };
};

export const getChartConfigs = (selectedSensorsDataList: TSensorsDataForTimeline[]) => {
  const layout: TPlotLayout = {
    ...LAYOUT_CONFIG(),
    margin: {
      l: 20,
      r: 20,
      t: 0,
      b: 0,
      pad: 0,
    },
    dragmode: true,
  };

  const traces: TPlotData[] = [];

  const specificTimelineDataYAxis = [
    ...new Set(selectedSensorsDataList.map((sensorData) => `${sensorData.sensor.type}_${sensorData.sensor.postfix}`)),
  ];

  selectedSensorsDataList.forEach((sensorData, index) => {
    const currentSensor = sensorData.sensor;

    if (!currentSensor) return;

    const xValues = getXValues(sensorData.timelineData ?? []);
    const yValues = getYValues(sensorData.timelineData ?? []);
    const isStringY = isStringYAxis(sensorData.timelineData ?? []);
    const isGeneralYAxisSetting = specificTimelineDataYAxis.length === 1 && !isStringY;

    const xAxisRange = getXAxisRange(xValues);
    const yAxisRange = isGeneralYAxisSetting ? null : getYAxisRange(yValues, isStringY);

    const yAxisPostfix = getYAxisPostfix(sensorData.timelineData);
    const yAxisTitle = `${sensorData.timelineData?.[0]?.label ?? ''} ${yAxisPostfix}`;

    const axisNumberPostfix = index === 0 ? '' : index + 1;
    const axisName = `axis${axisNumberPostfix}`;

    const { x, y } = getLayoutConfig({
      yAxisPostfix,
      index,
      xAxisRange,
      yAxisRange,
      isGeneralYAxisSetting,
    });

    layout[`x${axisName}`] = x;

    layout[`y${axisName}`] = y;

    const lineColor: string = selectedSensorsDataList.length === 1 ? '#56e5f1' : DEFAULT_COLORSCALE[index][1];

    const trace = {
      x: xValues,
      y: yValues,
      ...(!isGeneralYAxisSetting && { yaxis: `y${axisNumberPostfix}` }),
      customdata: currentSensor?.name ?? '',
      name: `${yAxisTitle}`,
      mode: 'lines+markers',
      type: 'scatter',
      marker: {
        color: lineColor,
      },
      line: {
        color: lineColor,
        width: 2,
        shape: isStringY || SENSORS_WITH_SQUARE_CHART.includes(currentSensor?.name) ? 'hv' : 'linear',
      },
      showlegend: false,
    };

    traces.push(trace);
  });

  return { traces, layout };
};
