import { formatNumber, roundPercentage } from '@/helpers/common';

export const prepareSummaryValue = (key: string, value: number) =>
  key.toLowerCase().includes('percentage') ? `${roundPercentage(value)}%` : formatNumber(value);

export const getInstrumentDashboardLink = (experimentData?: TExperimentFromServer) => {
  if (!experimentData?.instrument?.id) return null;

  const defaultLink = `/instrument/${experimentData.instrument.id}`;
  const params = new URLSearchParams();

  if (experimentData.instrument.name) {
    params.set('name', experimentData.instrument.name);
  }
  if (experimentData.startedAt) {
    params.set('startDate', experimentData.startedAt);
  }

  if (experimentData.currentStatus?.timestamp && experimentData.currentStatus.status === 'COMPLETED') {
    params.set('endDate', experimentData.currentStatus?.timestamp);
  }

  return `${defaultLink}?${params.toString()}`;
};

export const getExperimentProjectsDataToDisplay = (projects?: TExperimentProjectItem[]) => {
  if (!projects) return ['', ''];

  const names = projects.map(({ name }: TExperimentProjectItem) => name).join(' ');
  return [names, projects.length > 1 ? 's' : ''];
};
