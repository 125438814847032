import { useCallback, useState, FC, ReactNode, useMemo, ComponentType } from 'react';
import { Popover as TinyPopover } from 'react-tiny-popover';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';
import { MDASH } from '@/helpers';
import { themeOptions } from '@/types/theme';

import styles from './DetailsPopover.module.scss';
import DetailsAsArray from './DetailsAsArray';
import Button, { TButton } from '../Button';
import NoDataFound from '../NoDataFound';

const cn = classnames.bind(styles);

export type TDetailsFields = {
  label: string;
  isFullWidth?: boolean;
  value?: string | Record<string, string>[];
  className?: string;
};

type TDetailsPopoverProps = {
  details: TDetailsFields[];
  theme?: themeOptions;
  tooltip?: string;
  children?: ReactNode;
  showOnHover?: boolean;
  CustomButton?: ComponentType<TButton>;
  className?: string;
};

const DetailsPopover: FC<TDetailsPopoverProps> = ({
  details,
  theme = themeOptions.light,
  tooltip,
  children,
  showOnHover,
  CustomButton,
  className,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const togglePopover = useCallback(() => setIsOpen((prev) => !prev), []);
  const closePopover = useCallback(() => setIsOpen(false), []);
  const openPopover = useCallback(() => setIsOpen(true), []);

  const onMouseEnter = useMemo(() => (showOnHover ? openPopover : undefined), [showOnHover]);
  const onMouseLeave = useMemo(() => (showOnHover ? closePopover : undefined), [showOnHover]);

  const generateDetailsValue = (detail: string | Record<string, string>[]) => {
    if (typeof detail === 'string' || typeof detail === 'number') {
      return <div>{detail}</div>;
    }

    if (Array.isArray(detail)) {
      return <DetailsAsArray detail={detail} />;
    }

    return <NoDataFound textData={MDASH} alignment="left" />;
  };

  return (
    <TinyPopover
      onClickOutside={closePopover}
      isOpen={isOpen}
      align="end"
      padding={5}
      content={
        <div className={cn('details', `details_${theme}`, className)}>
          <div className={cn('details__wrapper')}>
            <div className={cn('details__content')}>
              {details.map((detailsInfo) => (
                <div
                  className={cn(
                    'content-block',
                    { 'content-block_full-width': detailsInfo.isFullWidth },
                    detailsInfo.className
                  )}
                  key={detailsInfo.label}
                >
                  <div className={cn('content-block__label')}>{detailsInfo.label}</div>
                  {detailsInfo.value ? generateDetailsValue(detailsInfo.value) : MDASH}
                </div>
              ))}
            </div>
          </div>
        </div>
      }
      containerStyle={{ marginTop: '20px' }}
      boundaryInset={40}
      reposition
    >
      {/* without div here popover is drawn far from button and JS error fails:
     Warning: Function components cannot be given refs. Attempts to access this ref will fail */}
      <div className={cn('button-wrapper')}>
        {showOnHover && (
          <div role="presentation" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className={cn('trigger')}>
            {children || <icons.MenuIcon width={20} />}
          </div>
        )}

        {!showOnHover && (
          <>
            {CustomButton && <CustomButton onClick={togglePopover} tooltip={tooltip} />}
            {!CustomButton && (
              <Button
                isFitContent
                isEmptyStyles
                className={cn('button')}
                aria-label="expand"
                onClick={togglePopover}
                tooltip={tooltip}
              >
                {children || <icons.MenuIcon width={20} />}
              </Button>
            )}
          </>
        )}
      </div>
    </TinyPopover>
  );
};

export default DetailsPopover;
