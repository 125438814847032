import { FC, memo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useSettingsNotification } from '@/hooks/plotSettings/useSettingsNotification';
import { themeOptions } from '@/types/theme';
import { EGatesLabelFormat } from '@/types/gateSettings';
import { EPageWithChartType } from '@/types/charts';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { gatesActions, gatesSelectors } from '@/store/slices/gates';

import Select from '@/components/common/Select';

import styles from '../SettingsPopover.module.scss';
import { TSettingsPopover } from '../types';

const options = [
  {
    value: EGatesLabelFormat.none,
    label: 'None',
  },
  {
    value: EGatesLabelFormat.nameAndPercent,
    label: 'Gate name and Percent',
  },
  {
    value: EGatesLabelFormat.name,
    label: 'Gate name',
  },
  {
    value: EGatesLabelFormat.percent,
    label: 'Percent',
  },
];

const cn = classnames.bind(styles);

const GateLabelSetting: FC<Pick<TSettingsPopover, 'pageType'>> = ({ pageType }) => {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();
  const gateLabelFormat = useSelector(gatesSelectors.selectGateLabelFormat(chartId));
  const fullScreenChartData = useSelector(chartSettingsSelectors.selectFullScreenChartData);

  const { isSettingsChangingConfirmed } = useSettingsNotification({ pageType });

  const handleLabelFormatChange = useCallback(
    async (newFormat: EGatesLabelFormat) => {
      if (pageType === EPageWithChartType.matrixView) {
        const isConfirmed = await isSettingsChangingConfirmed();

        if (!isConfirmed) {
          return;
        }
      }

      appDispatch(
        gatesActions.setGateLabelFormat({ format: newFormat, fullScreenChartDataId: fullScreenChartData?.id })
      );
    },
    [fullScreenChartData, isSettingsChangingConfirmed]
  );

  return (
    <div className={cn('section')}>
      <div className={cn('section__head')}>
        <span>Gate labels format</span>
      </div>
      <Select
        onChange={handleLabelFormatChange}
        value={gateLabelFormat}
        options={options}
        placeholder="Label type"
        className={cn('section__select', 'gate-label-format-select')}
        theme={themeOptions.light}
        defaultValue={gateLabelFormat}
      />
    </div>
  );
};

export default memo(GateLabelSetting);
