import { FC, useMemo } from 'react';
import classnames from 'classnames/bind';
import { formatDate } from '@/helpers';
import MemberList from '@/pages/ProjectList/MemberList';
import icons from '@/components/common/icons';
import { Link } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { formatHighlightSearchQuery } from '@/helpers/formatHighlightSearchQuery';
import ProjectOptionsPopoverWrapper from '@/pages/ProjectList/ProjectOptionsPopoverWrapper';
import { useProjectListContext } from '@/hooks/useProjectListContext';
import styles from './ProjectCard.module.scss';
import { getProjectOwnerData } from '../helpers';

const cn = classnames.bind(styles);

export type TProjectCardProps = {
  project: TProject;
};

const ProjectCard: FC<TProjectCardProps> = ({ project }) => {
  const { label, name, dateModified, collaborators, id: projectId, ownedBy, experimentCount } = project;
  const { searchQuery } = useProjectListContext();

  const projectOwner = useMemo(() => getProjectOwnerData(ownedBy), [ownedBy]);

  const pluralSymbol = useMemo(() => (experimentCount > 1 ? 's' : ''), [experimentCount]);

  return (
    <ProjectOptionsPopoverWrapper project={project}>
      <Link to={`/project/${projectId}`} className={cn('project-card')} id={projectId}>
        <div className={cn('project-card__label')}>{label}</div>
        <div className={cn('project-card__wrap')}>
          <div className={cn('project-card__title')}>
            <Highlighter textToHighlight={name} searchWords={formatHighlightSearchQuery(searchQuery)} />
          </div>
          <div className={cn('project-card__content')}>
            <div className={cn('project-card__date')}>{formatDate(dateModified)}</div>
            <div className={cn('project-card__member-data')}>
              <div className={cn('project-card__member-full-name')}>
                <MemberList name={projectOwner} />
                <div className={cn('project-card__member-name')}>
                  <Highlighter textToHighlight={projectOwner} searchWords={formatHighlightSearchQuery(searchQuery)} />
                </div>
              </div>
              <MemberList nameList={collaborators} />
            </div>
          </div>
          <div className={cn('project-card__experiments')}>
            <div className={cn('project-card__experiments-number')}>{experimentCount}</div>
            <div className={cn('project-card__experiments-text')}>{`Experiment${pluralSymbol}`}</div>
            <div className={cn('project-card__experiments-icon-wrap')}>
              <icons.ProjectCardArrowIcon />
            </div>
          </div>
        </div>
      </Link>
    </ProjectOptionsPopoverWrapper>
  );
};

export default ProjectCard;
