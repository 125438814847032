import { FC, ReactNode, useState, useRef, useEffect } from 'react';
import classnames from 'classnames/bind';

import LineLoader from '@/components/common/LineLoader';

import styles from './ControlPanel.module.scss';

const cn = classnames.bind(styles);

type TControlPanelSlotProps = {
  className?: string;
  children: ReactNode;
};

const ControlPanelStickyContent: FC<TControlPanelSlotProps> = ({ className, children }) => (
  <div className={cn('control-panel__sticky-content', className)}>{children}</div>
);
const ControlPanelStickyReveal: FC<TControlPanelSlotProps> = ({ className, children }) => (
  <div className={cn('control-panel__sticky-reveal', className)}>{children}</div>
);
const ControlPanelLeftActions: FC<TControlPanelSlotProps> = ({ className, children }) => (
  <div className={cn('control-panel__actions', 'control-panel__actions_left', className)}>{children}</div>
);
const ControlPanelRightActions: FC<TControlPanelSlotProps> = ({ className, children }) => (
  <div className={cn('control-panel__actions', 'control-panel__actions_right', className)}>{children}</div>
);
const ControlPanelFilterList: FC<TControlPanelSlotProps> = ({ className, children }) => (
  <div className={cn('control-panel__filter-list', className)}>{children}</div>
);

type TControlPanelProps = {
  isLoading?: boolean;
  className?: string;
  children?: ReactNode;
  size?: 'small' | 'default';
};

type TControlPanelExtensions = {
  StickyContent: typeof ControlPanelStickyContent;
  StickyReveal: typeof ControlPanelStickyReveal;
  LeftActions: typeof ControlPanelLeftActions;
  RightActions: typeof ControlPanelRightActions;
  FilterList: typeof ControlPanelFilterList;
};

const ControlPanel: FC<TControlPanelProps> & TControlPanelExtensions = ({
  isLoading = false,
  className,
  children,
  size = 'default',
}) => {
  const targetRef = useRef<Nullable<HTMLDivElement>>(null);

  const [isStucked, setIisStucked] = useState(false);

  const handleScroll = () => {
    if (!targetRef.current) return;

    const { top = 0 } = targetRef.current.getBoundingClientRect();
    setIisStucked(top === 0);
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={cn('control-panel', className, { 'control-panel_stucked': isStucked })} ref={targetRef} data-anchor>
      <div className={cn('control-panel__content', { 'control-panel__content_small': size === 'small' })}>
        {children}
      </div>

      <LineLoader isLoading={isLoading} className={cn('control-panel__line-loader')} />
    </div>
  );
};

ControlPanel.StickyContent = ControlPanelStickyContent;
ControlPanel.StickyReveal = ControlPanelStickyReveal;
ControlPanel.LeftActions = ControlPanelLeftActions;
ControlPanel.RightActions = ControlPanelRightActions;
ControlPanel.FilterList = ControlPanelFilterList;

export default ControlPanel;
