import { EMarkerSizes, EModebarTools, EChartType, EPlotlyChartType } from '@/types/charts';
import { DEFAULT_TITLE_FONT_LAYOUT } from '@/components/charts/DownloadChartButton/prepareLayouts';

export const LAYOUT_CONFIG = () => ({
  margin: {
    l: 50,
    r: 0,
    t: 30,
    b: 50,
    pad: 0,
  },
  xaxis: {
    color: '#989898',
    linecolor: '#000',
    showgrid: false,
    ticklen: 4,
    tickcolor: '#000000',
    zeroline: false,
    title: {
      text: '',
      font: {
        ...DEFAULT_TITLE_FONT_LAYOUT,
      },
    },
  },
  yaxis: {
    color: '#989898',
    linecolor: '#ffffff',
    showgrid: true,
    gridcolor: '#e6e6e6',
    showticklabels: true,
    zeroline: false,
    title: {
      text: '',
      automargin: true,
      font: {
        ...DEFAULT_TITLE_FONT_LAYOUT,
      },
    },
  },
  autosize: true,
  paper_bgcolor: '#ffff',
  dragmode: false,
});

export const CONFIG = () => ({
  displaylogo: false,
  scrollZoom: false,
  responsive: true,
  autosize: true,
  displayModeBar: false,
  showAxisDragHandles: false,
  showAxisRangeEntryBoxes: true,
  doubleClick: false,
  edits: {
    annotationPosition: false,
    annotationText: false,
    axisTitleText: false,
    colorbarTitleText: false,
    legendPosition: false,
    shapePosition: true,
    titleText: false,
  },
});

export const HISTOGRAM_DATA_CONFIG = () => ({
  type: EPlotlyChartType.histogram,
  hovertemplate: '%{y} <extra></extra>',
  hoverinfo: 'all',
  marker: {
    color: '#01a7e9',
    size: 0,
    line: {
      color: '#01a7e9',
      width: 1,
    },
  },
});

export const LINE_HISTOGRAM_DATA_CONFIG = () => ({
  type: EPlotlyChartType.scattergl,
  mode: 'lines',
  line: {
    shape: 'spline',
    smoothing: 1.3,
    color: '#01a7e9',
  },
  transforms: [
    {
      type: 'sort',
      target: 'x',
      order: 'ascending',
    },
  ],
  marker: {
    color: 'rgba(0,0,0,0)',
    size: 0,
    line: {
      color: '#000000',
      width: 1,
    },
  },
});

export const SCATTERGL_DATA_CONFIG = () => ({
  mode: 'markers' as const,
  type: EPlotlyChartType.scattergl,
  selectedpoints: null,
  marker: {
    color: '#1f1f1f',
    size: EMarkerSizes.default,
    line: {
      width: 0,
    },
  },
  hoverinfo: 'none',
  unselected: {
    marker: {
      opacity: 0.5,
    },
  },
  selected: {
    marker: {
      size: EMarkerSizes.highlighted,
      color: '#5a4ccd',
    },
  },
});

export const VIOLIN_DATA_CONFIG = () => ({
  type: EPlotlyChartType.violin,
  ...CONFIG(),
  scrollZoom: true,
});

export const KNEE_DATA_CONFIG = () => ({
  type: EPlotlyChartType.scattergl,
  ...CONFIG(),
  scrollZoom: true,
});

export const BASE_DATA_CONFIG = () => ({
  hoverinfo: 'none',
  unselected: {
    marker: {
      opacity: 1,
    },
  },
  showlegend: false,
  showscale: false,
});

export const DRAGMODES: Record<EModebarTools, string | boolean> = {
  [EModebarTools.rectangle]: false,
  [EModebarTools.polygon]: false,
  [EModebarTools.ellipse]: false,
  [EModebarTools.polar]: false,
  [EModebarTools.splitGate]: false,
  [EModebarTools.range]: false,
  [EModebarTools.zoom]: 'zoom',
  [EModebarTools.gateInteractions]: 'pan',
};

export const dataConfigByTypes: Record<EChartType, Record<string, unknown>> = {
  dot: { ...BASE_DATA_CONFIG(), ...SCATTERGL_DATA_CONFIG() },
  dotDensity: { ...BASE_DATA_CONFIG(), ...SCATTERGL_DATA_CONFIG() },
  histogram2d: {
    ...BASE_DATA_CONFIG(),
    type: EPlotlyChartType.histogram2d,
  },
  histogram2dcontour: {
    ...BASE_DATA_CONFIG(),
    type: EPlotlyChartType.histogram2dcontour,
    ncontours: 10,
  },
  histogram: {
    ...BASE_DATA_CONFIG(),
    ...HISTOGRAM_DATA_CONFIG(),
  },
  lineHistogram: {
    ...BASE_DATA_CONFIG(),
    ...LINE_HISTOGRAM_DATA_CONFIG(),
  },
  heatmap: {
    ...BASE_DATA_CONFIG(),
    type: EPlotlyChartType.heatmap,
  },
  violin: {
    ...BASE_DATA_CONFIG(),
    ...VIOLIN_DATA_CONFIG(),
  },
  knee: {
    ...BASE_DATA_CONFIG(),
    ...KNEE_DATA_CONFIG(),
  },
};
