import { FC, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames/bind';

import { useUserRole } from '@/hooks';

import Button from '@/components/common/Button';

import UsersIcon from '@/components/common/icons/iconList/UsersIcon';
import TeamsIcon from '@/components/common/icons/iconList/TeamsIcon';
import JobsIcon from '@/components/common/icons/iconList/JobsIcon';
import ProjectsIcon from '@/components/common/icons/iconList/ProjectsIcon';
import HamburgerIcon from '@/components/common/icons/iconList/HamburgerIcon';
import InstrumentsDashboardIcon from '@/components/common/icons/iconList/InstrumentsDashboardIcon';

import { ReactComponent as Logo } from '@/icons/Logo.svg';
import { addTooltip } from '@/helpers';

import styles from './Aside.module.scss';

const cn = classnames.bind(styles);

type TAside = {
  className?: string;
};

const Aside: FC<TAside> = ({ className }) => {
  const { isJobRunReadAllowed, isUserReadAllowed, isTeamReadAllowed, isProjectReadAllowed, isInstrumentReadAllowed } =
    useUserRole();

  const { navList: asideNavList } = useMemo(() => {
    const navList: { link: string; tooltip: string; icon: React.ReactNode }[] = [];

    if (isProjectReadAllowed) {
      navList.push({ link: '/projects', tooltip: 'Projects', icon: <ProjectsIcon /> });
    }
    if (isJobRunReadAllowed) {
      navList.push({ link: '/jobs-dashboard', tooltip: 'Jobs Dashboard', icon: <JobsIcon /> });
    }
    if (isUserReadAllowed) {
      navList.push({ link: '/users-dashboard', tooltip: 'Users Dashboard', icon: <UsersIcon /> });
    }
    if (isTeamReadAllowed) {
      navList.push({ link: '/teams-dashboard', tooltip: 'Teams Dashboard', icon: <TeamsIcon /> });
    }
    if (isInstrumentReadAllowed) {
      navList.push({ link: '/instruments', tooltip: 'Instruments Dashboard', icon: <InstrumentsDashboardIcon /> });
    }

    return { navList };
  }, [isUserReadAllowed, isTeamReadAllowed, isJobRunReadAllowed, isProjectReadAllowed, isInstrumentReadAllowed]);

  return (
    <aside className={cn('aside-menu', className)}>
      <NavLink to="/" aria-label="Home" className={cn('aside-menu__logo-link')}>
        <Logo className={cn('aside-menu__logo')} />
      </NavLink>

      {!!asideNavList.length && (
        <nav className={cn('aside-menu__nav', 'aside-nav')}>
          {asideNavList.map((navItem) => (
            <NavLink
              key={navItem.link}
              to={navItem.link}
              className={({ isActive }) =>
                cn('aside-nav__item', {
                  'aside-nav__item_active': isActive,
                })
              }
              aria-label={navItem.tooltip}
              {...addTooltip(navItem.tooltip, 'right')}
            >
              {navItem.icon}
            </NavLink>
          ))}
        </nav>
      )}

      <Button color="light" className={cn('aside-menu__button')}>
        <HamburgerIcon />
      </Button>
    </aside>
  );
};

export default Aside;
