export const searchProjects = (searchQuery: string, projectList: TProject[] | undefined) => {
  if (!searchQuery || !projectList?.length) {
    return projectList;
  }

  const searchKeys = ['name', 'owner', 'email'] as const;

  return projectList.filter((project) => {
    const preparedData = {
      name: project.name,
      owner: project.ownedBy.name,
      email: project.ownedBy.email ?? '',
    };

    return searchKeys.some((key) => preparedData[key]?.toLowerCase().includes(searchQuery.toLowerCase()));
  });
};
