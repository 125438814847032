import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { themeOptions } from '@/types/theme';
import { EHistogramDataGroupTypes, EPageWithChartType, EChartType, TChartTypesForSelect } from '@/types/charts';

import { chartSettingsActions, chartSettingsSelectors } from '@/store/slices/chartSettings';
import { histogramSettingsActions, histogramSettingsSelectors } from '@/store/slices/histogramSettings';

import { isHistogramChartType } from '@/helpers/charts/chartsType';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useSettingsNotification } from '@/hooks/plotSettings/useSettingsNotification';

import CheckboxInput from '@/components/common/CheckboxInput';
import Select from '@/components/common/Select';
import Range from '@/components/common/Range';

import { TSettingsPopover } from './types';

import { getAvailableChartTypeList } from './helpers';

import AxesScaleType from './components/AxesScaleType';
import ColorScaleSetting from './components/ColorScaleSetting';
import DotDensitySettings from './components/DotDensitySettings';
import HeatmapSettings from './components/HeatmapSettings';
import HistogramsSettings from './components/HistogramsSettings';

import styles from './SettingsPopover.module.scss';
import CustomRanges from './components/CustomRanges';
import GateLabelSetting from './components/GateLabelSetting';
import { groupOptionsByHistogramType } from './constants';
import DotSettings from './components/ScatterglSettings';

const cn = classnames.bind(styles);

const SettingsPopover: FC<TSettingsPopover> = ({
  pageType,
  className = '',
  isSortByDatasetData = false,
  toggleIsSortByDatasetData = () => null,
  heatmapSortByOptions = [],
  heatmapSortBySelectedOption,
  heatmapSetSortBySelectedOption,
  scatterPlotAxesOptions,
}) => {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));
  const isLogAxesScaleTypeAllowed = useSelector(chartSettingsSelectors.selectIsLogAxesScaleTypeAllowed(chartId));
  const currentHistogramDataGroupType = useSelector(
    histogramSettingsSelectors.selectCurrentHistogramDataGroupType(chartId)
  );
  const fullScreenChartData = useSelector(chartSettingsSelectors.selectFullScreenChartData);
  const isTickLabelsVisible = useSelector(chartSettingsSelectors.selectIsTickLabelsVisible(chartId));
  const contourBandWidth = useSelector(chartSettingsSelectors.selectContourBandWidth(chartId));

  const isHistogramSettingsVisible = useMemo(() => isHistogramChartType(currentChartType), [currentChartType]);

  const isHeatmapSettingsVisible = useMemo(() => currentChartType === EChartType.heatmap, [currentChartType]);
  const isDensityBandWidthVisible = useMemo(
    () =>
      currentChartType === EChartType.dotDensity && pageType !== EPageWithChartType.matrixView && !fullScreenChartData,
    [currentChartType, pageType, fullScreenChartData]
  );

  const handleContourBandWidthChange = (value: number | number[]) => {
    appDispatch(chartSettingsActions.setContourBandWidth(+value));
  };

  const handleIsTickLabelsVisibleClick = () => {
    appDispatch(chartSettingsActions.toggleIsTickLabelsVisible());
  };

  const plotTypeOptionList = useMemo<TOption[]>(() => {
    const plotTypeLabelMap: Record<TChartTypesForSelect, string> = {
      [EChartType.dot]: 'Dot',
      [EChartType.dotDensity]: 'Dot Density',
      [EChartType.histogram2d]: 'Histogram Density',
      [EChartType.histogram2dcontour]: 'Contour',
      [EChartType.histogram]: 'Histogram',
      [EChartType.lineHistogram]: 'Line Histogram',
    };

    return getAvailableChartTypeList(pageType).map((plotType) => ({
      label: plotTypeLabelMap[plotType as keyof typeof plotTypeLabelMap],
      value: plotType,
    }));
  }, [pageType]);

  const { isSettingsChangingConfirmed } = useSettingsNotification({ pageType });

  const handleCurrentChartTypeChange = async (value: string) => {
    const isConfirmed = await isSettingsChangingConfirmed();

    if (!isConfirmed) {
      return;
    }

    appDispatch(chartSettingsActions.setCurrentChartType(value));
    if (value !== EChartType.histogram) return;

    const isGroupOptionsIncludesCurrentType = groupOptionsByHistogramType[value].find(
      (option: TBasicOption) => option.label === currentHistogramDataGroupType
    );

    if (!isGroupOptionsIncludesCurrentType) {
      appDispatch(
        histogramSettingsActions.setCurrentHistogramDataGroupType({
          type: EHistogramDataGroupTypes.thresholdFreedmanDiaconis,
          fullScreenChartDataId: fullScreenChartData?.id,
        })
      );
    }
  };

  return (
    <div className={cn('popover', className)}>
      {currentChartType !== EChartType.heatmap && (
        <>
          <div className={cn('section')}>
            <div className={cn('section__head')}>
              <span>Plot type</span>
            </div>
            <Select
              value={currentChartType}
              placeholder=""
              className={cn('section__select', 'plot-type-select')}
              controlClassName={cn('section__select')}
              theme={themeOptions.light}
              defaultValue={currentChartType}
              onChange={handleCurrentChartTypeChange}
              options={plotTypeOptionList}
            />
          </div>
          <GateLabelSetting pageType={pageType} />
        </>
      )}

      {isLogAxesScaleTypeAllowed && <AxesScaleType pageType={pageType} />}

      {currentChartType === EChartType.dot && (
        <DotSettings scatterPlotAxesOptions={scatterPlotAxesOptions} pageType={pageType} />
      )}

      <ColorScaleSetting pageType={pageType} />

      {currentChartType === EChartType.histogram2dcontour && contourBandWidth !== undefined && (
        <div className={cn('section')}>
          <Range
            label="Contour range"
            onChange={handleContourBandWidthChange}
            min={5}
            max={50}
            step={10}
            value={contourBandWidth}
            infoBlockClassName={cn('sidebar__range-info')}
            className={cn('sidebar__range')}
            rangeHeight={4}
          />
        </div>
      )}
      {currentChartType !== EChartType.heatmap && (
        <div className={cn('section')}>
          <CustomRanges pageType={pageType} />
        </div>
      )}
      {isDensityBandWidthVisible && <DotDensitySettings />}
      {(isHistogramSettingsVisible || isHeatmapSettingsVisible) && (
        <div className={cn('section', 'section_multiple')}>
          {isHistogramSettingsVisible && <HistogramsSettings pageType={pageType} />}
          <div className={cn('section__content')}>
            <CheckboxInput
              checked={isTickLabelsVisible}
              onChange={handleIsTickLabelsVisibleClick}
              className={cn('checkbox')}
              theme="light"
              label="Show tick labels"
              isLabelAtLeft
            />
          </div>

          {isHeatmapSettingsVisible && (
            <HeatmapSettings
              isSortByDatasetData={isSortByDatasetData}
              toggleIsSortByDatasetData={toggleIsSortByDatasetData}
              heatmapSortByOptions={heatmapSortByOptions}
              heatmapSortBySelectedOption={heatmapSortBySelectedOption}
              heatmapSetSortBySelectedOption={heatmapSetSortBySelectedOption}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SettingsPopover;
