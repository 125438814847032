import {
  CSSProperties,
  Dispatch,
  FC,
  memo,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames/bind';
import 'react-datepicker/dist/react-datepicker.css';
import Skeleton from 'react-loading-skeleton';
import { useSearchParams } from 'react-router-dom';

import { useDebounce } from '@/hooks';
import usePlotProxy from '@/hooks/usePlotProxy';

import { handlePlotlyResize } from '@/helpers/plotly';

import NoDataFound from '@/components/common/NoDataFound';
import DownloadChartButton from '@/components/charts/DownloadChartButton';
import icons from '@/components/common/icons';
import Button from '@/components/common/Button';

import '@/styles/datepicker.scss';

import styles from './SensorByTelemetryTimeline.module.scss';
import instrumentDashboardStyles from '../../InstrumentDashboard.module.scss';
import { getChartConfigs } from './helpers';
import OverlaySensorGraphsPopover from '../OverlaySensorGraphsPopover';
import { TSensorsDataForTimeline } from '../HistoricalSensors/types';

const cn = classnames.bind({ ...instrumentDashboardStyles, ...styles });

type TTelemetryModalProps = {
  selectedSensorsData: Record<string, TSensorsDataForTimeline>;
  isPlotReadyToDisplay: boolean;
  setIsPlotReadyToDisplay: Dispatch<SetStateAction<boolean>>;
  className?: string;
  isLoading: boolean;
  isError: boolean;
  onZoomChange: (minXRange: Nullable<Date>, maxXRange: Nullable<Date>) => void;
  prevDateValuesRef?: MutableRefObject<Nullable<[Date, Date]>>;
  handleSelectedSensorsChange: (newValues: string[]) => void;
  sensorList: TTelemetryByInstrument[];
  selectedSensorValues: string[];
  changeCurrentSensorFactory: (value: TTelemetryByInstrument) => () => void;
};

const SensorByTelemetryTimeline: FC<TTelemetryModalProps> = ({
  selectedSensorsData,
  className,
  isPlotReadyToDisplay,
  setIsPlotReadyToDisplay,
  isLoading,
  isError,
  onZoomChange,
  prevDateValuesRef,
  handleSelectedSensorsChange,
  sensorList,
  selectedSensorValues,
  changeCurrentSensorFactory,
}) => {
  const graphRef = useRef<Nullable<IPlotlyHTMLDivElement>>(null);
  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');
  const [sensorColors, setSensorColors] = useState<Nullable<Record<string, string>>>(null);
  const [yAxisNames, setYAxisNames] = useState<Nullable<string[]>>(null);
  const [isReadyForFirstDraw, setIsReadyForFirstDraw] = useState(false);
  const [searchParams] = useSearchParams();
  const instrumentName = searchParams.get('name') ?? '';
  const selectedSensorsDataList = useMemo(() => Object.values(selectedSensorsData ?? {}), [selectedSensorsData]);

  const isEmptyData = useMemo(() => {
    if (!selectedSensorsDataList.length) return false;

    const isNoDataInList = selectedSensorsDataList.every(({ timelineData }) => !timelineData.length);

    return !isLoading && isNoDataInList && selectedSensorsDataList.length;
  }, [isLoading, selectedSensorsDataList]);

  const debouncedIsEmptyData = useDebounce(isEmptyData);
  const debouncedIsPlotReadyToDisplay = useDebounce(isPlotReadyToDisplay);

  useEffect(() => {
    window.addEventListener('resize', handlePlotlyResize);

    return () => {
      window.removeEventListener('resize', handlePlotlyResize);
      setIsReadyForFirstDraw(false);
    };
  }, []);

  const fileName = useMemo(() => {
    const titleArr = [];

    if (instrumentName) {
      titleArr.push(instrumentName);
    }

    const sensorName = selectedSensorValues.join('-');

    if (sensorName) {
      titleArr.push(sensorName);
    }
    titleArr.push('timeline');

    return titleArr.join('-');
  }, [instrumentName, selectedSensorsData]);

  useEffect(() => {
    const newIsReadyForFirstDraw = !isError && !isLoading;
    setIsReadyForFirstDraw(newIsReadyForFirstDraw);
  }, [isError, isLoading]);

  useEffect(() => {
    if (!graphRef.current) return;

    if (isEmptyData) {
      setIsPlotReadyToDisplay(true);
      plotlyProxy.purge();
      return;
    }

    const { traces, layout } = getChartConfigs(selectedSensorsDataList);

    // cleaning the chart to avoid displaying past traces on the range slider
    if (traces?.length !== graphRef.current?.data?.length) {
      plotlyProxy.purge();
    }

    if (selectedSensorsDataList.length !== traces.length) {
      return;
    }

    plotlyProxy.forceReact(
      traces,
      layout,
      {
        // TODO: Enable zoom via scroll after fixing zoom jumps along the x-axis
        scrollZoom: false,
        displayModeBar: false,
        responsive: true,
      },
      () => {
        const colors: Record<string, string> = {};
        const tracesYTitle: string[] = [];

        traces.forEach((trace) => {
          colors[trace.customdata] = trace.marker.color;
          tracesYTitle.push(trace.name);
        }, {});

        setSensorColors(colors);
        setYAxisNames(tracesYTitle);
        setIsPlotReadyToDisplay(true);
      }
    );
  }, [graphRef.current, isEmptyData, isReadyForFirstDraw, selectedSensorsDataList]);

  const handlePlotZoomed = useCallback((eventData: TPlotRelayoutEvent) => {
    const eventDataKeys = Object.keys(eventData);
    const isRangeEvent = eventDataKeys.length && eventDataKeys.every((key) => key.includes('range'));

    if (!isRangeEvent) return;
    const isZoomRangeEvent = eventDataKeys?.[0] === 'xaxis.range[0]';
    const isTimelineRangeEvent = eventDataKeys?.[0] === 'xaxis.range';

    let minX = null;
    let maxX = null;

    if (isZoomRangeEvent) {
      minX = eventData['xaxis.range[0]'] ? new Date(eventData['xaxis.range[0]']) : null;
      maxX = eventData['xaxis.range[1]'] ? new Date(eventData['xaxis.range[1]']) : null;
    } else if (isTimelineRangeEvent) {
      const newRangeData = eventData['xaxis.range'];

      minX = newRangeData?.[0] ? new Date(newRangeData[0]) : null;
      maxX = newRangeData?.[0] ? new Date(newRangeData[1]) : null;
    }

    if (!minX || !maxX) return;

    onZoomChange(minX, maxX);
  }, []);

  const resetRangeToDefault = useCallback(() => {
    if (!prevDateValuesRef?.current) return;
    onZoomChange(prevDateValuesRef.current[0], prevDateValuesRef.current[1]);
  }, [prevDateValuesRef?.current]);

  useEffect(() => {
    const graphDiv = graphRef.current;

    if (!graphRef?.current?.data?.[0]) return;
    graphRef.current.on('plotly_relayout', (eventData: TPlotRelayoutEvent) => handlePlotZoomed(eventData)); // event for zoom via selection
    graphRef.current.on('plotly_doubleclick', resetRangeToDefault);

    return () => {
      if (!graphDiv?.removeAllListeners) {
        return;
      }

      graphDiv?.removeAllListeners('plotly_relayouting');
      graphDiv?.removeAllListeners('plotly_relayout');
      graphDiv.removeAllListeners('plotly_doubleclick');
    };
  }, [graphRef.current?.data, handlePlotZoomed]);

  const isMultiChart = useMemo(() => selectedSensorsDataList.length > 1, [selectedSensorsDataList]);

  const isChartWaitingForDataBeforeDisplay = useMemo(
    () => isLoading || !debouncedIsPlotReadyToDisplay,
    [isLoading, debouncedIsPlotReadyToDisplay]
  );

  return (
    <div className={cn('timeline', className)}>
      <div className={cn('timeline__header')}>
        <div className={cn('timeline__header-items')}>
          <div className={cn('timeline__header-titles')}>
            {selectedSensorsDataList.map(({ sensor }) => (
              <div key={sensor?.name} className={cn('timeline__header-title')}>
                {isMultiChart && (
                  <div
                    className={cn('timeline__header-color-mark')}
                    style={
                      {
                        '--mark-color': sensorColors?.[sensor.name],
                      } as CSSProperties
                    }
                  />
                )}
                <span className={cn('timeline__header-title')}>{sensor.label}</span>
                <Button
                  className={cn('timeline__header-button')}
                  color="light"
                  onClick={changeCurrentSensorFactory(sensor)}
                >
                  <icons.CloseIcon />
                </Button>
              </div>
            ))}
          </div>
          <OverlaySensorGraphsPopover
            handleSelectedSensorsChange={handleSelectedSensorsChange}
            sensorList={sensorList}
            selectedSensorValues={selectedSensorValues}
            disabled={isLoading}
          />
          <DownloadChartButton
            graphRef={graphRef}
            fileName={fileName}
            xAxisLabel="Time"
            className={cn('action-btn')}
            iconClassName={cn('action-btn__icon')}
          />
        </div>
      </div>

      <div className={cn('timeline__content')}>
        {isChartWaitingForDataBeforeDisplay && <Skeleton className={cn('skeleton')} />}
        {!isChartWaitingForDataBeforeDisplay && <span className={cn('y-axis')}>{yAxisNames?.[0]}</span>}
        <div ref={graphRef} id="sensor-by-telemetry-chart" className={cn('timeline__chart')}>
          {!isLoading && debouncedIsEmptyData && (
            <NoDataFound
              className={cn('no-data')}
              alignment="center"
              size="normal"
              textData={
                isError ? (
                  'Something went wrong'
                ) : (
                  <span>
                    No Data <br /> Try to change the date range
                  </span>
                )
              }
            />
          )}
        </div>
        {!isChartWaitingForDataBeforeDisplay && yAxisNames?.[1] && (
          <span className={cn('y-axis', 'y-axis_right')}>{yAxisNames?.[1]}</span>
        )}
      </div>
    </div>
  );
};

export default memo(SensorByTelemetryTimeline);
