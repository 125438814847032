import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { EChartType } from '@/types/charts';

import { scatterplotsActions, EAxesGroupName, scatterplotsSelectors, TAxes } from '@/store/slices/scatterplots';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { chartDataSelectors } from '@/store/slices/chartData';

export function useHeatmapSettingsPreset(chartDataList: TDatasetDetails[]) {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const isCageLvlForced = useSelector(chartSettingsSelectors.selectIsCageLvlForced(chartId));
  const { optionList: cageLevelMultiLanesAxesOptionList } = useSelector(
    chartSettingsSelectors.selectMultiLanesAxesOptionList(chartDataList, isObjectEntityEnabled && !isCageLvlForced)
  );
  const generalAxes = useSelector(scatterplotsSelectors.selectAxesByGroup(EAxesGroupName.general)) as Record<
    string,
    TAxes
  >;
  const currentChartData = useSelector(chartDataSelectors.selectCurrentChartData);

  const defineXAxisPreset = useCallback(
    (plotAxesOptionList: TBasicOption[], chartData: TDatasetDetails): string => {
      const defaultXAxis = `${chartData.channelId}_mean`;

      const axesDataFromGeneral = generalAxes?.[currentChartData?.name ?? ''];

      const xAxisFromGeneralInOptionList = plotAxesOptionList.find(
        (axesOption) => axesOption.value === axesDataFromGeneral?.x
      );

      if (xAxisFromGeneralInOptionList?.value && !axesDataFromGeneral.isObjectEntityEnabled)
        return xAxisFromGeneralInOptionList.value.toString();

      return defaultXAxis;
    },
    [generalAxes, currentChartData, isObjectEntityEnabled]
  );

  useEffect(() => {
    if (chartDataList.length === 0) {
      return;
    }
    const chartData = chartDataList[0];

    const presetedXAxis = defineXAxisPreset(cageLevelMultiLanesAxesOptionList as TBasicOption[], chartData);

    appDispatch(
      scatterplotsActions.setAxes({
        axesGroupName: EAxesGroupName.heatmap,
        newAxes: { x: presetedXAxis, isObjectEntityEnabled: isObjectEntityEnabled && !isCageLvlForced },
      })
    );
  }, [chartDataList]);

  return useMemo(
    () => ({
      isReady: true,
      plotType: EChartType.heatmap,
    }),
    []
  );
}
