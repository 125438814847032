import { FC, MutableRefObject, ReactNode } from 'react';
import classnames from 'classnames/bind';
import 'react-datepicker/dist/react-datepicker.css';

import styles from './InstrumentCard.module.scss';
import InstrumentCardHeaderRightActions from './InstrumentCardHeaderRightActions';
import InstrumentCardHeader from './InstrumentCardHeader';
import InstrumentCardContent from './InstrumentCardContent';
import InstrumentCardHeaderLeftActions from './InstrumentCardHeaderLeftActions';

const cn = classnames.bind(styles);

type TInstrumentCardProps = {
  children: ReactNode;
  className?: string;
  innerRef?: MutableRefObject<Nullable<HTMLDivElement>>;
};

type TInstrumentCardExtensions = {
  Header: typeof InstrumentCardHeader;
  HeaderRightActions: typeof InstrumentCardHeaderRightActions;
  HeaderLeftActions: typeof InstrumentCardHeaderLeftActions;
  Content: typeof InstrumentCardContent;
};

const InstrumentCard: FC<TInstrumentCardProps> & TInstrumentCardExtensions = ({ children, className, innerRef }) => (
  <div ref={innerRef} className={cn('card', className)}>
    {children}
  </div>
);

InstrumentCard.Header = InstrumentCardHeader;
InstrumentCard.HeaderRightActions = InstrumentCardHeaderRightActions;
InstrumentCard.HeaderLeftActions = InstrumentCardHeaderLeftActions;
InstrumentCard.Content = InstrumentCardContent;

export default InstrumentCard;
