import { EAxesGroupName, TAxes, TAxesState } from '@/store/slices/scatterplots/types';
import { TBaseChartSettings } from '@/store/slices/chartSettings';
import { EAxesScaleType } from '@/types/charts';

export const getOrigDataRange = ({
  allOrigDataRanges,
  scanId,
  laneId,
  xAxis,
  yAxis,
}: {
  allOrigDataRanges: Record<string, number[]>;
  scanId?: string;
  laneId?: string;
  xAxis: string;
  yAxis: string;
}) => {
  const xKey = JSON.stringify({
    scanId,
    laneId,
    axis: xAxis,
  });
  const yKey = JSON.stringify({
    scanId,
    laneId,
    axis: yAxis,
  });
  const xRange = allOrigDataRanges[xKey];
  const yRange = allOrigDataRanges[yKey];
  if (xRange && yRange) {
    return { x: xRange, y: yRange };
  }
};

export const getOrigMultiDataRange = ({
  allOrigDataRanges,
  xAxis,
  yAxis,
  allAxes,
  chartDataList,
  specificDatasetOptionMap,
  xAxisScaleType,
  yAxisScaleType,
  globalScaleTypes,
}: {
  allOrigDataRanges: Record<string, number[]>;
  xAxis: string;
  yAxis: string;
  allAxes: TAxesState;
  chartDataList: TDatasetDetails[];
  specificDatasetOptionMap: Record<string, Partial<TBaseChartSettings>>;
  xAxisScaleType: EAxesScaleType; // scale of the dataset for which dataRange is formed
  yAxisScaleType: EAxesScaleType;
  globalScaleTypes: {
    xAxisScaleType: EAxesScaleType;
    yAxisScaleType: EAxesScaleType;
  };
}) => {
  let xUniteRange: number[] | undefined;
  let yUniteRange: number[] | undefined;

  chartDataList?.forEach((chartData) => {
    const chartDataAxes = (allAxes?.[EAxesGroupName.general] as Record<string, TAxes> | undefined)?.[chartData.id];
    const specificScalesForDataset = specificDatasetOptionMap?.[chartData.id]?.axesScaleTypes ?? {
      x: globalScaleTypes.xAxisScaleType,
      y: globalScaleTypes.yAxisScaleType,
    };

    const isScalesMatched =
      xAxisScaleType === specificScalesForDataset.x && yAxisScaleType === specificScalesForDataset.y;

    if (xAxis === chartDataAxes?.x && yAxis === chartDataAxes?.y && isScalesMatched) {
      const xKey = JSON.stringify({ scanId: chartData.scanId, laneId: chartData.laneId, axis: xAxis });
      const yKey = JSON.stringify({ scanId: chartData.scanId, laneId: chartData.laneId, axis: yAxis });
      const xRange = allOrigDataRanges[xKey];
      const yRange = allOrigDataRanges[yKey];
      if (!xUniteRange) {
        xUniteRange = xRange;
      } else if (xRange) {
        xUniteRange = [Math.min(xRange[0], xUniteRange[0]), Math.max(xRange[1], xUniteRange[1])];
      }

      if (!yUniteRange) {
        yUniteRange = yRange;
      } else if (yRange) {
        yUniteRange = [Math.min(yRange[0], yUniteRange[0]), Math.max(yRange[1], yUniteRange[1])];
      }
    }
  });

  if (xUniteRange && yUniteRange) {
    return { x: xUniteRange, y: yUniteRange };
  }
};
