import { FC } from 'react';
import classnames from 'classnames/bind';

import Button, { TButton } from '@/components/common/Button';

import styles from './InfoButton.module.scss';

const cn = classnames.bind(styles);

type TInfoButton = Pick<TButton, 'onClick' | 'className' | 'tooltip'>;

const InfoButton: FC<TInfoButton> = ({ onClick, tooltip, className }) => (
  <Button onClick={onClick} className={cn('info-button', className)} tooltip={tooltip} color="white">
    i
  </Button>
);

export default InfoButton;
