import { FC } from 'react';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';

import styles from './LinkElement.module.scss';

const cn = classnames.bind(styles);

type TLinkElementProps = {
  link: string;
  title: string;
  disabled?: boolean;
  className?: string;
};

const LinkElement: FC<TLinkElementProps> = ({ link, title, disabled, className }) =>
  disabled ? (
    <span>{title}</span>
  ) : (
    <Link className={cn('link', className)} to={link}>
      {title}
    </Link>
  );

export default LinkElement;
