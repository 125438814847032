import { FC, memo, useEffect, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import classnames from 'classnames/bind';

import Button from '@/components/common/Button';
import icons from '@/components/common/icons';

import styles from '../../InstrumentDashboard.module.scss';

const cn = classnames.bind(styles);

type TDownloadTelemetryButtonProps = {
  selectedSensorsTimelineData: TTelemetryBySensorFromServer[][];
  disabled?: boolean;
  fileName: string;
};

type TCsvDataItem = { time: string; value: string | number; postfix: string };

const DownloadTelemetryButton: FC<TDownloadTelemetryButtonProps> = ({
  selectedSensorsTimelineData,
  disabled,
  fileName,
}) => {
  const [csvData, setCsvData] =
    useState<Nullable<{ headers: { label: string; key: string }[]; data: Record<string, TCsvDataItem>[] }>>(null);

  const handleButtonClick = () => {
    if (!selectedSensorsTimelineData) return;

    const headers: { label: string; key: string }[] = [];
    const data: Record<string, TCsvDataItem>[] = [];

    selectedSensorsTimelineData.forEach((sensorData, index) => {
      const key = `details-${index}`;

      const isLastList = index === selectedSensorsTimelineData.length - 1;

      const sensorName = sensorData?.[0]?.name ?? '';
      const headerItem = [
        { label: `Time (${sensorName})`, key: `${key}.time` },
        { label: `Value (${sensorName})`, key: `${key}.value` },
        { label: `Postfix (${sensorName})`, key: `${key}.postfix` },
      ];

      if (!isLastList) {
        // add empty column between sensors
        headerItem.push({ label: '', key: '' });
      }

      headers.splice(headers.length, 0, ...headerItem);

      sensorData.forEach((timelineData, i) => {
        const dataItem = {
          time: timelineData.time,
          value: timelineData.value,
          postfix: timelineData?.postfix ?? '',
        };

        if (data?.[i]) {
          data[i][key] = dataItem;
        } else {
          data.push({
            [key]: dataItem,
          });
        }
      });
    });
    setCsvData({ headers, data });
  };

  const linkRef = useRef<Nullable<HTMLSpanElement>>(null);

  useEffect(() => {
    if (!linkRef.current || !csvData) {
      return;
    }
    linkRef.current.click();
    requestAnimationFrame(() => {
      setCsvData(null);
    });
  }, [linkRef.current, csvData]);

  return (
    <>
      <Button
        className={cn('action-btn')}
        tooltip="Download sensors telemetry"
        color="light"
        onClick={handleButtonClick}
        disabled={disabled}
      >
        <icons.DownloadIcon className={cn('action-btn__icon')} />
      </Button>
      {csvData && (
        <CSVLink data={csvData.data} headers={csvData.headers} filename={fileName} target="_self">
          <span ref={linkRef} />
        </CSVLink>
      )}
    </>
  );
};

export default memo(DownloadTelemetryButton);
