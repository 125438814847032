export enum EWarningType {
  noCageLocation = 'NO_CAGE_LOCATION',
  noCageId = 'NO_CAGE_ID',
  cageContourNotFound = 'CAGE_CONTOUR_NOT_FOUND',
  noObjectLocation = 'NO_OBJECT_LOCATION',
  noObjectId = 'NO_OBJECT_ID',
  notFoundInContours = 'NOT_FOUND_IN_CONTOURS',
}

export const WARNING_MESSAGES = {
  [EWarningType.noCageLocation]:
    'The cage cannot be displayed in the navigator because cage locations are not provided',
  [EWarningType.noCageId]:
    'The cage cannot be displayed in the navigator because it does not have a global_cage_id_matched field.',
  [EWarningType.cageContourNotFound]:
    'The cage cannot be displayed in the navigator because its contour was not found by a global_cage_id_matched field.',
  [EWarningType.noObjectLocation]:
    'The object cannot be displayed in the navigator because object locations are not provided',
  [EWarningType.noObjectId]:
    'The object cannot be displayed in the navigator because it does not have an objectId field.',
  [EWarningType.notFoundInContours]:
    'The object cannot be displayed in the navigator because its contour was not found in cell_contours',
};
