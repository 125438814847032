import { FC, memo, useMemo } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/react-hooks';

import { MDASH } from '@/helpers';
import useParamsInstrumentId from '@/hooks/useParamsInstrumentId';
import * as queries from '@/graphql/queries';

import instrumentDashboardStyles from '../../InstrumentDashboard.module.scss';

import styles from './Consumables.module.scss';

import InstrumentCard from '../InstrumentCard';

const cn = classnames.bind({ ...instrumentDashboardStyles, ...styles });

const Consumables: FC = () => {
  const instrumentId = useParamsInstrumentId();
  const {
    data: instrumentData,
    loading: isLoading,
    error,
  } = useQuery<{
    instrumentById: TInstrumentFromServer;
  }>(queries.instrumentById, {
    errorPolicy: 'all',
    variables: {
      instrumentId,
    },
  });

  const consumables = useMemo(
    () => Object.values(instrumentData?.instrumentById?.consumables ?? {}),
    [instrumentData?.instrumentById?.consumables]
  );

  const isErrorWithNoData = useMemo(() => !!error && !consumables.length, [error, consumables]);

  const isEmptyData = useMemo(
    () => !isLoading && (isErrorWithNoData || !consumables.length),
    [isLoading, isErrorWithNoData]
  );

  return (
    <InstrumentCard className={cn('content-block', 'card')}>
      <InstrumentCard.Header title="Consumables on the instrument" />
      <InstrumentCard.Content
        isLoading={isLoading}
        isNoData={isEmptyData}
        noDataMessage={error ? 'Something went wrong' : 'No data found'}
        className={cn('content')}
        id="content"
      >
        {!!consumables.length && (
          <table className={cn('table')}>
            <thead className={cn('table__header')}>
              <tr>
                <th className={cn('table__header-item')}>Consumable name</th>
                <th className={cn('table__header-item')}>Consumable type</th>
                <th className={cn('table__header-item')}>Location</th>
                <th className={cn('table__header-item')}>Expiration date</th>
                <th className={cn('table__header-item')}>Override used</th>
              </tr>
            </thead>
            <tbody>
              {consumables.map((consumable) => (
                <tr className={cn('table__row')} key={consumable.consumableId}>
                  <td className={cn('table__column')}>
                    <span className={cn('consumable-name')}>{consumable.consumableName ?? MDASH}</span>
                  </td>
                  <td className={cn('table__column')}>
                    <span>{consumable?.consumableType ?? MDASH}</span>
                  </td>
                  <td className={cn('table__column')}>
                    <span>{consumable.readerLocation}</span>
                  </td>
                  <td className={cn('table__column')}>
                    <span>{consumable?.expirationDate ?? MDASH}</span>
                  </td>
                  <td className={cn('table__column')}>
                    <span>{consumable.userOverride ? 'Yes' : 'No'}</span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </InstrumentCard.Content>
    </InstrumentCard>
  );
};

export default memo(Consumables);
