import { useEffect, useState } from 'react';
import { getCognitoGroups } from '@/helpers';

export function useUserRole() {
  const [userPermissions, setUserPermissions] = useState({
    isPermissionsRead: false,

    isJobRunCreateAllowed: false,
    isJobRunDeleteAllowed: false,
    isJobRunReadAllowed: false,

    isProjectCreateAllowed: false,
    isProjectReadAllowed: false,
    isProjectDeleteAllowed: false,

    isUserCreateAllowed: false,
    isUserReadAllowed: false,
    isUserUpdateAllowed: false,
    isUserDeleteAllowed: false,

    isTeamCreateAllowed: false,
    isTeamReadAllowed: false,
    isTeamUpdateAllowed: false,
    isTeamDeleteAllowed: false,

    isInstrumentReadAllowed: false,
  });

  useEffect(() => {
    getCognitoGroups().then((cognitoGroups) => {
      setUserPermissions({
        isPermissionsRead: true,

        isJobRunCreateAllowed: cognitoGroups.includes('jobRun:create'),
        isJobRunDeleteAllowed: cognitoGroups.includes('jobRun:delete'),
        isJobRunReadAllowed: cognitoGroups.includes('jobRun:read'),

        isProjectCreateAllowed: cognitoGroups.includes('project:create'),
        isProjectReadAllowed: cognitoGroups.includes('project:read'),
        isProjectDeleteAllowed: cognitoGroups.includes('project:delete'),

        isUserCreateAllowed: cognitoGroups.includes('user:create'),
        isUserReadAllowed: cognitoGroups.includes('user:read'),
        isUserUpdateAllowed: cognitoGroups.includes('user:update'),
        isUserDeleteAllowed: cognitoGroups.includes('user:delete'),

        isTeamCreateAllowed: cognitoGroups.includes('team:create'),
        isTeamReadAllowed: cognitoGroups.includes('team:read'),
        isTeamUpdateAllowed: cognitoGroups.includes('team:update'),
        isTeamDeleteAllowed: cognitoGroups.includes('team:delete'),

        isInstrumentReadAllowed: cognitoGroups.includes('instrument:read'),
      });
    });
  }, []);

  return userPermissions;
}
