import { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { chartSettingsActions, chartSettingsSelectors } from '@/store/slices/chartSettings';
import { gatesActions, gatesSelectors } from '@/store/slices/gates';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useSettingsNotification } from '@/hooks/plotSettings/useSettingsNotification';
import { EAxesScaleType } from '@/types/charts';
import { themeOptions } from '@/types/theme';

import Select from '@/components/common/Select';

import styles from '../SettingsPopover.module.scss';
import { TSettingsPopover } from '../types';

const cn = classnames.bind(styles);

const AxesScaleType: FC<Pick<TSettingsPopover, 'pageType'>> = ({ pageType }) => {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const currentScalesTypeForAxes = useSelector(chartSettingsSelectors.selectCurrentScalesTypeForAxes(chartId));
  const isLogScaleTypeForXAxisAllowed = useSelector(
    chartSettingsSelectors.selectIsLogScaleTypeForXAxisAllowed(chartId)
  );
  const isLogScaleTypeForYAxisAllowed = useSelector(
    chartSettingsSelectors.selectIsLogScaleTypeForYAxisAllowed(chartId)
  );
  const activeGate = useSelector(gatesSelectors.selectActiveGate);

  const optionList = useMemo<TOption[]>(
    () => [
      {
        label: 'Linear',
        value: EAxesScaleType.linear,
      },
      {
        label: 'Log',
        value: EAxesScaleType.log,
      },
      {
        label: 'ArcSinh',
        value: EAxesScaleType.arcSinh,
      },
    ],
    [EAxesScaleType]
  );
  const { isSettingsChangingConfirmed } = useSettingsNotification({ pageType });

  const handleChangeAxisScaleType = (axis: 'x' | 'y') => async (value: EAxesScaleType) => {
    const isConfirmed = await isSettingsChangingConfirmed();

    if (!isConfirmed) {
      return;
    }

    appDispatch(chartSettingsActions.setAxesScaleTypes({ [axis]: value }));
    if (activeGate?.shape.type === 'circle') {
      appDispatch(gatesActions.setActiveGate(null));
    }
  };

  return (
    <div className={cn('section')}>
      <div className={cn('section__head')}>
        <span>Axes scale type</span>
      </div>
      <div
        className={cn('axes-scale-types-wrapper', {
          'axes-scale-types-wrapper_one-column': !isLogScaleTypeForYAxisAllowed,
        })}
      >
        <div className={cn('axis-scale-type')}>
          <span>X</span>
          <Select
            value={currentScalesTypeForAxes.xAxisScaleType}
            disabled={!isLogScaleTypeForXAxisAllowed}
            placeholder=""
            className={cn('section__select', 'plot-type-select')}
            controlClassName={cn('section__select')}
            theme={themeOptions.light}
            defaultValue={currentScalesTypeForAxes.xAxisScaleType}
            onChange={handleChangeAxisScaleType('x')}
            options={optionList}
          />
        </div>
        {isLogScaleTypeForYAxisAllowed && (
          <div className={cn('axis-scale-type')}>
            <span>Y</span>
            <Select
              value={currentScalesTypeForAxes.yAxisScaleType}
              placeholder=""
              className={cn('section__select', 'plot-type-select')}
              controlClassName={cn('section__select')}
              theme={themeOptions.light}
              defaultValue={currentScalesTypeForAxes.yAxisScaleType}
              onChange={handleChangeAxisScaleType('y')}
              options={optionList}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AxesScaleType;
