import { FC, MouseEventHandler, ReactNode, useEffect, useState } from 'react';
import classnames from 'classnames/bind';

import Popover, { TPopoverOption } from '@/components/common/Popover';

import styles from './ContextMenuPopoverWrapper.module.scss';

const cn = classnames.bind(styles);

const POPOVER_WIDTH = 220;
const POPOVER_HEIGHT = 150;

type TContextMenuPopoverWrapperProps = {
  options: TPopoverOption[];
  activeElementId: string;
  children: ReactNode;
};

const ContextMenuPopoverWrapper: FC<TContextMenuPopoverWrapperProps> = ({ options, activeElementId, children }) => {
  const [isPopoverOpened, setIsPopoverOpened] = useState(false);
  const [popoverCoords, setPopoverCoords] = useState({ x: 0, y: 0 });

  const handleContextMenu: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const target = event.target as Node;
    const activeShape = document.getElementById(activeElementId);

    if (!activeShape?.contains(target)) return;

    if (options.length > 0) {
      setIsPopoverOpened((prevState) => !prevState);
    }
  };

  const handlePopoverClick = () => {
    setIsPopoverOpened((prevState) => !prevState);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      event.stopPropagation();
      const target = event.target as Node;
      const activeShape = document.getElementById(activeElementId);

      const SECONDARY_BUTTON_TYPE = 2;
      if (event.button === SECONDARY_BUTTON_TYPE && activeShape?.contains(target)) {
        const viewportWidth = window.innerWidth;
        const viewportHeight = window.innerHeight;

        const x = Math.max(0, Math.min(event.clientX, viewportWidth - POPOVER_WIDTH));
        const y = Math.max(0, Math.min(event.clientY, viewportHeight - POPOVER_HEIGHT));

        setPopoverCoords({
          x,
          y,
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [options.length]);

  return (
    <div onContextMenu={handleContextMenu}>
      <Popover
        onClick={handlePopoverClick}
        isOpen={isPopoverOpened}
        options={options}
        setIsOpen={setIsPopoverOpened}
        contentClassName={cn('popover')}
        contentId="popover"
        reposition
        // TODO: Use automatic calculations from floating-ui to determine the popover position.
        // It might make sense to use the FloatingOverlay wrapper instead of react-tiny-popover
        styles={{
          transform: `translate(${popoverCoords.x}px, ${popoverCoords.y}px)`,
        }}
      />
      {children}
    </div>
  );
};

export default ContextMenuPopoverWrapper;
