import { ReactNode } from 'react';
import { Id, toast } from 'react-toastify';

import { TOAST_DELAY } from '@/helpers';
import { getErrorMessage } from '@/helpers/errors';

export default class ProgressToast {
  private toastId: Id | null = null;

  private readonly loadingMessage: ReactNode;

  private readonly successMessage: ReactNode;

  private readonly errorMessage: ReactNode;

  private readonly autoClosedLoaderToast?: boolean;

  constructor(
    loadingMessage: ReactNode,
    successMessage: ReactNode,
    errorMessage: ReactNode,
    autoClosedLoaderToast?: boolean
  ) {
    this.loadingMessage = loadingMessage;
    this.successMessage = successMessage;
    this.errorMessage = errorMessage;
    this.autoClosedLoaderToast = autoClosedLoaderToast;
  }

  public showLoadingMessage() {
    this.toastId = toast.loading(this.loadingMessage, {
      className: 'toast_max-content',
    });

    if (this.autoClosedLoaderToast) {
      // toast with type="loading" does not support auto-close, so it must be closed manually
      setTimeout(() => {
        this.hideMessage();
      }, TOAST_DELAY);
    }
  }

  public showSuccessMessage() {
    if (!this.toastId && !this.autoClosedLoaderToast) return;

    if (!this.toastId) {
      toast.success(this.successMessage, {
        autoClose: TOAST_DELAY,
      });

      return;
    }

    toast.update(this.toastId, {
      render: this.successMessage,
      type: 'success',
      isLoading: false,
      autoClose: TOAST_DELAY,
    });
    this.toastId = null;
  }

  public showErrorMessage(error?: unknown) {
    if (!this.toastId && !this.autoClosedLoaderToast) return;

    const render = error ? (
      <span>
        {this.errorMessage}
        <br />
        {getErrorMessage(error)}
      </span>
    ) : (
      this.errorMessage
    );

    if (!this.toastId) {
      toast.error(render, {
        autoClose: TOAST_DELAY,
      });

      return;
    }

    toast.update(this.toastId, {
      render: error ? (
        <span>
          {this.errorMessage}
          <br />
          {getErrorMessage(error)}
        </span>
      ) : (
        this.errorMessage
      ),
      type: 'error',
      isLoading: false,
      autoClose: TOAST_DELAY,
    });
    this.toastId = null;
  }

  hideMessage() {
    if (!this.toastId) {
      return;
    }
    toast.dismiss(this.toastId);
    this.toastId = null;
  }
}
