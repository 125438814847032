import { FC, memo, useCallback, useMemo } from 'react';
import classnames from 'classnames/bind';
import 'react-datepicker/dist/react-datepicker.css';

import { addTooltip } from '@/helpers';
import { format } from 'date-fns';

import icons from '@/components/common/icons';

import styles from './SensorListItem.module.scss';

const cn = classnames.bind(styles);

const getSensorTooltip = (telemetrySensor: TTelemetryByInstrument) => {
  const { label, description, time } = telemetrySensor;

  return `<div>
      <span style="color: #B5B5B5;">${label}</span>
      </br>
      <span>${description}</span>
      </br>
      <em>${format(new Date(time), 'yyyy-MM-dd HH:mm:ss')}</em>
      </div>`;
};

type TSensorListItemProps = {
  telemetrySensor: TTelemetryByInstrument;
  onClick?: (sensor: TTelemetryByInstrument) => void;
  isClickable?: boolean;
  className?: string;
  selected?: boolean;
  disabled?: boolean;
};

const SensorListItem: FC<TSensorListItemProps> = ({
  telemetrySensor,
  onClick,
  isClickable,
  className,
  selected,
  disabled,
}) => {
  const handleClick = useCallback(() => {
    if (!isClickable || !onClick) return;

    onClick(telemetrySensor);
  }, [isClickable, onClick]);

  const sensorValue = useMemo(
    () => (telemetrySensor.type === 'float' ? Number(telemetrySensor.value).toFixed(2) : telemetrySensor.value),
    [telemetrySensor]
  );

  return isClickable ? (
    <button
      onClick={handleClick}
      className={cn('sensor', 'sensor_clickable', { sensor_disabled: disabled })}
      disabled={disabled}
    >
      <span
        className={cn('sensor__name', className, { sensor__name_selected: selected })}
        {...addTooltip(telemetrySensor.description, 'top-start')}
      >
        {selected && <icons.CheckMarkIcon className={cn('sensor__icon')} />}
        {telemetrySensor.label}
      </span>
    </button>
  ) : (
    <div className={cn('sensor')}>
      {/* div wrapper used for correct display of tooltip in subgrid grid */}
      <div className={cn('sensor__name-wrapper', className)}>
        <span
          className={cn('sensor__name', 'sensor__name_dashed')}
          {...addTooltip(getSensorTooltip(telemetrySensor), 'top-start')}
        >
          {telemetrySensor.label}
        </span>
      </div>
      <span>{`${sensorValue} ${telemetrySensor.postfix ?? ''}`}</span>
    </div>
  );
};

export default memo(SensorListItem);
