import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { getKeyName } from '@/helpers';
import { getCorrectNameOfAxes } from '@/helpers/preprocessing';

import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { histogramSettingsSelectors } from '@/store/slices/histogramSettings';
import { useDebounce } from '@/hooks/useDebounce';
import { getSpecificScaleTypeForDataset } from '@/hooks/useExperimentContext/helpers';
import { EAxesGroupName, scatterplotsSelectors, TAxes } from '@/store/slices/scatterplots';
import { EAxesScaleType } from '@/types/charts';

type TRangeData = {
  name: string;
  datasetId: string;
  xAxisFromMap: string;
  yAxisFromMap: string;
};
type TRangesDataMap = Record<string, TRangeData>;

// TODO: MATRIX_SINGLE_CHART this plot range name need to use if plot was not changed, and need ignore this generated name, because it is not valid
const useMatrixRanges = (chartDataList: TDatasetDetails[]) => {
  const contextChartId = usePlotChartIdContext();
  const objectType = useSelector(chartSettingsSelectors.selectObjectType(contextChartId));
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(contextChartId));
  const { xAxisScaleType, yAxisScaleType } = useSelector(chartSettingsSelectors.selectCurrentScalesTypeForAxes());
  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(contextChartId));
  const kernelBandwidthCoefficient = useSelector(
    histogramSettingsSelectors.selectKernelBandwidthCoefficient(contextChartId)
  );
  const histogramGroupType = useSelector(
    histogramSettingsSelectors.selectCurrentHistogramDataGroupType(contextChartId)
  );
  const { optionMapping } = useSelector(
    chartSettingsSelectors.selectMultiLanesAxesOptionList(chartDataList, isObjectEntityEnabled)
  );
  const allGlobalChartSettings = useSelector(chartSettingsSelectors.selectAllGlobalChartSettings);
  const allAxes = useSelector(scatterplotsSelectors.selectAxes);

  const debouncedCurrentChartType = useDebounce(currentChartType);
  const [rangesDataMap, setRangesDataMap] = useState<TRangesDataMap>({});

  const optionMappingRef = useRef<Record<string, Record<string, string>>>({});

  useEffect(() => {
    optionMappingRef.current = optionMapping;
  }, [optionMapping]);

  const getDatasetsIdsWithTheSameAxesAndScales = useCallback(
    (
      xAxisFromMap: string,
      yAxisFromMap: string,
      scales: { xAxisScaleType: EAxesScaleType; yAxisScaleType: EAxesScaleType }
    ) => {
      const datasetsWithTheSameAxes = chartDataList.filter((details) => {
        const chartDataAxes = (allAxes?.[EAxesGroupName.general] as Record<string, TAxes> | undefined)?.[details.id];
        const datasetScaleTypes = details?.id
          ? getSpecificScaleTypeForDataset(details.id, allGlobalChartSettings)
          : { xAxisScaleType, yAxisScaleType };

        const isSameScales =
          datasetScaleTypes.xAxisScaleType === scales.xAxisScaleType &&
          datasetScaleTypes.yAxisScaleType === scales.yAxisScaleType;

        const isSameAxes = xAxisFromMap === chartDataAxes?.x && chartDataAxes?.y === yAxisFromMap;
        return isSameScales && isSameAxes;
      });

      const uniqueIdList = [
        ...new Set(datasetsWithTheSameAxes.map((dataset) => `${dataset.scanId}_${dataset.laneId}`)),
      ].join('_');

      return uniqueIdList;
    },
    [chartDataList, allAxes, xAxisScaleType, yAxisScaleType, allGlobalChartSettings]
  );

  const getRangeData = useCallback(
    (xAxis: string, yAxis: string, chartId: string) => {
      if (!xAxis && !yAxis) {
        return;
      }

      const chartData = chartDataList.find(({ id }) => id === chartId);

      if (!chartData) {
        return;
      }

      const scaleTypesForCurrentChart = chartData?.id
        ? getSpecificScaleTypeForDataset(chartData.id, allGlobalChartSettings)
        : { xAxisScaleType, yAxisScaleType };

      const { xAxisFromMap, yAxisFromMap } = getCorrectNameOfAxes(
        optionMappingRef.current,
        chartData?.dataset?.name ?? '',
        xAxis,
        yAxis
      );

      const datasetsIdList = getDatasetsIdsWithTheSameAxesAndScales(
        xAxisFromMap,
        yAxisFromMap,
        scaleTypesForCurrentChart
      );

      const entityType = isObjectEntityEnabled ? 'object' : 'cage';
      const axisName = getKeyName(
        objectType,
        entityType,
        xAxisFromMap,
        yAxisFromMap,
        scaleTypesForCurrentChart.xAxisScaleType,
        scaleTypesForCurrentChart.yAxisScaleType,
        debouncedCurrentChartType,
        histogramGroupType,
        `coefficient_${kernelBandwidthCoefficient}`,
        datasetsIdList
      );
      return {
        name: axisName,
        datasetId: chartId,
        xAxisFromMap,
        yAxisFromMap,
      };
    },
    [
      isObjectEntityEnabled,
      objectType,
      chartDataList,
      xAxisScaleType,
      yAxisScaleType,
      debouncedCurrentChartType,
      histogramGroupType,
      kernelBandwidthCoefficient,
      getDatasetsIdsWithTheSameAxesAndScales,
      allGlobalChartSettings.specificDatasetOptionMap,
    ]
  );

  const getRangeName = useCallback(
    (xAxis: string, yAxis: string, chartId: string) => getRangeData(xAxis, yAxis, chartId)?.name,
    [getRangeData]
  );

  const handleAxisChange = useCallback(
    (xAxis: string, yAxis: string, chartId: string) => {
      const updatedRangeData = getRangeData(xAxis, yAxis, chartId);
      if (!updatedRangeData) {
        return;
      }
      setRangesDataMap((prev) => ({
        ...prev,
        [chartId]: updatedRangeData,
      }));
    },
    [getRangeData]
  );

  useEffect(() => {
    const updatedRangesDataMap = { ...rangesDataMap };

    if (!Object.values(updatedRangesDataMap)?.length) return;

    const entityType = isObjectEntityEnabled ? 'object' : 'cage';
    Object.values(updatedRangesDataMap).forEach((rangeData) => {
      const { xAxisFromMap, yAxisFromMap, datasetId } = rangeData;
      const scaleTypesForCurrentChart = datasetId
        ? getSpecificScaleTypeForDataset(datasetId, allGlobalChartSettings)
        : { xAxisScaleType, yAxisScaleType };

      const datasetsIdList = getDatasetsIdsWithTheSameAxesAndScales(
        xAxisFromMap,
        yAxisFromMap,
        scaleTypesForCurrentChart
      );

      rangeData.name = getKeyName(
        objectType,
        entityType,
        xAxisFromMap,
        yAxisFromMap,
        scaleTypesForCurrentChart.xAxisScaleType,
        scaleTypesForCurrentChart.yAxisScaleType,
        debouncedCurrentChartType,
        histogramGroupType,
        `coefficient_${kernelBandwidthCoefficient}`,
        datasetsIdList
      );
    });

    setRangesDataMap(updatedRangesDataMap);
  }, [
    isObjectEntityEnabled,
    objectType,
    xAxisScaleType,
    yAxisScaleType,
    debouncedCurrentChartType,
    histogramGroupType,
    kernelBandwidthCoefficient,
    allGlobalChartSettings.specificDatasetOptionMap,
    getDatasetsIdsWithTheSameAxesAndScales,
  ]);

  return useMemo(
    () => ({
      handleAxisChange,
      getRangeName,
      rangesDataMap,
    }),
    [handleAxisChange, getRangeName, rangesDataMap]
  );
};
export default useMatrixRanges;
