import { isPointInGate } from '@/helpers/gates';
import { isNumber } from '@/helpers';

import { filterEntityListByObjectType } from '@/hooks/charts/useChartEntityList';
import { EEntityObjectType, TInitialChartSettingsState } from '@/store/slices/chartSettings';
import { EAxesScaleType } from '@/types/charts';
import { getCurrentScalesTypeForAxes } from '@/store/slices/chartSettings/helpers';

export const UNGATED_ID = 'ungated';

type TPrepareGatesData = {
  gateList: TGate[];
  fullParentEntityList: TEntity[];
  entitiesDataByGates: TEntitiesByGates;
  objectType: EEntityObjectType;
  scanId?: string;
  laneId?: string;
  parentGate?: TGate;
};

type TGetEntitiesDataByGatesPayload = {
  entityList: TEntity[];
  gateList: TGate[];
  scanId?: string;
  laneId?: string;
  objectType: EEntityObjectType;
  xAxisScaleType: EAxesScaleType;
  yAxisScaleType: EAxesScaleType;
  xDimension: string;
  yDimension: string;
};

export const filterEntityForLogScale = ({
  xAxisScaleType,
  yAxisScaleType,
  xDimension,
  yDimension,
  entityList,
}: {
  xAxisScaleType: EAxesScaleType;
  yAxisScaleType: EAxesScaleType;
  xDimension: string;
  yDimension: string;
  entityList: TEntity[];
}) => {
  if (xAxisScaleType !== EAxesScaleType.log && yAxisScaleType !== EAxesScaleType.log) return entityList;

  const filteredEntity = entityList.filter((entityItem) => {
    const xVal = isNumber(entityItem[xDimension]) ? entityItem[xDimension] : null;
    const yVal = isNumber(entityItem[yDimension]) ? entityItem[yDimension] : null;

    if (!xVal || !yVal) return;

    const isXNegativeOrZeroInLogScale = xAxisScaleType === EAxesScaleType.log && xVal < 0;
    const isYNegativeOrZeroInLogScale = yAxisScaleType === EAxesScaleType.log && yVal < 0;

    return !isXNegativeOrZeroInLogScale && !isYNegativeOrZeroInLogScale;
  });

  return filteredEntity;
};

const prepareGatesData = ({
  gateList,
  fullParentEntityList,
  entitiesDataByGates,
  objectType = EEntityObjectType.all,
  scanId,
  laneId,
  parentGate,
}: TPrepareGatesData) => {
  gateList.forEach((gate) => {
    const parentEntityList = fullParentEntityList.filter(
      (entity) => isNumber(entity[gate.xDimension]) && isNumber(entity[gate.yDimension])
    );

    const gateEntityList =
      gate.shape.type === 'polar'
        ? parentEntityList
        : parentEntityList.filter((entity: TEntity) =>
            isPointInGate({
              x: entity[gate.xDimension],
              y: entity[gate.yDimension],
              gate,
              parentGate,
              scanId,
              laneId,
            })
          );

    const filteredCageList = filterEntityListByObjectType(gateEntityList, objectType);
    const entitiesCount = filteredCageList.length;
    const entitiesPercent = parentEntityList.length > 0 ? (filteredCageList.length / parentEntityList.length) * 100 : 0;

    entitiesDataByGates[gate.id] = {
      cageList: filteredCageList,
      fullCageList: gateEntityList,
      cagesCount: entitiesCount,
      cagesPercent: `${entitiesPercent.toFixed(2)}%`,
      name: gate?.name ?? '',
    };

    if (gate.gateNodes.length) {
      prepareGatesData({
        gateList: gate.gateNodes,
        parentGate: gate,
        fullParentEntityList: gateEntityList,
        entitiesDataByGates,
        objectType,
        scanId,
        laneId,
      });
    }
  });
};

export const getEntitiesDataByGates = ({
  entityList,
  gateList,
  objectType = EEntityObjectType.all,
  scanId,
  laneId,
  xAxisScaleType,
  yAxisScaleType,
  xDimension,
  yDimension,
}: TGetEntitiesDataByGatesPayload) => {
  const filteredCageList = filterEntityListByObjectType(entityList, objectType);

  const filteredParentEntityByScale =
    xAxisScaleType !== EAxesScaleType.log && yAxisScaleType !== EAxesScaleType.log
      ? filteredCageList
      : filterEntityForLogScale({
          xAxisScaleType,
          yAxisScaleType,
          xDimension,
          yDimension,
          entityList: filteredCageList,
        });

  const entitiesDataByGates: TEntitiesByGates = {
    [UNGATED_ID]: {
      cageList: filteredParentEntityByScale,
      fullCageList: entityList,
      cagesCount: filteredParentEntityByScale.length,
      cagesPercent: '100%',
      name: '',
    },
  };

  prepareGatesData({
    gateList,
    fullParentEntityList: filteredParentEntityByScale,
    entitiesDataByGates,
    objectType,
    scanId,
    laneId,
  });
  return entitiesDataByGates;
};

export const getEntitiesData = (
  entitiesByLanesAndGates: TEntitiesByLanesAndGates,
  lanePath?: string,
  gate?: Nullable<TGate>
) => {
  if (!lanePath || !entitiesByLanesAndGates[lanePath]) {
    return null;
  }
  if (gate && gate.id) {
    return entitiesByLanesAndGates[lanePath][gate.id] ?? null;
  }

  return entitiesByLanesAndGates[lanePath][UNGATED_ID];
};

export const filterEntitiesByCage = (entityList: TEntity[]) => {
  const entitiesMap: Record<string, TEntity> = {};
  entityList.forEach((entity) => {
    const key = JSON.stringify({
      cageId: entity.cageId,
      xStage: entity.xStage,
      yStage: entity.yStage,
    });
    if (!entitiesMap[key]) {
      entitiesMap[key] = entity;
    }
  });
  return Object.values(entitiesMap);
};

export const getSpecificScaleTypeForDataset = (
  datasetId: string,
  allGlobalChartSettings: TInitialChartSettingsState
) => {
  const specificScaleTypes = allGlobalChartSettings?.specificDatasetOptionMap?.[datasetId]?.axesScaleTypes;
  const currentDatasetChartType = getChartType(datasetId, allGlobalChartSettings);

  const scaleTypes = {
    x: allGlobalChartSettings.axesScaleTypes.x,
    y: allGlobalChartSettings.axesScaleTypes.y,
  };

  if (specificScaleTypes) {
    scaleTypes.x = specificScaleTypes.x;
    scaleTypes.y = specificScaleTypes.y;
  }

  if (specificScaleTypes) return { xAxisScaleType: specificScaleTypes.x, yAxisScaleType: specificScaleTypes.y };
  return getCurrentScalesTypeForAxes(currentDatasetChartType, scaleTypes);
};

export const getChartType = (datasetId: string, allGlobalChartSettings: TInitialChartSettingsState) => {
  const specificScaleTypes = allGlobalChartSettings?.specificDatasetOptionMap?.[datasetId]?.currentChartType;

  if (specificScaleTypes) return specificScaleTypes;

  return allGlobalChartSettings.currentChartType;
};

export const getObjectTypeForStatisticCalculation = (
  datasetId: string,
  allGlobalChartSettings: TInitialChartSettingsState
) => {
  const specificObjectType = allGlobalChartSettings?.specificDatasetOptionMap?.[datasetId]?.objectType;

  return specificObjectType ?? allGlobalChartSettings.objectType;
};
