import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { PopoverPosition } from 'react-tiny-popover';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { formatHighlightSearchQuery } from '@/helpers/formatHighlightSearchQuery';
import { isNumber } from '@/helpers';

import { useDebounce } from '@/hooks';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import { EActiveEntityUuidLastEdit, scatterplotsActions } from '@/store/slices/scatterplots';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import SearchInput from '@/components/common/SearchInput';
import Popover, { TPopoverOption } from '@/components/common/Popover';

import { getSearchMatches } from './helpers';

import styles from './EntitiesSearch.module.scss';

const cn = classnames.bind(styles);

const MAX_ENTITIES_COUNT_TO_SHOW = 50;

type TEntitiesSearchProps = {
  entityList: TEntity[];
  onEntitySelect: (entity: TEntity) => void;
  disabled?: boolean;
  isAlwaysOpen?: boolean;
  placeholder?: string;
  className?: string;
  popoverClassName?: string;
  popoverPositions?: PopoverPosition[];
  isOnlyGlobalCageId?: boolean;
};

const EntitiesSearch: FC<TEntitiesSearchProps> = ({
  entityList,
  onEntitySelect,
  disabled = false,
  isAlwaysOpen = false,
  placeholder = '',
  className,
  popoverClassName,
  popoverPositions = ['top'],
  isOnlyGlobalCageId = false,
}) => {
  const appDispatch = useAppDispatch();

  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled());

  const [searchQuery, setSearchQuery] = useState('');
  const debouncedSearchQuery = useDebounce(searchQuery, 100);

  const [isPopoverOpened, setIsPopoverOpened] = useState(false);
  const [popoverOptionList, setPopoverOptionList] = useState<TPopoverOption[]>([]);

  const [selectedEntityUuid, setSelectedEntityUuid] = useState('');
  const debouncedActiveEntityUuid = useDebounce(selectedEntityUuid, 10);

  const isSearchDisabled = useMemo(
    () => entityList.length === 0 || !('globalCageIdMatched' in entityList[0] && entityList[0].globalCageIdMatched),
    [entityList]
  );

  const handleSearchInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(value);
  };

  const resetSearch = () => {
    setSearchQuery('');
  };

  useEffect(() => {
    if (debouncedSearchQuery.length === 0 || isSearchDisabled) {
      setPopoverOptionList([]);
      return;
    }
    const { exactList, approximateList } = getSearchMatches(entityList, debouncedSearchQuery, isOnlyGlobalCageId);
    const searchList = exactList.concat(approximateList);

    if (searchList.length === 0) {
      setPopoverOptionList([
        {
          id: 'not-found',
          title: 'No cages found',
          inactive: true,
        },
      ]);
      return;
    }

    const maxCount = Math.max(MAX_ENTITIES_COUNT_TO_SHOW, exactList.length);
    const optionList = searchList.slice(0, maxCount).map((entity) => {
      const searchWords = formatHighlightSearchQuery(searchQuery);
      const dataList = [{ label: 'Global cage ID', value: entity.globalCageIdMatched.toString() }];

      if (isObjectEntityEnabled && 'objectId' in entity && isNumber(entity.objectId)) {
        dataList.push({ label: 'Object ID', value: entity.objectId.toString() });
      }

      return {
        id: `entity-${entity.uuid}`,
        title: (
          <div>
            {dataList.map(({ label, value }) => (
              <span key={value} className={cn('popover__option-part')}>
                <span className={cn('popover__option-label')}>{label}:</span>
                <Highlighter textToHighlight={value} searchWords={searchWords} />
              </span>
            ))}
          </div>
        ),
        onClick: () => {
          onEntitySelect(entity);
          resetSearch();
        },
        onMouseEnter: () => {
          setSelectedEntityUuid(entity.uuid);
        },
        onMouseLeave: () => {
          setSelectedEntityUuid('');
        },
      };
    }) as TPopoverOption[];
    if (searchList.length > maxCount) {
      optionList.push({
        id: 'show-more',
        title: 'Continue typing to see more',
        inactive: true,
      });
    }
    setPopoverOptionList(optionList);
  }, [debouncedSearchQuery, entityList, onEntitySelect]);

  useEffect(() => {
    setIsPopoverOpened(popoverOptionList.length > 0);
  }, [popoverOptionList]);

  useEffect(() => {
    appDispatch(
      scatterplotsActions.setActiveEntityUuid({
        uuid: debouncedActiveEntityUuid,
        lastEdit: EActiveEntityUuidLastEdit.popover,
      })
    );
  }, [debouncedActiveEntityUuid]);

  if (isSearchDisabled) {
    return null;
  }

  return (
    <>
      <SearchInput
        onChange={handleSearchInputChange}
        onReset={resetSearch}
        value={debouncedSearchQuery}
        autoComplete="off"
        className={className}
        disabled={disabled}
        isAlwaysOpen={isAlwaysOpen}
        placeholder={placeholder}
      />
      <Popover
        isOpen={isPopoverOpened && !disabled}
        options={popoverOptionList}
        useBackdrop={false}
        contentClassName={cn('popover', popoverClassName)}
        align="end"
        positions={popoverPositions}
        padding={5}
        contentId="search"
        disableClosing
      />
    </>
  );
};

export default EntitiesSearch;
