import { SVGProps } from 'react';

const MenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <rect
      x="0.75"
      y="-0.75"
      width="22.5"
      height="22.5"
      rx="11.25"
      transform="matrix(1 0 0 -1 0 22.5)"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path d="M7 8H17" stroke="currentColor" strokeWidth="1.5" />
    <path d="M7 12H17" stroke="currentColor" strokeWidth="1.5" />
    <path d="M7 16H17" stroke="currentColor" strokeWidth="1.5" />
  </svg>
);

export default MenuIcon;
