import { FC, memo, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Id, toast } from 'react-toastify';
import classnames from 'classnames/bind';

import { ECdnObjectType } from '@/types/cdnData';

import { LAST_EDIT } from '@/helpers';
import { findLaneInScanList } from '@/helpers/scans';
import { contoursOnCanvas, CURRENT_OBJECT_DRAW_SETTINGS } from '@/helpers/objectsOnCanvas';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { useParamsExperimentId } from '@/hooks';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useContoursContext } from '@/hooks/useContoursContext';

import { cdnAPI } from '@/store/services/cdnData';
import { experimentSelectors } from '@/store/slices/experiment';
import { navigatorActions, navigatorSelectors } from '@/store/slices/navigator';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { viewerActions, viewerSelectors } from '@/store/slices/viewer';

import EntitiesSearch from '@/components/common/EntitiesSearch';

import styles from './EntitiesSearchBlock.module.scss';
import { EWarningType, WARNING_MESSAGES } from './constants';

const cn = classnames.bind(styles);

const OBJECT_CONTOUR_GAP = 3;

const EntitiesSearchBlock: FC<{ openCageInspector: (props: TCageInspectorModalProps) => void }> = ({
  openCageInspector,
}) => {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const experimentId = useParamsExperimentId();
  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const currentLaneId = useSelector(navigatorSelectors.selectCurrentLaneId);
  const currentScanId = useSelector(navigatorSelectors.selectCurrentScanId);
  const currentLane = useMemo(
    () => findLaneInScanList(scanList, currentScanId, currentLaneId),
    [scanList, currentScanId, currentLaneId]
  );

  const highlightedCage = useSelector(viewerSelectors.selectHighlightedCage);
  const { zoom } = useSelector(navigatorSelectors.selectZoom(currentLaneId));

  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const useFetchEntityListQuery = isObjectEntityEnabled
    ? cdnAPI.useFetchObjectEntityListQuery
    : cdnAPI.useFetchCageEntityListQuery;
  const { data: entityList = [], isLoading: isEntityListLoading } = useFetchEntityListQuery(currentLane);

  const {
    cdnData: {
      [ECdnObjectType.cageContour]: { objectList: cageContourList, isLoading: isCageContourListLoading },
      [ECdnObjectType.cellContour]: { objectList: cellContourList },
    },
  } = useContoursContext();

  const isSearchDisabled = useMemo(
    () => isEntityListLoading || isCageContourListLoading,
    [isEntityListLoading, isCageContourListLoading]
  );

  const toastId = useRef<Nullable<Id>>(null);

  const openImageModal = (entity: TEntity) => {
    openCageInspector({ entity, lane: currentLane });
  };

  const findCageContour = useCallback(
    (entity: TEntity) => {
      if (cageContourList.length === 0) {
        toast.info(WARNING_MESSAGES[EWarningType.noCageLocation], {
          toastId: EWarningType.noCageLocation,
        }); // Prevents showing info with the same text when quickly reopening the navigator;
        return;
      }

      if (!entity.globalCageIdMatched) {
        toast.info(WARNING_MESSAGES[EWarningType.noCageId], { toastId: EWarningType.noCageId });
        return;
      }

      const cageContour = (cageContourList as TCageContour[]).find(
        (contour) => contour.globalCageIdMatched === entity.globalCageIdMatched
      );
      if (!cageContour) {
        toast.info(WARNING_MESSAGES[EWarningType.cageContourNotFound], { toastId: EWarningType.cageContourNotFound });
      }
      return cageContour;
    },
    [cageContourList]
  );

  const findCellContourForCurrentCage = useCallback(
    (
      entity: TEntity,
      cageBbox: {
        xMin: number;
        xMax: number;
        yMin: number;
        yMax: number;
      }
    ) => {
      if (cellContourList.length === 0) {
        toast.info(WARNING_MESSAGES[EWarningType.noObjectLocation], {
          toastId: EWarningType.noObjectLocation,
        }); // Prevents showing info with the same text when quickly reopening the navigator
        return;
      }
      if (!entity.objectId) {
        toast.info(WARNING_MESSAGES[EWarningType.noObjectId], {
          toastId: EWarningType.noObjectId,
        });
        return;
      }
      const objectFromCurrentCage = (cellContourList as TCellContour[]).find((contour) => {
        if (contour.objectId !== entity.objectId) return;

        const minXPosition = cageBbox.xMin - OBJECT_CONTOUR_GAP;
        const maxXPosition = cageBbox.xMax + OBJECT_CONTOUR_GAP;
        const minYPosition = cageBbox.yMin - OBJECT_CONTOUR_GAP;
        const maxYPosition = cageBbox.yMax + OBJECT_CONTOUR_GAP;

        const [cellX, cellY] = contour.canvasPoints[0];

        const isObjectInsideCurrentCage =
          cellX > minXPosition && cellX < maxXPosition && cellY > minYPosition && cellY < maxYPosition;

        return isObjectInsideCurrentCage;
      });

      if (!objectFromCurrentCage) {
        toast.info(WARNING_MESSAGES[EWarningType.notFoundInContours], { toastId: EWarningType.notFoundInContours });
      }
      return objectFromCurrentCage;
    },
    [cellContourList]
  );

  const highlightCageInNavigator = useCallback(
    (entity: TEntity, shouldOpenImageIfError = true) => {
      const cageContour = findCageContour(entity);

      if (!cageContour) {
        if (shouldOpenImageIfError) {
          openImageModal(entity);
        }
        return;
      }

      const MIN_ZOOM_TO_HIGHLIGHT_CAGE = 3.5;
      if (zoom < MIN_ZOOM_TO_HIGHLIGHT_CAGE) {
        appDispatch(
          navigatorActions.setZoom({
            experimentId,
            laneId: currentLaneId,
            options: {
              zoom: MIN_ZOOM_TO_HIGHLIGHT_CAGE,
              lastEdit: LAST_EDIT.SEARCH,
            },
          })
        );
      }

      const objBbox = contoursOnCanvas.getObjectBbox(cageContour);

      appDispatch(
        navigatorActions.setPosition({
          experimentId,
          laneId: currentLaneId,
          options: {
            x: objBbox.xMin + (objBbox.xMax - objBbox.xMin) / 2,
            y: objBbox.yMin + (objBbox.yMax - objBbox.yMin) / 2,
            lastEdit: LAST_EDIT.SEARCH,
          },
        })
      );

      const highlightedContourList: THighlightedContourItem[] = [
        {
          canvasPoints: [
            [objBbox.xMin, objBbox.yMin],
            [objBbox.xMax, objBbox.yMin],
            [objBbox.xMax, objBbox.yMax],
            [objBbox.xMin, objBbox.yMax],
            [objBbox.xMin, objBbox.yMin],
          ],
        },
      ];

      if (isObjectEntityEnabled) {
        const cellContour = findCellContourForCurrentCage(entity, objBbox);
        let cellObjBbox = null;

        if (cellContour) {
          cellObjBbox = contoursOnCanvas.getObjectBbox(cellContour);
        }

        if (cellObjBbox) {
          highlightedContourList.push({
            canvasPoints: [
              [cellObjBbox.xMin, cellObjBbox.yMin],
              [cellObjBbox.xMax, cellObjBbox.yMin],
              [cellObjBbox.xMax, cellObjBbox.yMax],
              [cellObjBbox.xMin, cellObjBbox.yMax],
              [cellObjBbox.xMin, cellObjBbox.yMin],
            ],
            contourSettings: CURRENT_OBJECT_DRAW_SETTINGS,
          });
        }
      }

      appDispatch(viewerActions.setHighlightedContourList(highlightedContourList));
    },
    [findCageContour, zoom, experimentId, currentLaneId, isObjectEntityEnabled]
  );

  const dismissToast = () => {
    if (toastId.current) {
      toast.dismiss(toastId.current);
      toastId.current = null;
    }
  };

  useEffect(() => {
    if (!highlightedCage) {
      return;
    }

    if (isCageContourListLoading) {
      if (!toastId.current) {
        toastId.current = toast.loading('The cage will be highlighted when cage contours are loaded');
      }
    } else {
      setTimeout(() => {
        dismissToast();
        highlightCageInNavigator(highlightedCage, false);
        appDispatch(viewerActions.setHighlightedCage(null));
      }, 100);
    }
  }, [highlightedCage, isCageContourListLoading, highlightCageInNavigator]);

  useEffect(() => dismissToast, []);

  useEffect(
    () => () => {
      appDispatch(viewerActions.setHighlightedContourList([]));
    },
    [isSearchDisabled]
  );

  return (
    <div className={cn('entities-search-block')}>
      <EntitiesSearch
        entityList={entityList}
        onEntitySelect={highlightCageInNavigator}
        disabled={isSearchDisabled}
        isAlwaysOpen
        placeholder={isObjectEntityEnabled ? 'Search for objects (Object level)' : 'Search for cages (Cage level)'}
        className={cn('entities-search-block__input')}
        popoverPositions={['bottom', 'top']}
        isOnlyGlobalCageId
      />
    </div>
  );
};

export default memo(EntitiesSearchBlock);
