import { FC, memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { preprocessingActions, preprocessingSelectors } from '@/store/slices/preprocessing';
import { EStepName } from '@/store/slices/preprocessing/types';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { chartDataSelectors } from '@/store/slices/chartData';

import { EPreprocessingAssayType } from '@/helpers';
import { useObjectEntitiesByLanesAndGates } from '@/hooks/useExperimentContext/useObjectEntitiesByLanesAndGates';
import { useParamsExperimentId } from '@/hooks';

import ChooseAssay from './ChooseAssay';
import Beads from './Beads';

import styles from './StepInit.module.scss';
import StepActions, { TAction, TActionGroup } from '../components/StepActions';
import Loader from '../components/Loader';

const cn = classnames.bind(styles);

type TStepInit = {
  className?: string;
};

const StepInit: FC<TStepInit> = ({ className }) => {
  const appDispatch = useDispatch();
  const experimentId = useParamsExperimentId();
  const multiChartEntityListData = useSelector(chartDataSelectors.selectMultiChartEntityListData);
  const axesOptionListLength = useSelector(preprocessingSelectors.selectAxesOptionListLength);
  const selectedPreprocessingAssayId = useSelector(preprocessingSelectors.selectPreprocessingAssayId);
  const isStepInitCompleted = useSelector(preprocessingSelectors.selectIsStepInitCompleted);
  const datasets = useSelector(preprocessingSelectors.selectDatasets);
  const { allOptionList, allOptionMapping, generalChannelOptionMap } = useSelector(
    chartSettingsSelectors.selectMultiLanesAxesOptionList(datasets, true)
  );

  const [nextStep, setNextStep] = useState<Nullable<EStepName>>(null);

  const datasetList = useMemo(() => datasets.map((dataset) => dataset.dataset), [datasets]);

  useObjectEntitiesByLanesAndGates({
    experimentId,
    currentAppDatasetList: datasetList,
    currentAppDataset: null,
  });

  const actionGroups = useMemo<TActionGroup[]>(() => {
    const res: TActionGroup[] = [];

    const actionGroup: TAction[] = [
      {
        label: 'Next',
        disabled: !isStepInitCompleted,
        onClick: () => {
          if (!selectedPreprocessingAssayId) return;
          const step =
            selectedPreprocessingAssayId === EPreprocessingAssayType.cytokineSecretion
              ? EStepName.stepCytokineDefineBeads
              : EStepName.stepCellKillingDefineCells;

          setNextStep(step);
        },
      },
    ];

    res.push(actionGroup);

    return res;
  }, [isStepInitCompleted, selectedPreprocessingAssayId]);

  useEffect(() => {
    appDispatch(preprocessingActions.resetState());
  }, []);

  useEffect(() => {
    appDispatch(preprocessingActions.setAxesOptionList(allOptionList));
  }, [allOptionList]);

  useEffect(() => {
    appDispatch(preprocessingActions.setAxesOptionMap(allOptionMapping));
  }, [allOptionMapping]);

  useEffect(() => {
    appDispatch(preprocessingActions.setChannelOptionMap(generalChannelOptionMap));
  }, [generalChannelOptionMap]);

  const loaderText = useMemo(() => {
    const textList: string[] = [];
    if (!axesOptionListLength && !!datasets.length) {
      textList.push('Getting data about option list');
    }

    return textList;
  }, [axesOptionListLength, datasets.length]);

  const isShowLoader = useMemo(() => {
    const completeLoadingList = Object.values(multiChartEntityListData).filter((el) => el.isError || el.data);
    const isEqualLength = !!datasets.length && completeLoadingList.length !== datasets.length;
    return !axesOptionListLength && !!nextStep && isEqualLength;
  }, [axesOptionListLength, datasets.length, nextStep, multiChartEntityListData]);

  useEffect(() => {
    if (!nextStep || isShowLoader) {
      return;
    }

    appDispatch(preprocessingActions.setCurrentStep(nextStep));
  }, [nextStep, isShowLoader]);

  return (
    <>
      <div className={cn('step-one', className)}>
        <ChooseAssay />
        {selectedPreprocessingAssayId === EPreprocessingAssayType.cytokineSecretion && <Beads />}
      </div>
      <StepActions actionGroups={actionGroups} />
      <Loader isLoading={isShowLoader} textData={loaderText} />
    </>
  );
};

export default memo(StepInit);
