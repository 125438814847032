import { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';
import 'react-datepicker/dist/react-datepicker.css';

import styles from './InstrumentCard.module.scss';

const cn = classnames.bind(styles);

type TInstrumentCardHeaderLeftActionsProps = {
  className?: string;
  children: ReactNode;
};

const InstrumentCardHeaderLeftActions: FC<TInstrumentCardHeaderLeftActionsProps> = ({ className, children }) => (
  <div className={cn('header__left-actions', className)}>{children}</div>
);

export default InstrumentCardHeaderLeftActions;
