import { MutableRefObject, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { EChartType, EPlotlyChartType } from '@/types/charts';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import axisScaleHelper from '@/helpers/axisScaleHelper';
import { formatDecimalNumber } from '@/helpers';

import { EActiveEntityUuidLastEdit, scatterplotsActions, scatterplotsSelectors } from '@/store/slices/scatterplots';
import { datasetsSelectors } from '@/store/slices/datasets';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import { useExperimentModalsContext } from '@/contexts/ExperimentModalsContext';

import { useAppDispatch } from '../useAppDispatch';
import usePlotProxy from '../usePlotProxy';
import useHighlightMarker from '../charts/useHighlightMarker';

export type TCageInfo = {
  x: string;
  y: string;
  colorByValue: string;
} & Pick<TEntity, 'cageId' | 'snapshotId' | 'globalCageIdMatched'>;

type THoveredMarkerData = {
  index: number;
  snapshotId: number;
  cageId: number;
  globalCageIdMatched: number;
  entityUuid: string;
  x: string;
  y: string;
  tooltipX: number;
  tooltipY: number;
  colorByValue: string;
};

type TUseDatasetChartHoverResponse = {
  isHoverInfoOpen: boolean;
  cageData: Nullable<TCageInfo>;
  hoverInfoPosition: Nullable<[number, number]>;
};

export function useDatasetChartHover(
  graphRef: MutableRefObject<Nullable<IPlotlyHTMLDivElement>>,
  isPlotLoaded: boolean,
  isInteractionsEnabled: boolean,
  axesRange: TPlotAxisRange,
  origDataRange?: TPlotAxisRange
): TUseDatasetChartHoverResponse {
  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const { isCageInspectorOpen } = useExperimentModalsContext();

  const activeEntityUuid = useSelector(scatterplotsSelectors.selectActiveCageId);
  const isPreprocessingView = useSelector(datasetsSelectors.selectIsPreprocessingView);
  const { xAxisScaleType, yAxisScaleType } = useSelector(
    chartSettingsSelectors.selectCurrentScalesTypeForAxes(chartId)
  );
  const highlightDotsBy = useSelector(scatterplotsSelectors.selectHighlightDotsBy(chartId));
  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));

  const [hoveredMarkerData, setHoveredMarkerData] = useState<Nullable<THoveredMarkerData>>(null);
  const [hoverInfoPosition, setHoverInfoPosition] = useState<Nullable<[number, number]>>(null);
  const [isHoverInfoOpen, setIsHoverInfoOpen] = useState<boolean>(false);
  const [cageData, setCageData] = useState<Nullable<TCageInfo>>(null);

  const { highlightMarker, removeMarkerHighlight } = useHighlightMarker(plotlyProxy);

  const isHoverEffectsEnabled = useMemo(
    () =>
      graphRef.current?.data?.[0].type === EPlotlyChartType.scattergl &&
      [EChartType.dot, EChartType.dotDensity].includes(currentChartType),
    [graphRef.current?.data?.[0].type, currentChartType]
  );

  const hoverMarker = () => {
    if (!hoveredMarkerData) {
      return;
    }
    highlightMarker(hoveredMarkerData.index);
    setHoverInfoPosition([hoveredMarkerData.tooltipX, hoveredMarkerData.tooltipY]);
    setIsHoverInfoOpen(true);
    setCageData({
      snapshotId: hoveredMarkerData.snapshotId,
      cageId: hoveredMarkerData.cageId,
      globalCageIdMatched: hoveredMarkerData.globalCageIdMatched,
      x: hoveredMarkerData.x,
      y: hoveredMarkerData.y,
      colorByValue: hoveredMarkerData.colorByValue,
    });
    appDispatch(
      scatterplotsActions.setActiveEntityUuid({
        uuid: hoveredMarkerData.entityUuid,
        lastEdit: EActiveEntityUuidLastEdit.plot,
      })
    );
  };

  const removeMarkerHover = () => {
    removeMarkerHighlight();
    setIsHoverInfoOpen(false);
    setHoverInfoPosition(null);
    setCageData(null);
    appDispatch(scatterplotsActions.setActiveEntityUuid({ uuid: '', lastEdit: EActiveEntityUuidLastEdit.plot }));
  };

  const updateHoveredMarkerData = (markerData: Nullable<THoveredMarkerData>) => {
    // Sequential work with data through an intermediate object prevents getting into a loop between hovers, unhovers and plot restyles
    setHoveredMarkerData(markerData);
  };

  const handleMarkerHover = useCallback(
    (eventData: TPlotHoverEvent) => {
      if (!isHoverEffectsEnabled || !eventData?.points?.[0]) {
        return;
      }

      const { pointIndex, customdata, x, y } = eventData.points[0];
      const { x: eventX, y: eventY } = eventData.event;

      const xValue = axisScaleHelper.convertPlotValueToReal(xAxisScaleType, x, origDataRange?.x);
      const yValue = axisScaleHelper.convertPlotValueToReal(yAxisScaleType, y, origDataRange?.y);

      updateHoveredMarkerData({
        index: pointIndex,
        snapshotId: customdata.snapshotId,
        cageId: customdata.cageId,
        globalCageIdMatched: customdata.globalCageIdMatched,
        entityUuid: customdata.uuid,
        x: formatDecimalNumber(xValue, 3),
        y: formatDecimalNumber(yValue, 3),
        tooltipX: eventX,
        tooltipY: eventY,
        colorByValue: customdata?.[highlightDotsBy]?.toFixed(3) ?? '',
      });
    },
    [graphRef.current?.data, xAxisScaleType, yAxisScaleType, origDataRange, highlightDotsBy, isHoverEffectsEnabled]
  );

  const handleMarkerUnHover = () => {
    if (!isHoverEffectsEnabled) {
      return;
    }

    updateHoveredMarkerData(null);
  };

  useEffect(() => {
    if (hoveredMarkerData) {
      hoverMarker();
    } else {
      removeMarkerHover();
    }
  }, [hoveredMarkerData]);

  const handleActiveCageChange = () => {
    if (isHoverEffectsEnabled || !graphRef.current?.data?.[0]?.customdata) {
      return;
    }

    if (activeEntityUuid && activeEntityUuid.lastEdit !== EActiveEntityUuidLastEdit.plot) {
      if (activeEntityUuid.uuid) {
        const { customdata } = graphRef.current.data[0];
        const activeMarkerIndex = customdata.findIndex((data: TEntity) => data.uuid === activeEntityUuid.uuid);
        if (activeMarkerIndex === -1) return;
        highlightMarker(activeMarkerIndex);
      } else {
        removeMarkerHighlight();
      }
    }
  };

  useEffect(() => {
    handleActiveCageChange();
  }, [activeEntityUuid, graphRef.current]);

  useEffect(() => {
    // removes hover from markers while zooming
    if (!isHoverInfoOpen) return;
    removeMarkerHover();
  }, [axesRange]);

  useEffect(() => {
    if (isHoverInfoOpen && isCageInspectorOpen) {
      removeMarkerHover();
    }
  }, [isHoverInfoOpen, isCageInspectorOpen]);

  useEffect(() => {
    if (!graphRef.current || !isPlotLoaded || !isHoverEffectsEnabled) return;

    const graphDiv = graphRef.current;

    if (isInteractionsEnabled && !isPreprocessingView) {
      graphRef.current.on('plotly_hover', handleMarkerHover);
      graphRef.current.on('plotly_unhover', handleMarkerUnHover);
    } else {
      graphRef.current.removeAllListeners('plotly_hover');
      graphRef.current.removeAllListeners('plotly_unhover');
    }

    return () => {
      if (!graphDiv.removeAllListeners) {
        return;
      }
      graphDiv?.removeAllListeners('plotly_hover');
      graphDiv?.removeAllListeners('plotly_unhover');
    };
  }, [
    isPlotLoaded,
    graphRef.current?.data,
    isInteractionsEnabled,
    isPreprocessingView,
    handleMarkerHover,
    isHoverEffectsEnabled,
  ]);

  return {
    isHoverInfoOpen,
    cageData,
    hoverInfoPosition,
  };
}

export default useDatasetChartHover;
