import { memo, useMemo } from 'react';
import classnames from 'classnames/bind';
import { Link } from 'react-router-dom';

import { DOUBLE_DASH, formatDate, formatExperimentDuration, formatNumber } from '@/helpers';
import { getExperimentProjectsDataToDisplay, getInstrumentDashboardLink } from '@/helpers/experiment/summary';

import Modal, { TModal } from '@/components/common/Modal';
import NoDataFound from '@/components/common/NoDataFound';
import Metadata from '@/components/common/Metadata';

import styles from './DescriptionModal.module.scss';

const cn = classnames.bind(styles);

export type TDescriptionModal = {
  experiment?: TExperimentFromServer;
  animationType?: 'slide-animation' | 'splash-animation';
  description?: string; //  remove props when experiment description field is known
  totalCages?: number;
} & Pick<TModal, 'onRequestClose' | 'isOpen'>;

const DescriptionModal = ({
  experiment,
  isOpen,
  onRequestClose,
  animationType,
  description = '',
  totalCages,
}: TDescriptionModal) => {
  const instrumentDashboardLink = useMemo(() => getInstrumentDashboardLink(experiment), [experiment]);

  const basicInfo = [
    { id: 0, title: 'Operator', data: experiment?.operator ?? <NoDataFound alignment="left" textData={DOUBLE_DASH} /> },
    {
      id: 1,
      title: 'Instrument',
      data:
        experiment?.instrument?.friendlyName && instrumentDashboardLink ? (
          <Link className={cn('link')} to={instrumentDashboardLink}>
            {experiment?.instrument?.friendlyName}
          </Link>
        ) : (
          <NoDataFound alignment="left" textData={DOUBLE_DASH} />
        ),
    },
    {
      id: 2,
      title: 'Date',
      data: formatDate(experiment?.dateCreated, 'MMMM d, yyyy') ?? (
        <NoDataFound alignment="left" textData={DOUBLE_DASH} />
      ),
    },
    {
      id: 3,
      title: 'Duration',
      data: experiment?.duration ? (
        formatExperimentDuration(experiment?.duration)
      ) : (
        <NoDataFound alignment="left" textData={DOUBLE_DASH} />
      ),
    },
    { id: 4, title: 'Scans total', data: experiment?.numberOfScans ?? 0 },
  ];

  const [projectNames, projectListPluralSymbol] = useMemo(
    () => getExperimentProjectsDataToDisplay(experiment?.projects),
    [experiment?.projects]
  );

  return (
    <Modal
      sidebar="right"
      onRequestClose={onRequestClose}
      isOpen={isOpen}
      shouldCloseOnOverlayClick
      className={cn('description-modal')}
      animationType={animationType}
    >
      <Modal.Header className={cn('description-modal__header')}>
        <h2 className={cn('header__title')}>Information</h2>
      </Modal.Header>
      <Modal.Content className={cn('description-modal__body')}>
        <div className={cn('body__content')}>
          <div className={cn('body__summary')}>
            <div className={cn('summary__details')}>
              <h3 className={cn('details__title')}>{formatNumber(totalCages)}</h3>
              <span className={cn('title__caption')}>Cages total</span>
            </div>
            <div className={cn('summary__details')}>
              <h3 className={cn('details__title')}>{experiment?.numberOfSamples ?? 0}</h3>
              <span className={cn('title__caption')}>Samples total</span>
            </div>
            <div className={cn('summary__details')}>
              {projectNames.trim() && (
                <>
                  <h3 className={cn('details__title', 'title_resized', 'title_extra-top-space')}>{projectNames}</h3>
                  <span className={cn('title__caption')}>{`Project${projectListPluralSymbol}`}</span>
                </>
              )}
            </div>
            <div className={cn('summary__details', 'details__rows')}>
              <Metadata className={cn('experiment-page-header__metadata')}>
                {basicInfo.map((elem) => (
                  <Metadata.Item
                    titleClassName={cn('text-color-light')}
                    title={elem.title}
                    description={elem.data}
                    key={elem.id}
                  />
                ))}
              </Metadata>
            </div>
          </div>
          <div className={cn('body__description')}>
            {description ? (
              <>
                <h4 className={cn('description__title')}>Abstract</h4>
                <div className={cn('description__info')}>{description}</div>
              </>
            ) : (
              <NoDataFound size="normal" alignment="center" />
            )}
          </div>
        </div>
      </Modal.Content>
    </Modal>
  );
};
export default memo(DescriptionModal);
