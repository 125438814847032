import { EPreprocessingObjectType, TGeneralBeadShape, TGeneralCellShape } from '../store/slices/preprocessing/types';

type TGateTypeCheckPayload = {
  type: TGateType;
  model: unknown;
};

export function isDefined<Type>(value: unknown): value is NonNullable<Type> {
  return value !== undefined && value !== null && !Number.isNaN(value);
}

export const isCageContourFromServer = (value: unknown) =>
  isDefined(value) && 'image_id' in value && 'contour_xy' in value;

export const isCellContourFromServer = (value: unknown) =>
  isDefined(value) && 'image_id' in value && 'contour_xy' in value;

export const isCircleTypeGate = (shape: TGateTypeCheckPayload): shape is TCircleGateShape => shape?.type === 'circle';

export const isPolygonTypeGate = (shape: TGateTypeCheckPayload): shape is TPolygonGateShape =>
  shape?.type === 'polygon';

export const isPolarTypeGate = (shape: TGateTypeCheckPayload): shape is TPolarGateShape => shape?.type === 'polar';

export const isPolarAreaTypeGate = (shape: TGateTypeCheckPayload): shape is TPolarAreaGateShape =>
  shape?.type === 'polar-sector';

export const isPolarSplitGateType = (
  shape: TGateTypeCheckPayload,
  properties?: TGateProperties
): shape is TPolarGateShape => {
  const isPolarSlitGate = Boolean(properties?.type && properties.type === 'split-gate');
  return isPolarTypeGate(shape) && isPolarSlitGate;
};

export const isSplitGatePolarSectorType = (
  shape: TGateTypeCheckPayload,
  properties?: TGateProperties
): shape is TPolarAreaGateShape => {
  const isPolarSlitGate = Boolean(properties?.type && properties.type === 'split-gate');
  return isPolarAreaTypeGate(shape) && isPolarSlitGate;
};

export const isRangeTypeGate = (shape?: TGateTypeCheckPayload): shape is TRangeGateShape => shape?.type === 'range';

export const isGeneralCellShape = (
  shape: TGeneralBeadShape | TGeneralCellShape,
  preprocessingType: EPreprocessingObjectType
): shape is TGeneralCellShape => preprocessingType === EPreprocessingObjectType.cells;

export const isMultipleInstrumentVersionGroups = (
  versions?: TComponentsVersion[] | Record<string, TComponentsVersion[]>
): versions is Record<string, TComponentsVersion[]> => isDefined(versions) && typeof versions === 'object';
