import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { getCorrectNameOfAxes } from '@/helpers/preprocessing';
import axisScaleHelper from '@/helpers/axisScaleHelper';
import { getCoordinatesByAxesAndGate } from '@/helpers/charts/chartsData';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { useObjectEntitiesByLanesAndGates } from '@/hooks/useExperimentContext/useObjectEntitiesByLanesAndGates';
import { getEntitiesData } from '@/hooks/useExperimentContext/helpers';
import { useAppDispatch } from '@/hooks/useAppDispatch';

import { preprocessingSelectors } from '@/store/slices/preprocessing';
import { scatterplotsSelectors } from '@/store/slices/scatterplots';
import { chartSettingsActions, chartSettingsSelectors } from '@/store/slices/chartSettings';

import useSelectedGateForUnitedSpecificShapes from './useSelectedGateForUnitedSpecificShapes';

type TUseGeneralRangeProps = {
  experimentId: string;
  datasetList: TDataset[];
  customPostfixString?: string;
};
const useGeneralRange = ({ experimentId, datasetList, customPostfixString = '' }: TUseGeneralRangeProps) => {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const axesOptionMap = useSelector(preprocessingSelectors.selectAxesOptionMap);
  const xAxis = useSelector(scatterplotsSelectors.selectXAxis());
  const yAxis = useSelector(scatterplotsSelectors.selectYAxis());
  const objectType = useSelector(chartSettingsSelectors.selectObjectType(chartId));
  const selectedGate = useSelectedGateForUnitedSpecificShapes();

  const { xAxisScaleType, yAxisScaleType } = useSelector(
    chartSettingsSelectors.selectCurrentScalesTypeForAxes(chartId)
  );

  const gates = useMemo(() => (selectedGate ? [selectedGate] : undefined), [selectedGate]);

  const { objectEntitiesByLanesAndGates } = useObjectEntitiesByLanesAndGates({
    experimentId,
    currentAppDatasetList: datasetList,
    currentAppDataset: null,
    gateList: gates,
    isForceUpdateEntities: true,
  });

  const calculate = useCallback(() => {
    if (!datasetList.length) {
      return;
    }

    const { xAxisFromMap, yAxisFromMap } = getCorrectNameOfAxes(axesOptionMap, datasetList[0].name, xAxis, yAxis);
    const rangeKey = `${objectType}_${xAxisFromMap}_${yAxisFromMap}_${customPostfixString}_preprocessing`;
    appDispatch(chartSettingsActions.setPlotRangesMapName(rangeKey));
    datasetList.forEach((dataset) => {
      const entityList = getEntitiesData(objectEntitiesByLanesAndGates, dataset.path)?.cageList ?? [];

      const { coordinates } = getCoordinatesByAxesAndGate({
        entityList,
        xAxis,
        yAxis,
        gate: selectedGate,
        entityLevelGateList: gates,
      });
      const range = axisScaleHelper.getPlotRange({
        xAxisScaleType,
        yAxisScaleType,
        x: coordinates.x,
        y: coordinates.y,
      });
      appDispatch(chartSettingsActions.setPlotRange({ range, rangeName: rangeKey }));
    });
  }, [
    objectEntitiesByLanesAndGates,
    xAxis,
    yAxis,
    objectType,
    axesOptionMap,
    datasetList,
    customPostfixString,
    selectedGate,
    gates,
    xAxisScaleType,
    yAxisScaleType,
  ]);

  useEffect(() => {
    calculate();
  }, [calculate]);
};

export default useGeneralRange;
