import { FC, memo, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import 'react-datepicker/dist/react-datepicker.css';
import { useQuery, useSubscription } from '@apollo/client';
import { format } from 'date-fns';

import * as queries from '@/graphql/queries';
import * as subscriptions from '@/graphql/subscriptions';

import useParamsInstrumentId from '@/hooks/useParamsInstrumentId';
import { addTooltip, arrToMapByKeys, sortData } from '@/helpers';
import { isDefined } from '@/helpers/typeGuards';

import instrumentDashboardStyles from '../../InstrumentDashboard.module.scss';
import styles from './LiveSensors.module.scss';

import InstrumentCard from '../InstrumentCard';
import SensorListItem from '../SensorListItem';

const cn = classnames.bind({ ...instrumentDashboardStyles, ...styles });

const LiveSensors: FC = () => {
  const instrumentId = useParamsInstrumentId();
  const [sensorsData, setSensorsData] = useState<TTelemetryByInstrument[]>([]);

  const { loading: isInstrumentTelemetryLoading, error: isInstrumentTelemetryError } = useQuery(
    queries.telemetryByInstrument,
    {
      variables: {
        instrumentId,
      },
      onCompleted(data) {
        setSensorsData(data?.telemetryByInstrument ?? []);
      },
    }
  );

  // TODO: It is necessary to test and change, if necessary, the method of receiving updated data by subscription
  const { data: subscriptionData } = useSubscription<{
    onInstrumentEvent: { data: { telemetryData: TTelemetryByInstrument[] } };
  }>(subscriptions.onInstrumentEvent, {
    variables: {
      instrumentId,
      types: ['InstrumentTelemetryPostV2'],
    },
    onData: () => {
      try {
        if (!subscriptionData?.onInstrumentEvent?.data?.telemetryData) return;

        const { telemetryData } = subscriptionData.onInstrumentEvent.data;

        setSensorsData((prevSensorsData) => {
          if (!Array.isArray(telemetryData)) return prevSensorsData;

          if (isDefined(telemetryData?.[0]) && 'name' in telemetryData[0] && isDefined(telemetryData[0].name))
            return prevSensorsData;

          const subscriptionDataMapByValue = arrToMapByKeys(telemetryData, 'name');

          return prevSensorsData.map((sensorData) => {
            const updatedSensorData = subscriptionDataMapByValue?.[sensorData.name];

            if (updatedSensorData) {
              return {
                ...sensorData,
                value: updatedSensorData.value,
                time: updatedSensorData.time,
              };
            }

            return sensorData;
          });
        });
      } catch (error) {
        console.warn(error);
      }
    },
  });

  const isEmptySensorsData = useMemo(
    () => !isInstrumentTelemetryLoading && !isInstrumentTelemetryError && !sensorsData?.length,
    [isInstrumentTelemetryLoading, isInstrumentTelemetryError, sensorsData]
  );

  const sortedSensorsData = useMemo(
    () =>
      sensorsData.toSorted((current, next) => {
        if (current.sequence === null) {
          return 1;
        }

        if (next.sequence === null) {
          return -1;
        }
        return sortData(current.sequence, next.sequence);
      }),
    [sensorsData]
  );

  const latestUpdatedSensor = useMemo(() => {
    if (!sensorsData.length) return null;

    return sensorsData.reduce((latest, current) => {
      if (!current.time) return latest;
      if (!latest?.time) return current;

      return new Date(current.time) > new Date(latest.time) ? current : latest;
    }, sensorsData[0]);
  }, [sensorsData]);

  return (
    <InstrumentCard className={cn('content-block')}>
      <InstrumentCard.Header title="Live sensor readings">
        {latestUpdatedSensor && (
          <InstrumentCard.HeaderLeftActions>
            <span {...addTooltip(latestUpdatedSensor.label)}>
              {format(new Date(latestUpdatedSensor.time), 'yyyy-MM-dd HH:mm:ss')}
            </span>
          </InstrumentCard.HeaderLeftActions>
        )}
      </InstrumentCard.Header>
      <InstrumentCard.Content
        isLoading={isInstrumentTelemetryLoading}
        isNoData={!!(isEmptySensorsData || isInstrumentTelemetryError)}
        noDataMessage={isInstrumentTelemetryError ? 'Something went wrong' : 'No data found'}
      >
        <div className={cn('list-block')}>
          <div className={cn('list-block__list')}>
            {sortedSensorsData?.map((telemetry: TTelemetryByInstrument) => (
              <SensorListItem key={telemetry.name} telemetrySensor={telemetry} className={cn('list-block__item')} />
            ))}
          </div>
        </div>
      </InstrumentCard.Content>
    </InstrumentCard>
  );
};

export default memo(LiveSensors);
