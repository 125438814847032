import { useState } from 'react';
import icons from '@/components/common/icons';
import { removeAnsiSymbols } from '@/helpers';
import Button from '../Button';

const CopyToClipboardButton = ({
  content,
  className,
  disabled,
}: {
  content: string;
  className?: string;
  disabled?: boolean;
}) => {
  const [isCopied, setIsCopied] = useState(false);

  const showCopiedInfo = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(removeAnsiSymbols(content));
      showCopiedInfo();
    } catch (error) {
      console.warn('Unable copy to clipboard:', error);
    }
  };

  return (
    <Button isFitContent className={className} onClick={handleCopy} disabled={disabled}>
      {isCopied ? <icons.CheckMarkIcon /> : <icons.CopyIcon fill="#222222" />}
    </Button>
  );
};

export default CopyToClipboardButton;
