import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useConfirmationModalContext } from '@/components/common/ConfirmationModalProvider';
import { CONFIRMATION_MODAL_TEXT } from '@/components/charts/ChartSettingsButton/SettingsPopover/constants';
import { EPageWithChartType } from '@/types/charts';

import { chartSettingsActions, chartSettingsSelectors } from '@/store/slices/chartSettings';
import { gatesSelectors } from '@/store/slices/gates';
import { histogramSettingsSelectors } from '@/store/slices/histogramSettings';
import { useAppDispatch } from '../useAppDispatch';

export function useSettingsNotification({ pageType }: { pageType: EPageWithChartType }) {
  const confirmationModal = useConfirmationModalContext();
  const appDispatch = useAppDispatch();

  const specificChartSettingsOptionMap = useSelector(chartSettingsSelectors.selectSpecificDatasetOptionMap);
  const specificHistogramSettingsOptionMap = useSelector(histogramSettingsSelectors.selectSpecificDatasetOptionMap);
  const specificGatesSettingsOptionMap = useSelector(gatesSelectors.selectSpecificDatasetOptionMap);

  const fullScreenChartData = useSelector(chartSettingsSelectors.selectFullScreenChartData);
  const isSpecificSettingsExist = useMemo(
    () =>
      Object.keys(specificChartSettingsOptionMap).length ||
      Object.keys(specificHistogramSettingsOptionMap).length ||
      Object.keys(specificGatesSettingsOptionMap).length,
    [specificChartSettingsOptionMap, specificHistogramSettingsOptionMap, specificGatesSettingsOptionMap]
  );

  const isShowConfirmationWindow = useMemo(
    () => !fullScreenChartData && isSpecificSettingsExist && pageType === EPageWithChartType.matrixView,
    [fullScreenChartData, isSpecificSettingsExist, pageType]
  );

  const isSettingsChangingConfirmed = useCallback(async () => {
    const isConfirmed = isShowConfirmationWindow
      ? await confirmationModal.onOpen({
          confirmationText: CONFIRMATION_MODAL_TEXT,
          approveButtonText: 'Continue',
          cancelButtonText: 'Cancel',
        })
      : true;

    if (isConfirmed && !fullScreenChartData) {
      appDispatch(chartSettingsActions.clearAllSpecificChartSettings());
    }

    return isConfirmed;
  }, [isShowConfirmationWindow]);

  return {
    isSettingsChangingConfirmed,
  };
}

export default useSettingsNotification;
