import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { ECdnObjectType } from '@/types/cdnData';

import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { findLaneInScanList } from '@/helpers/scans';
import { getCdnTableDataFileName } from '@/helpers/cdnData/fetch';

import {
  getEntitiesDataByGates,
  getObjectTypeForStatisticCalculation,
  getSpecificScaleTypeForDataset,
  UNGATED_ID,
} from '@/hooks/useExperimentContext/helpers';

import { cdnAPI } from '@/store/services/cdnData';
import { experimentSelectors } from '@/store/slices/experiment';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { datasetsSelectors } from '@/store/slices/datasets';
import { EAxesGroupName, scatterplotsSelectors } from '@/store/slices/scatterplots';
import { viewerSelectors } from '@/store/slices/viewer';
import { chartDataSelectors } from '@/store/slices/chartData';
import { TUseObjectEntitiesByLanesAndGatesProps } from './useObjectEntitiesByLanesAndGates';

type TUseCageEntitiesByLanesAndGatesProps = Omit<TUseObjectEntitiesByLanesAndGatesProps, 'isForceUpdateEntities'>;

export function useCageEntitiesByLanesAndGates({
  experimentId,
  currentAppDatasetList,
  currentAppDataset,
  gateList,
  chartDataList, // TODO: check if there is a way to get information about the currently displayed datasets (taking into account the channel) in some other way for the correct formation of statistics
}: TUseCageEntitiesByLanesAndGatesProps) {
  const chartId = usePlotChartIdContext();

  const fullScreenChartData = useSelector(chartSettingsSelectors.selectFullScreenChartData);
  const useOptimizedData = useSelector(viewerSelectors.selectUseOptimizedData);
  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const objectType = useSelector(chartSettingsSelectors.selectObjectType(chartId));
  const laneDatasetDetailsList = useSelector(datasetsSelectors.selectDatasetDetailsList(experimentId));
  const currentChartData = useSelector(chartDataSelectors.selectCurrentChartData);

  const chartIdForStatisticCalculation = useMemo(
    () => fullScreenChartData?.id ?? currentChartData?.id ?? chartId,
    [fullScreenChartData, currentChartData, chartId]
  );
  const { xAxisScaleType, yAxisScaleType } = useSelector(
    chartSettingsSelectors.selectCurrentScalesTypeForAxes(chartIdForStatisticCalculation)
  );
  const specificAxesGroupName = fullScreenChartData ? EAxesGroupName.singleChart : undefined;
  const xAxis = useSelector(scatterplotsSelectors.selectXAxis(specificAxesGroupName));
  const yAxis = useSelector(scatterplotsSelectors.selectYAxis(specificAxesGroupName));
  const allGlobalChartSettings = useSelector(chartSettingsSelectors.selectAllGlobalChartSettings);

  const [fetchCageEntityList] = cdnAPI.useLazyFetchCageEntityListQuery();

  const [cageEntitiesByLanesAndGates, setCageEntitiesByLanesAndGates] = useState<TEntitiesByLanesAndGates>({});
  const [cageEntitiesLoadingLaneList, setCageEntitiesLoadingLaneList] = useState<string[]>([]);
  const [cageEntitiesLoadedLaneList, setCageEntitiesLoadedLaneList] = useState<string[]>([]);
  const [isError, setIsError] = useState<boolean>(false);

  useEffect(() => {
    if (!gateList) {
      return;
    }
    // recalculate gates percentages for all cage data
    const newCageEntitiesByLanesAndGates: TEntitiesByLanesAndGates = {};
    Object.keys(cageEntitiesByLanesAndGates).forEach((lanePath) => {
      const cagesData = cageEntitiesByLanesAndGates[lanePath];
      const dataset = laneDatasetDetailsList.find((lane) => lane.dataset.path === lanePath);

      const displayedChartDetails = chartDataList?.find((details) => details.dataset.path === lanePath);
      const displayedDatasetId = fullScreenChartData?.id ?? displayedChartDetails?.id;

      const scaleTypesForCurrentChart = displayedDatasetId
        ? getSpecificScaleTypeForDataset(displayedDatasetId, allGlobalChartSettings)
        : { xAxisScaleType, yAxisScaleType };

      const objectTypeForCurrentChart = displayedDatasetId
        ? getObjectTypeForStatisticCalculation(displayedDatasetId, allGlobalChartSettings)
        : objectType;

      newCageEntitiesByLanesAndGates[lanePath] = getEntitiesDataByGates({
        entityList: cagesData[UNGATED_ID].fullCageList,
        gateList,
        objectType: objectTypeForCurrentChart,
        scanId: dataset?.scanId,
        laneId: dataset?.laneId,
        xAxisScaleType: scaleTypesForCurrentChart.xAxisScaleType,
        yAxisScaleType: scaleTypesForCurrentChart.yAxisScaleType,
        xDimension: xAxis,
        yDimension: yAxis,
      });
    });
    setCageEntitiesByLanesAndGates(newCageEntitiesByLanesAndGates);
  }, [
    gateList,
    xAxisScaleType,
    yAxisScaleType,
    xAxis,
    yAxis,
    fullScreenChartData,
    chartDataList,
    allGlobalChartSettings.specificDatasetOptionMap,
  ]);

  useEffect(() => {
    if (!gateList) {
      return;
    }
    // recalculate gates percentages for just loaded cage data
    const newCageEntitiesByLanesAndGates: TEntitiesByLanesAndGates = {};
    cageEntitiesLoadedLaneList.forEach((lanePath) => {
      const cagesData = cageEntitiesByLanesAndGates[lanePath];

      if (!cagesData) return;

      const cagesDataKeys = Object.keys(cagesData);
      if (cagesDataKeys.length === 1 && cagesDataKeys[0] === UNGATED_ID) {
        const dataset = laneDatasetDetailsList.find((lane) => lane.dataset.path === lanePath);

        newCageEntitiesByLanesAndGates[lanePath] = getEntitiesDataByGates({
          entityList: cagesData[UNGATED_ID].fullCageList,
          gateList,
          objectType,
          scanId: dataset?.scanId,
          laneId: dataset?.laneId,
          xAxisScaleType,
          yAxisScaleType,
          xDimension: xAxis,
          yDimension: yAxis,
        });
      } else {
        newCageEntitiesByLanesAndGates[lanePath] = cagesData;
      }
    });
    setCageEntitiesByLanesAndGates(newCageEntitiesByLanesAndGates);
  }, [cageEntitiesLoadedLaneList]);

  useEffect(() => {
    if (!gateList) {
      return;
    }
    const newCageEntitiesByLanesAndGates: TEntitiesByLanesAndGates = {};
    const cageEntitiesByLanesAndGatesKeys = Object.keys(cageEntitiesByLanesAndGates);

    if (!cageEntitiesByLanesAndGatesKeys.length) return;

    cageEntitiesByLanesAndGatesKeys.forEach((lanePath) => {
      const cagesData = cageEntitiesByLanesAndGates[lanePath];
      const dataset = laneDatasetDetailsList.find((lane) => lane.dataset.path === lanePath);

      if (!cagesData) return;

      const entitiesDataByGates = getEntitiesDataByGates({
        entityList: cagesData[UNGATED_ID].fullCageList,
        gateList,
        objectType,
        scanId: dataset?.scanId,
        laneId: dataset?.laneId,
        xAxisScaleType,
        yAxisScaleType,
        xDimension: xAxis,
        yDimension: yAxis,
      });
      newCageEntitiesByLanesAndGates[lanePath] = entitiesDataByGates;
    });

    setCageEntitiesByLanesAndGates(newCageEntitiesByLanesAndGates);
  }, [objectType]);

  useEffect(() => {
    setIsError(false);

    const datasetList = [...currentAppDatasetList];
    if (currentAppDataset && !datasetList.find((dataset) => dataset.path === currentAppDataset.path)) {
      datasetList.push(currentAppDataset);
    }
    datasetList.forEach((dataset) => {
      const lane = findLaneInScanList(scanList, dataset.scanId, dataset.laneId);
      if (lane) {
        if (!cageEntitiesLoadingLaneList.includes(lane.path)) {
          setCageEntitiesLoadingLaneList((prev) => [...prev, lane.path]);
          fetchCageEntityList(lane, true)
            .unwrap()
            .then((cageList) => {
              const entitiesDataByGates = getEntitiesDataByGates({
                entityList: cageList,
                gateList: [],
                objectType,
                scanId: dataset.scanId,
                laneId: dataset.laneId,
                xAxisScaleType,
                yAxisScaleType,
                xDimension: xAxis,
                yDimension: yAxis,
              });
              setCageEntitiesByLanesAndGates((prev) => ({ ...prev, [lane.path]: entitiesDataByGates }));
            })
            .catch((error) => {
              setIsError(true);
              const { fileName } = getCdnTableDataFileName({
                lane,
                type: ECdnObjectType.cageEntity,
                useOptimizedData,
              });
              if (!fileName) {
                return;
              }
              showErrorToast(getErrorMessage(error));
            })
            .finally(() => {
              setCageEntitiesLoadedLaneList((prev) => [...prev, lane.path]);
            });
        }
      }
    });

    return () => {
      setIsError(false);
    };
  }, [currentAppDataset, currentAppDatasetList, objectType]);

  return { cageEntitiesByLanesAndGates, cageEntitiesLoadedLaneList, isError };
}
