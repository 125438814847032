import { FC, memo, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames/bind';
import { select as d3Select } from 'd3-selection';

import { EModebarTools, EPageWithChartType, EChartType, EAxesScaleType } from '@/types/charts';

import { handlePlotlyResize } from '@/helpers/plotly';
import { isPolarAreaTypeGate, isSplitGatePolarSectorType } from '@/helpers/typeGuards';
import { defineGateDeletionConfirmationMessage, findParentGate } from '@/helpers/gates';
import { getHistogramsYAxisLabel, isHistogramsChartType } from '@/helpers/charts/lineHistogram';
import axisScaleHelper from '@/helpers/axisScaleHelper';
import { addTooltip, isNumber } from '@/helpers';
import { findLaneInScanList } from '@/helpers/scans';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import {
  useChartRangeHandling,
  useDebounce,
  useExperimentContext,
  usePlotGateAndAxesUpdate,
  usePlotSettings,
  useScatterglPlotsSettings,
} from '@/hooks';
import { useDatasetChartHover } from '@/hooks/datasetAnalysis/useDatasetChartHover';
import { useGatesOnPlot } from '@/hooks/gates/useGatesOnPlot';
import { changeGateColor, hideGate } from '@/hooks/gates/helpers/common';
import { useHideGate } from '@/hooks/gates/useHideGate';
import { useChartSideEffects } from '@/hooks/useChartSideEffects';
import { useHistogramPlotsSettings } from '@/hooks/plotSettings/useHistogramPlotsSettings';
import type { TCageInfo } from '@/hooks/datasetAnalysis/useDatasetChartHover';
import usePlotProxy from '@/hooks/usePlotProxy';
import { useGatesShapeOverrides } from '@/hooks/gates/useGatesShapeOverrides';
import { useOrigDataRange } from '@/hooks/charts/useOrigDataRange';

import { gatesActions, gatesSelectors } from '@/store/slices/gates';
import { scatterplotsActions, scatterplotsSelectors } from '@/store/slices/scatterplots';
import { datasetsSelectors } from '@/store/slices/datasets';
import { preprocessingSelectors } from '@/store/slices/preprocessing';
import { chartSettingsActions, chartSettingsSelectors } from '@/store/slices/chartSettings';
import { selectAxesOptionListFromScanList } from '@/store/services/app/selectors';
import { histogramSettingsSelectors } from '@/store/slices/histogramSettings';
import { EStepName } from '@/store/slices/preprocessing/types';

import { useExperimentModalsContext } from '@/contexts/ExperimentModalsContext';

import icons from '@/components/common/icons';
import Popover, { TPopoverOption } from '@/components/common/Popover';
import { useConfirmationModalContext } from '@/components/common/ConfirmationModalProvider';
import NoDataFound from '@/components/common/NoDataFound';
import DownloadChartButton from '@/components/charts/DownloadChartButton';
import ChartSettingsButton from '@/components/charts/ChartSettingsButton';
import type { TDatasetChart } from '@/components/charts/SingleChartWithGates/types';
import UpdateGateChangeColorModal from '@/components/common/UpdateGateChangeColorModal';
import AxisYSelect from '@/components/charts/AxisYSelect';
import AxisXSelect from '@/components/charts/AxisXSelect';
import { getOption, isBasicOption } from '@/components/common/Select/helpers';
import RangeResetButton from '@/components/charts/RangeResetButton';
import EntitiesSearch from '@/components/common/EntitiesSearch';
import PointHoverTemplate, { TPointHoverTemplateData } from '@/components/charts/PointHoverTemplate';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { getPrimeAxisList } from '@/pages/Dataset/components/DatasetChart/helpers/datasetChart';

import { EUnselectedOption } from '@/types/settings';

import '@/styles/gates.scss';

import { experimentSelectors } from '@/store/slices/experiment';
import Modebar from '../Modebar';
import styles from './DatasetChart.module.scss';
import { DRAGMODES } from '../../constants';
import LogScaleWarning from '../LogScaleWarning';
import AxesSettingsPopover from '../AxesSettingsPopover';
import AxesControls from '../AxesControls';
import ChartModals from './ChartModals';

const cn = classnames.bind(styles);

const DYNAMIC_CHART_SELECTOR = '.nsewdrag.drag';

export type TDatasetChartProps = TDatasetChart;

// todo: split logic and elements of this component
const DatasetChart: FC<TDatasetChartProps> = ({
  isTransitionEnd,
  scatterPlotAxesOptions = [],
  scanId,
  laneId,
  scanList = [],
  currentAppLane,
  entitiesByLanesAndGates,
  deleteGate = () => null,
  entityLevelGateList,
  deleteGateChildren = () => null,
  updateGate = () => null,
  createGate,
  isCreateGatesInProgress = false,
  isUpdateGatesInProgress = false,
  isError,
  fullGateList = [],
  channelName,
  plotId = 'plot-chart',
  CustomCreateGateComponent,
  CustomUpdateGateComponent,
  onlyShowAxisValue,
  needResetRange = false,
  customPlotlySelectedHandler,
  handleSwapChannels,
  plotRangeName,
  specificAxesGroupName,
}) => {
  const appDispatch = useAppDispatch();
  const fullScreenChartData = useSelector(chartSettingsSelectors.selectFullScreenChartData);

  const chartId = usePlotChartIdContext();

  const { openCageInspector } = useExperimentModalsContext();

  const graphRef = useRef<Nullable<IPlotlyHTMLDivElement>>(null);
  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');

  const { chartDataList } = useExperimentContext();
  const experimentName = useSelector(experimentSelectors.selectCurrentExperimentName);
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const isTickLabelsVisible = useSelector(chartSettingsSelectors.selectIsTickLabelsVisible(chartId));
  const onlyGateToDisplay = useSelector(gatesSelectors.selectOnlyGateToDisplay);

  const { isYAxisDisabled } = usePlotSettings({ graphRef, specificAxesGroupName });

  useScatterglPlotsSettings({ graphRef });

  const selectedGate = useSelector(gatesSelectors.selectSelectedGate);
  const isPreprocessingView = useSelector(datasetsSelectors.selectIsPreprocessingView);
  const preprocessingDatasetIndex = useSelector(preprocessingSelectors.selectCurrentDatasetIndex);
  const currentPreprocessingStep = useSelector(preprocessingSelectors.selectCurrentStep);

  const activeGate = useSelector(gatesSelectors.selectActiveGate);
  const isCustomGateRangeActive = useSelector(gatesSelectors.selectIsCustomGateRangeActive);
  const xAxis = useSelector(scatterplotsSelectors.selectXAxis(specificAxesGroupName));
  const yAxis = useSelector(scatterplotsSelectors.selectYAxis(specificAxesGroupName));
  const plotRange = useSelector(chartSettingsSelectors.selectPlotRangeFactory(chartId)(plotRangeName));
  const { xAxisScaleType, yAxisScaleType } = useSelector(
    chartSettingsSelectors.selectCurrentScalesTypeForAxes(chartId)
  );
  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));
  const currentHistogramDataGroupType = useSelector(
    histogramSettingsSelectors.selectCurrentHistogramDataGroupType(chartId)
  );
  const highlightDotsBy = useSelector(scatterplotsSelectors.selectHighlightDotsBy(chartId));

  const origDataRange = useOrigDataRange({
    isSingleChart: true,
    scanId,
    laneId,
    xAxis,
    yAxis: isHistogramsChartType(currentChartType) ? xAxis : yAxis,
    xAxisScaleType,
    yAxisScaleType,
  });

  const [isPopoverOpened, setIsPopoverOpened] = useState(false);
  const [isGateUpdateModalOpened, setIsGateUpdateModalOpened] = useState<boolean>(false);
  const [openedAxisSettingsName, setOpenedAxisSettingsName] = useState<Nullable<'x' | 'y'>>(null);
  const [selectedTool, setSelectedTool] = useState<Nullable<EModebarTools>>(EModebarTools.zoom);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [popoverCoords, setPopoverCoords] = useState({ x: 0, y: 0 });
  const [isGateChangColorModalOpened, setIsGateChangColorModalOpened] = useState(false);

  const isDataInLogScaleDiscarded = useMemo(() => {
    if (!currentAppLane?.dataset?.path) return false;
    if (xAxisScaleType !== EAxesScaleType.log && yAxisScaleType !== EAxesScaleType.log) return false;

    const currentUngatedEntitiesData = entitiesByLanesAndGates?.[currentAppLane.dataset.path]?.ungated;

    if (!currentUngatedEntitiesData) return false;

    return currentUngatedEntitiesData.fullCageList.length > currentUngatedEntitiesData.cageList.length;
  }, [currentAppLane?.dataset?.path, entitiesByLanesAndGates, xAxisScaleType, yAxisScaleType]);

  const popoverBoundaryElementRef = useRef(null);

  const {
    axesRange,
    setAxesRange,
    handleAxisRangeChange,
    resetRangeToDefault,
    isCustomRangeUsed,
    maxChartRange,
    minChartRange,
    handlePlotZoomed,
    isRangeChangedByPlotly,
    isInteractionsEnabled,
  } = useChartRangeHandling({ openedAxisSettingsName, selectedTool, graphRef, rangeName: plotRangeName });

  const isResetDisabled = useMemo(
    () => !isCustomRangeUsed || isCustomGateRangeActive,
    [isCustomRangeUsed, isCustomGateRangeActive]
  );

  const {
    chartEntityList,
    chartEntityListError,
    isChartEntityListError,
    entitiesDataByGates,
    isPlotLoaded,
    isModebarControlsDisabled,
    isAllPlotDataLoaded,
    isEmptyData,
    isShowLoader,
  } = useChartSideEffects({
    graphRef,
    currentAppLane,
    entitiesByLanesAndGates,
    setOpenedAxisSettingsName,
    selectedTool,
    setSelectedTool,
    isTransitionEnd,
    isResetDisabled,
    entityLevelGateList,
    plotRangeName,
    pageType: EPageWithChartType.singleChart,
    specificAxesGroupName,
  });

  const hasNoChartDataForSelectedScan = useMemo(() => {
    if (isPreprocessingView) {
      const correctedYAxis = isHistogramsChartType(currentChartType) ? xAxis : yAxis;
      if (xAxis && correctedYAxis) {
        return isEmptyData;
      }
      return false;
    }
    return chartDataList.length === 0;
  }, [isPreprocessingView, xAxis, yAxis, isEmptyData, chartDataList.length]);

  useEffect(() => {
    if (needResetRange) {
      resetRangeToDefault();
    }
  }, [needResetRange]);

  const showSkeleton = useMemo(
    () => isShowLoader || (!isAllPlotDataLoaded && !hasNoChartDataForSelectedScan),
    [isShowLoader, isAllPlotDataLoaded, hasNoChartDataForSelectedScan]
  );
  const debouncedShowSkeleton = useDebounce(showSkeleton, 100);

  const debouncedIsAllPlotDataLoaded = useDebounce(isAllPlotDataLoaded, 100);
  const confirmationModal = useConfirmationModalContext();

  useHistogramPlotsSettings({
    graphRef,
    defaultChartConfigs: { isTickLabelsVisible },
    isPlotLoaded: debouncedIsAllPlotDataLoaded,
    datasetName: currentAppLane?.dataset.name ?? '',
    origDataRange,
  });

  const { gateProperties, isGateVisible, toggleGateVisibility } = useHideGate({
    gate: activeGate,
    gateList: fullGateList ?? [],
    updateGate,
  });

  const downloadChartFileName = useMemo(
    () => `${experimentName} dataset-${scanId}-${laneId} channel-${channelName}`,
    [scanId, laneId, channelName, experimentName]
  );

  const primaryAxisOptionList = useMemo(() => {
    if (isObjectEntityEnabled) {
      const optionList: TOption[] = selectAxesOptionListFromScanList(scanList, scanId, laneId, isObjectEntityEnabled);

      return optionList.map((option) => (isBasicOption(option) ? option.value : ''));
    }
    const currentScan = scanList.find((scan) => scan.id === scanId);
    const currentLane = currentScan?.lanes.find((lane) => lane.id === laneId);
    const markerList = currentLane?.classes;

    return getPrimeAxisList(channelName, markerList);
  }, [scanList, scanId, laneId, isObjectEntityEnabled]);

  const isHistogram = useMemo(() => isHistogramsChartType(currentChartType), [currentChartType]);

  const xAxisLabel = useMemo(() => {
    if (!Array.isArray(scatterPlotAxesOptions)) return '';

    return getOption(scatterPlotAxesOptions, xAxis)?.label ?? '';
  }, [scatterPlotAxesOptions, xAxis]);

  const yAxisLabel = useMemo(() => {
    if (isHistogram) {
      const titleByGroupType = getHistogramsYAxisLabel(currentHistogramDataGroupType);
      return titleByGroupType;
    }

    if (!Array.isArray(scatterPlotAxesOptions)) return '';

    return getOption(scatterPlotAxesOptions, yAxis)?.label ?? '';
  }, [scatterPlotAxesOptions, yAxis, isHistogram, currentHistogramDataGroupType]);

  const highlightDotsByLabel = useMemo(() => {
    if (
      highlightDotsBy === EUnselectedOption.value ||
      currentChartType !== EChartType.dot ||
      !Array.isArray(scatterPlotAxesOptions)
    )
      return '';

    return getOption(scatterPlotAxesOptions, highlightDotsBy)?.label ?? '';
  }, [scatterPlotAxesOptions, highlightDotsBy, currentChartType]);

  const handlePlotlySelected = (newGateData: Nullable<TNewGateModelData>) => {
    if (customPlotlySelectedHandler) {
      customPlotlySelectedHandler(newGateData);
      clearNewGateData();
    }
    setIsModalOpen(true);
  };

  const onContextMenu = (pos: Record<'x' | 'y', number>) => {
    if (
      isNumber(preprocessingDatasetIndex) &&
      currentPreprocessingStep !== EStepName.stepCellKillingDefineCellsTarget
    ) {
      return;
    }

    setIsPopoverOpened(true);
    setPopoverCoords(pos);
  };

  const handleToolClick = (key: EModebarTools) => {
    if (key === selectedTool) {
      return;
    }

    setSelectedTool(key);

    plotlyProxy.relayout({ dragmode: DRAGMODES[key] });
  };

  const swapChannels = () => {
    if (handleSwapChannels) {
      handleSwapChannels();
    }

    appDispatch(
      scatterplotsActions.setAxes({
        newAxes: { x: yAxis, y: xAxis },
        axesGroupName: specificAxesGroupName,
        withoutUpdatingGroupName: !!specificAxesGroupName,
      })
    );
    appDispatch(
      chartSettingsActions.setAxesScaleTypes({
        x: yAxisScaleType,
        y: xAxisScaleType,
      })
    );
  };

  const handleClickOutside = (event: MouseEvent) => {
    event.stopPropagation();
    const target = event.target as Element;
    const activeGateGroup = d3Select('.gate-group_active').node() as Element;

    if (
      !isGateUpdateModalOpened &&
      !activeGateGroup?.contains(target) &&
      event.button !== 2 &&
      target?.id === 'popover__backdrop'
    ) {
      d3Select(activeGateGroup).attr('class', 'gate-group');
      appDispatch(gatesActions.setActiveGate(null));
      setIsPopoverOpened(false);
    }
  };

  const handleDeleteGate = () => {
    if (!activeGate) return;
    const idToDelete = isPolarAreaTypeGate(activeGate.shape) ? activeGate.parentId : activeGate.id;

    deleteGate(idToDelete);
    appDispatch(gatesActions.setActiveGate(null));

    if (selectedGate?.id === idToDelete) {
      appDispatch(gatesActions.setSelectedGate(null));
    }
  };

  const openDeleteGateModal = async () => {
    if (!activeGate) return;

    const result = await confirmationModal.onOpen({
      confirmationText: defineGateDeletionConfirmationMessage(activeGate, entityLevelGateList),
      approveButtonText: 'Delete',
    });

    if (result) {
      handleDeleteGate();
    }
  };

  const handleDeleteChildren = () => {
    if (!entityLevelGateList?.length || !activeGate) return;
    deleteGateChildren(activeGate.id);
  };

  const openDeleteGateChildrenModal = async () => {
    if (!activeGate) return;
    const result = await confirmationModal.onOpen({
      confirmationText: `Are you sure you want to delete children gates of gate ${activeGate.name}?`,
      approveButtonText: 'Delete',
    });

    if (result) {
      handleDeleteChildren();
    }
  };

  const openEditGateModal = () => {
    setIsGateUpdateModalOpened(true);
  };

  const handleCustomRangeSaving = (updatedGate: TGate) => {
    appDispatch(gatesActions.setSelectedGate({ ...updatedGate }));
    appDispatch(gatesActions.setIsCustomGateRangeActive(true));
    setOpenedAxisSettingsName(null);
  };

  const openImageModal = (entity: TEntity) => {
    const lane = findLaneInScanList(scanList, scanId, laneId);
    openCageInspector({ entity, lane });
  };

  const handlePointClick = ({ points }: TPlotMouseEvent) => {
    const { customdata: pointEntity } = points[0] ?? {};
    if (pointEntity) {
      openImageModal(pointEntity);
    }
  };

  const closeGateChangeColorModal = () => {
    setIsGateChangColorModalOpened(false);
  };

  const saveGateCustomRange = () => {
    if (!selectedGate) return;

    const gateRange = {
      x: axesRange.x,
      y: axesRange.y,
    };
    const existedProperties = selectedGate.properties;
    const updatedProperties = existedProperties
      ? {
          ...existedProperties,
          range: { ...gateRange },
        }
      : { range: { ...gateRange } };

    updateGate(
      selectedGate,
      {
        properties: updatedProperties,
      },
      () => handleCustomRangeSaving({ ...selectedGate, properties: updatedProperties })
    );
  };

  const applyCustomGateRange = useCallback(() => {
    if (!gateProperties || !plotRange) return;

    const newCheckboxState = !isCustomGateRangeActive;

    if (newCheckboxState && gateProperties.range) {
      setAxesRange(gateProperties.range);
      const updatedLayoutConfig = {
        'xaxis.autorange': false,
        'xaxis.range': gateProperties.range.x, // updates the xaxis range
        'yaxis.autorange': false,
        'yaxis.range': gateProperties.range.y,
      };

      plotlyProxy.update(null, updatedLayoutConfig);
    } else {
      resetRangeToDefault();
    }
    appDispatch(gatesActions.setIsCustomGateRangeActive(newCheckboxState));
  }, [isCustomGateRangeActive, selectedGate, plotRange]);

  const handleCustomRangeDeletion = (properties: TGateProperties) => {
    appDispatch(gatesActions.setSelectedGate({ ...selectedGate, properties: JSON.stringify(properties) }));
  };

  const handleRangeResetClick = () => {
    resetRangeToDefault();
  };

  const openChangeGateColorModal = () => {
    setIsGateChangColorModalOpened(true);
  };

  const deleteCustomGateRange = useCallback(() => {
    if (!selectedGate || !gateProperties?.range) return;

    const properties = { ...gateProperties };

    delete properties.range;

    updateGate(selectedGate, { properties }, () => handleCustomRangeDeletion(properties));

    appDispatch(gatesActions.setIsCustomGateRangeActive(false));
  }, [selectedGate]);

  const {
    applyGateTo,
    resetGateToDefault,
    isResetToGlobalGateHidden,
    isApplyGateGloballyHidden,
    isApplyGateToSelectedHidden,
  } = useGatesShapeOverrides({
    updateGate,
    currentAppLane,
    gate: activeGate,
    pageType: EPageWithChartType.singleChart,
  });

  const handleShowOnlyOneGate = () => {
    if (!activeGate) return;

    const isPolarGateNodes = isPolarAreaTypeGate(activeGate.shape) || isSplitGatePolarSectorType(activeGate.shape);
    let gateData: Nullable<TGate> = activeGate;

    if (isPolarGateNodes) {
      gateData = findParentGate(entityLevelGateList, activeGate.parentId);
    }
    appDispatch(gatesActions.setOnlyGateToDisplay(gateData));
  };

  const options: TPopoverOption[] = [
    {
      id: 'show-only-this-gate',
      title: 'Show only this gate',
      icon: <icons.VisibilityOnIcon />,
      onClick: handleShowOnlyOneGate,
      hidden: onlyGateToDisplay?.id === activeGate?.id,
    },
    {
      id: 'rename-gate',
      title: 'Rename gate',
      icon: <icons.PencilIcon />,
      onClick: openEditGateModal,
      hidden: activeGate?.shape?.type === 'polar' || activeGate?.noEditable || isPreprocessingView,
    },
    {
      id: 'change-gate-color',
      title: 'Change gate color',
      icon: <icons.PencilIcon />,
      onClick: openChangeGateColorModal,
      hidden: isPreprocessingView,
    },
    {
      id: 'hide-gate',
      title: isGateVisible ? 'Hide gate' : 'Show gate',
      icon: isGateVisible ? <icons.VisibilityOffIcon /> : <icons.VisibilityOnIcon />,
      onClick: toggleGateVisibility,
      hidden: isPreprocessingView,
    },
    {
      id: 'delete-gate',
      title: 'Delete gate',
      icon: <icons.DeleteIcon />,
      onClick: () => {
        openDeleteGateModal();
      },
    },
    {
      id: 'delete-sub-gates',
      title: 'Delete children',
      icon: <icons.DeleteChildrensIcon />,
      onClick: () => {
        openDeleteGateChildrenModal();
      },
      hidden: !activeGate?.gateNodes?.length || activeGate?.shape?.type === 'polar' || isPreprocessingView,
    },
    {
      id: 'apply-gate-globally',
      title: 'Apply gate globally',
      icon: <icons.ApplyGateToIcon />,
      onClick: () => {
        applyGateTo();
      },
      hidden: isPreprocessingView || isApplyGateGloballyHidden,
    },
    {
      id: 'apply-gate-to-selected',
      title: 'Apply gate to selected datasets in Matrix view',
      icon: <icons.ApplyGateToIcon />,
      onClick: () => {
        applyGateTo(true);
      },
      hidden: isApplyGateToSelectedHidden,
    },
    {
      id: 'reset-gate-to-default',
      title: 'Reset gate to default',
      icon: <icons.ResetGateToGlobalIcon />,
      onClick: () => {
        resetGateToDefault();
      },
      hidden: isPreprocessingView || isResetToGlobalGateHidden,
    },
  ];

  const currentChartDataList = useMemo(
    () => (fullScreenChartData ? [fullScreenChartData] : chartDataList),
    [fullScreenChartData, chartDataList]
  );

  const usePlotGateAndAxesUpdateProps = useMemo(
    () => ({
      cageDataList: [{ cageList: chartEntityList }],
      graphRef,
      isAllPlotDataLoaded,
      entityLevelGateList,
      axesRange,
      setAxesRange,
      currentAppLane,
      chartDataList: currentChartDataList,
      specificAxesGroupName,
      scanId,
      laneId,
      pageType: EPageWithChartType.singleChart,
    }),
    [
      graphRef,
      chartEntityList,
      isAllPlotDataLoaded,
      entityLevelGateList,
      axesRange,
      setAxesRange,
      currentAppLane,
      scanId,
      laneId,
      specificAxesGroupName,
      currentChartDataList,
    ]
  );

  usePlotGateAndAxesUpdate(usePlotGateAndAxesUpdateProps);

  useEffect(() => {
    window.addEventListener('resize', handlePlotlyResize);

    return () => {
      window.removeEventListener('resize', handlePlotlyResize);
    };
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      if (!graphRef.current) return;
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isGateUpdateModalOpened]);

  useEffect(() => {
    const graphDiv = graphRef.current;
    if (!isPlotLoaded || !graphRef.current || isPreprocessingView) return;

    graphRef.current.on('plotly_click', handlePointClick);

    return () => {
      if (!graphDiv?.removeAllListeners) return;

      graphDiv.removeAllListeners('plotly_click');
    };
  }, [graphRef.current, isPlotLoaded, handlePointClick]);

  useEffect(() => {
    if (!graphRef?.current?.data?.[0]) return;
    graphRef.current.removeAllListeners('plotly_relayouting');
    graphRef.current.removeAllListeners('plotly_relayout');
    graphRef.current.removeAllListeners('plotly_doubleclick');
    graphRef.current.on('plotly_relayouting', (eventData: TPlotRelayoutEvent) =>
      handlePlotZoomed(eventData, 'plotly_relayouting')
    ); // event for zoom via scroll
    graphRef.current.on('plotly_relayout', (eventData: TPlotRelayoutEvent) =>
      handlePlotZoomed(eventData, 'plotly_relayout')
    ); // event for zoom via selection
    graphRef.current.on('plotly_doubleclick', () => {
      graphRef?.current?.removeAllListeners('plotly_click');
      resetRangeToDefault(() => {
        if (isPreprocessingView) return;
        graphRef?.current?.on('plotly_click', handlePointClick);
      });
    });
  }, [graphRef.current?.data, entityLevelGateList, activeGate, selectedGate, plotRange, handlePlotZoomed]);

  const { isHoverInfoOpen, cageData, hoverInfoPosition } = useDatasetChartHover(
    graphRef,
    isPlotLoaded,
    isInteractionsEnabled,
    axesRange,
    origDataRange
  );

  const filteredEntityLevelGateList = useMemo(
    () =>
      entityLevelGateList?.filter((gate: TGate) =>
        gate.properties?.processType ? gate?.laneId === laneId && gate?.scanId === scanId : true
      ) ?? [],
    [entityLevelGateList, scanId, laneId]
  );

  const { newGateModel, clearNewGateData, mouseUpEventRef, mouseDownEventRef, overflowContainer } = useGatesOnPlot({
    gates: fullGateList,
    updateGate,
    graphRef,
    scanId,
    laneId,
    selectedTool,
    entitiesDataByGates,
    handlePlotlySelected,
    plotId,
    chartClassName: DYNAMIC_CHART_SELECTOR,
    onContextMenu,
    isAllPlotDataLoaded,
    axesRange,
    isHoverInfoOpen,
    entityLevelGateList: filteredEntityLevelGateList,
    isObjectEntityEnabled,
    createGate,
    defaultPlotRange: plotRange,
    specificAxesGroupName,
    pageType: EPageWithChartType.singleChart,
  });

  const handleCloseModal = useCallback(() => {
    setIsGateUpdateModalOpened(false);
    setIsModalOpen(false);
    clearNewGateData();
  }, [appDispatch, newGateModel]);

  useEffect(() => {
    d3Select('.gate-element_active').attr('class', 'gate-element');
    appDispatch(gatesActions.setActiveGate(null));
  }, [isModalOpen]);

  useLayoutEffect(() => {
    if (!fullGateList?.length) {
      return;
    }

    fullGateList.forEach(({ id, properties }) => {
      changeGateColor(id, properties?.color ?? '');
      hideGate(id, properties?.isVisible);
    });
  }, [activeGate, fullGateList]);

  useEffect(() => {
    if (
      selectedTool === EModebarTools.ellipse &&
      (!axisScaleHelper.isCircleGateAllowed(xAxisScaleType) || !axisScaleHelper.isCircleGateAllowed(yAxisScaleType))
    ) {
      setSelectedTool(EModebarTools.rectangle);
    }
  }, [xAxisScaleType, yAxisScaleType]);

  useEffect(() => {
    setSelectedTool(EModebarTools.zoom);
  }, [currentChartType]);

  const hoverTemplateData = useMemo<TPointHoverTemplateData>(() => {
    if (!cageData) {
      return null;
    }

    const templateData: Record<keyof TCageInfo, { label: string; value: string | number }> = {
      cageId: {
        label: 'Cage ID',
        value: cageData.cageId,
      },
      globalCageIdMatched: {
        label: 'Global cage ID',
        value: cageData.globalCageIdMatched,
      },
      snapshotId: {
        label: 'Snapshot ID',
        value: cageData.snapshotId,
      },
      x: {
        label: `${xAxisLabel} (X)`,
        value: cageData.x,
      },
      y: {
        label: `${yAxisLabel} (Y)`,
        value: cageData.y,
      },
      ...(highlightDotsByLabel && {
        colorByValue: {
          label: highlightDotsByLabel,
          value: cageData.colorByValue,
        },
      }),
    };

    return templateData;
  }, [xAxisLabel, yAxisLabel, cageData, highlightDotsByLabel]);

  return (
    <>
      <UpdateGateChangeColorModal
        isModalOpen={isGateChangColorModalOpened}
        closeModal={closeGateChangeColorModal}
        updateGate={updateGate}
        gate={activeGate}
        entityLevelGateList={entityLevelGateList}
      />
      <ChartModals
        newGateModel={newGateModel}
        handleCloseModal={handleCloseModal}
        updateGate={updateGate}
        createGate={createGate}
        isGateUpdateModalOpened={isGateUpdateModalOpened}
        isModalOpen={isModalOpen}
        isUpdateGatesInProgress={isUpdateGatesInProgress}
        isCreateGatesInProgress={isCreateGatesInProgress}
        mouseUpEventRef={mouseUpEventRef}
        mouseDownEventRef={mouseDownEventRef}
        CustomCreateGateComponent={CustomCreateGateComponent}
        CustomUpdateGateComponent={CustomUpdateGateComponent}
        specificAxesGroupName={specificAxesGroupName}
      />
      <div
        className={cn('chart')}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
        ref={popoverBoundaryElementRef}
      >
        <Popover
          isOpen={isPopoverOpened}
          setIsOpen={setIsPopoverOpened}
          options={options}
          contentClassName={cn('popover')}
          styles={{
            top: `${popoverCoords.y}px`,
            left: `${popoverCoords.x}px`,
            zIndex: '10',
          }}
          contentId="popover"
        />
        {openedAxisSettingsName && (
          <AxesSettingsPopover
            className={cn('customSelect')}
            isOpen={Boolean(openedAxisSettingsName)}
            onClose={() => setOpenedAxisSettingsName(null)}
            axesMinValue={axesRange[openedAxisSettingsName][0]}
            axesMaxValue={axesRange[openedAxisSettingsName][1]}
            maxLimit={maxChartRange}
            minLimit={minChartRange}
            axisName={openedAxisSettingsName}
            onRangeChange={handleAxisRangeChange}
            isControlBntHidden={!selectedGate}
            handleBtnClick={saveGateCustomRange}
            isLoading={isUpdateGatesInProgress}
          />
        )}
        {!isError && !isChartEntityListError ? (
          (showSkeleton || debouncedShowSkeleton) && <Skeleton className={cn('skeleton')} />
        ) : (
          <NoDataFound
            className={cn('chart__no-data')}
            alignment="center"
            size="normal"
            textData={chartEntityListError}
          />
        )}
        {debouncedIsAllPlotDataLoaded && !hasNoChartDataForSelectedScan && (
          <div className={cn('chart__controls')}>
            <div className={cn('chart__modebar', 'modebar')}>
              <Modebar
                selectedTool={selectedTool}
                handleTooltipClick={handleToolClick}
                disabledAll={isModebarControlsDisabled}
                controlItemClassName={cn('modebar__control-item')}
                controlItemSelectedClassName={cn('modebar__control-item_selected')}
                entityLevelGateList={entityLevelGateList}
              />
              <ChartSettingsButton
                pageType={EPageWithChartType.singleChart}
                scatterPlotAxesOptions={scatterPlotAxesOptions}
                align={fullScreenChartData ? 'center' : 'start'}
              />
              <DownloadChartButton
                graphRef={graphRef}
                fileName={downloadChartFileName}
                xAxisLabel={xAxisLabel}
                yAxisLabel={yAxisLabel}
                overflowContainer={overflowContainer}
              />
              <RangeResetButton onClick={handleRangeResetClick} disabled={isResetDisabled} />
              {isDataInLogScaleDiscarded && <LogScaleWarning pageType={EPageWithChartType.singleChart} />}
              {!isPreprocessingView && (
                <EntitiesSearch
                  entityList={chartEntityList}
                  onEntitySelect={openImageModal}
                  className={cn('modebar__entities-search')}
                  popoverClassName={cn('modebar__entities-popover')}
                />
              )}
            </div>
            <AxesControls
              isCustomGateRangeActive={isCustomGateRangeActive}
              toggleCustomRangeCheckbox={applyCustomGateRange}
              deleteCustomGateRange={deleteCustomGateRange}
              isGateRangeControlsHidden={!gateProperties?.range}
              isRangeChangedByPlotly={isRangeChangedByPlotly}
              saveGateCustomRange={saveGateCustomRange}
            />
          </div>
        )}
        <div className={cn('plot')}>
          {debouncedIsAllPlotDataLoaded && !hasNoChartDataForSelectedScan && !isError && !isChartEntityListError && (
            <>
              <AxisYSelect
                scatterPlotAxesOptions={scatterPlotAxesOptions}
                onlyShowValue={onlyShowAxisValue || isYAxisDisabled}
                primaryOptionList={primaryAxisOptionList}
                customLabel={yAxisLabel}
                className="plot__y-axis"
                specificAxesGroupName={specificAxesGroupName}
              />
              <AxisXSelect
                scatterPlotAxesOptions={scatterPlotAxesOptions}
                onlyShowValue={onlyShowAxisValue}
                primaryOptionList={primaryAxisOptionList}
                className="plot__x-axis"
                specificAxesGroupName={specificAxesGroupName}
              />
              <button
                disabled={isYAxisDisabled}
                type="button"
                onClick={swapChannels}
                className={cn('axis-switch', { 'axis-switch_disabled': isYAxisDisabled })}
                {...addTooltip('Swap Axes')}
                aria-label="Swap Axes"
              >
                <icons.AxisSwitchIcon className={cn('axis-switch__icon')} />
              </button>
            </>
          )}
          <div className={cn('plot__container')}>
            <div ref={graphRef} id={plotId} className={cn('plot-chart')}>
              <PointHoverTemplate isOpen data={hoverTemplateData} templatePosition={hoverInfoPosition} />
              {!isError && !isChartEntityListError && (isEmptyData || hasNoChartDataForSelectedScan) && (
                <NoDataFound
                  className={cn('chart__no-data')}
                  alignment="center"
                  size="small"
                  textData={
                    <span>
                      No data matching the current settings.
                      <br />
                      {hasNoChartDataForSelectedScan && !isPreprocessingView
                        ? 'Please select a different scan or change the axes preset'
                        : 'Please select a different gate or change the axes'}
                    </span>
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(DatasetChart);
