import { useCallback, MutableRefObject, FC, useRef, useState } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';

import { prepareLayout } from '@/components/charts/DownloadChartButton/prepareLayouts';
import PopoverButton from '@/components/common/PopoverButton';

import { TSelectionDivElement } from '@/hooks/gates/types';
import { getFullFileNameWithTime } from '@/hooks/useMatrixScreenShot/helpers';

import styles from './DownloadChartButton.module.scss';

import { getExportingImageSize } from './helpers';
import { handleDownloadPngImage } from './helpers/pngImage';
import { handleDownloadSvgImage } from './helpers/svgImage';

const cn = classnames.bind(styles);

type TDownloadChartButtonProps = {
  graphRef: MutableRefObject<Nullable<IPlotlyHTMLDivElement>>;
  fileName: string;
  xAxisLabel?: string;
  yAxisLabel?: string;
  overflowContainer?: TSelectionDivElement;
  withTime?: boolean;
  className?: string;
  iconClassName?: string;
};

const DownloadChartButton: FC<TDownloadChartButtonProps> = ({
  graphRef,
  fileName,
  xAxisLabel = '',
  yAxisLabel = '',
  overflowContainer,
  withTime = false,
  className,
  iconClassName,
}) => {
  const { Plotly } = window;
  const labelRef = useRef<Nullable<HTMLSpanElement>>(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleDownloadButtonClick = useCallback(
    async (type: 'png' | 'svg') => {
      if (!graphRef.current) {
        return;
      }
      setIsLoading(true);

      const { imageWidth, imageHeight } = getExportingImageSize();

      const layout = prepareLayout(graphRef.current, xAxisLabel, yAxisLabel);

      const plotlyImage = await Plotly.toImage(
        {
          data: graphRef.current.data,
          layout,
        },
        {
          format: type,
          width: imageWidth,
          height: imageHeight,
        }
      );

      const renderPlotHeight = imageHeight - (layout.margin.t + layout.margin.b);
      const renderPlotWidth = imageWidth - (layout.margin.l + layout.margin.r);

      if (type === 'png') {
        handleDownloadPngImage({
          plotlyImage,
          imageWidth,
          imageHeight,
          renderPlotHeight,
          renderPlotWidth,
          fileName: getFullFileNameWithTime(fileName, withTime),
          layout,
          overflowContainer,
          labelElement: labelRef.current,
        });

        setIsLoading(false);
        return;
      }

      handleDownloadSvgImage({
        plotlyImage,
        imageWidth,
        imageHeight,
        renderPlotHeight,
        renderPlotWidth,
        fileName: getFullFileNameWithTime(fileName, withTime),
        layout,
        overflowContainer,
        labelElement: labelRef.current,
      });
      setIsLoading(false);
    },
    [graphRef.current, xAxisLabel, yAxisLabel, overflowContainer]
  );

  const options = [
    {
      id: 'png',
      title: 'PNG',
      onClick: () => handleDownloadButtonClick('png'),
    },
    {
      id: 'svg',
      title: 'SVG',
      onClick: () => handleDownloadButtonClick('svg'),
    },
  ];

  return (
    <>
      <PopoverButton
        options={options}
        icon={
          <icons.DownloadIcon
            className={cn('download-icon', { 'download-icon_downloading': isLoading }, iconClassName)}
          />
        }
        tooltip="Download plot as an image"
        className={className}
      />
      <span ref={labelRef} className={cn('label')} />
    </>
  );
};

export default DownloadChartButton;
