import { MutableRefObject, useCallback, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import {
  calculateDensity,
  getCorrectDimension,
  getCoordinatesByAxesAndGate,
  defineMinHistogramsNbinsX,
  getCoordinatesDataForLogScale,
} from '@/helpers/charts/chartsData';
import { isHistogramChartType } from '@/helpers/charts/chartsType';
import { getLineHistogramCoordinates, isHistogramsChartType } from '@/helpers/charts/lineHistogram';
import { EMarkerSizes, EPageWithChartType, EChartType, EAxesScaleType } from '@/types/charts';
import { isNumber } from '@/helpers';
import { histogramSettingsSelectors } from '@/store/slices/histogramSettings';
import { EAxesGroupName, scatterplotsActions, scatterplotsSelectors } from '@/store/slices/scatterplots';
import { preprocessingSelectors } from '@/store/slices/preprocessing';
import { formatRange, isEmptyAxesRange, isSameRanges } from '@/helpers/charts/ranges';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useDebounce } from '@/hooks/useDebounce';
import { useOrigDataRange } from '@/hooks/charts/useOrigDataRange';

import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import axisScaleHelper from '@/helpers/axisScaleHelper';
import { chartDataSelectors } from '@/store/slices/chartData';
import usePrevious from '../usePrevious';
import useSelectedGateForSpecificDataset from '../preprocessing/useSelectedGateForSpecificDataset';
import usePlotProxy from '../usePlotProxy';

export function usePlotGateAndAxesUpdate(input: {
  cageDataList: TExtendedCageDataList;
  dimensionsMapping?: Record<string, Record<string, string>>;
  graphRef: MutableRefObject<Nullable<IPlotlyHTMLDivElement>>;
  isAllPlotDataLoaded: Nullable<boolean>;
  entityLevelGateList: TGate[] | undefined;
  axesRange?: TPlotAxisRange;
  setAxesRange?: (value: TPlotAxisRange) => void;
  plotRangeName?: string;
  customXAxis?: string;
  customYAxis?: string;
  specificDatasetId?: string;
  scanId?: string;
  laneId?: string;
  pageType: EPageWithChartType;
  chartDataList: TDatasetDetails[];
  specificAxesGroupName?: EAxesGroupName;
}) {
  const {
    cageDataList,
    dimensionsMapping,
    graphRef,
    isAllPlotDataLoaded,
    entityLevelGateList = [],
    axesRange = { x: [0, 0], y: [0, 0] },
    setAxesRange,
    plotRangeName,
    customXAxis,
    customYAxis,
    specificDatasetId,
    scanId,
    laneId,
    pageType,
    chartDataList,
    specificAxesGroupName,
  } = input;
  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');
  const appDispatch = useAppDispatch();
  const fullScreenChartData = useSelector(chartSettingsSelectors.selectFullScreenChartData);

  const chartId = usePlotChartIdContext();

  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));
  const currentChartData = useSelector(chartDataSelectors.selectCurrentChartData) ?? chartDataList[0];
  const selectedGate = useSelectedGateForSpecificDataset(specificDatasetId);
  const storedXAxis = useSelector(scatterplotsSelectors.selectXAxis(specificAxesGroupName));
  const { xAxisScaleType, yAxisScaleType } = useSelector(
    chartSettingsSelectors.selectCurrentScalesTypeForAxes(chartId)
  );

  const xAxis = useMemo(() => customXAxis ?? storedXAxis, [customXAxis, storedXAxis]);
  const storedYAxis = useSelector(scatterplotsSelectors.selectYAxis(specificAxesGroupName));
  const yAxis = useMemo(() => {
    if (isHistogramChartType(currentChartType)) {
      return xAxis; // Histograms don't need yAxis. If y data is invalid histogram won't be drawn and there is no way to change it with UI. That's why xAxis is passed to yAxis
    }
    return customYAxis ?? storedYAxis;
  }, [currentChartType, xAxis, customYAxis, storedYAxis]);

  const customHistogramBinsAmount = useSelector(histogramSettingsSelectors.selectCustomHistogramBinsAmount(chartId));
  const currentHistogramDataGroupType = useSelector(
    histogramSettingsSelectors.selectCurrentHistogramDataGroupType(chartId)
  );
  const kernelBinsAmountMultiplier = useSelector(histogramSettingsSelectors.selectKernelBinsAmountMultiplier(chartId));
  const kernelBandwidthCoefficient = useSelector(histogramSettingsSelectors.selectKernelBandwidthCoefficient(chartId));

  const plotRange = useSelector(chartSettingsSelectors.selectPlotRangeFactory(chartId)(plotRangeName));
  const currentDatasetIndex = useSelector(preprocessingSelectors.selectCurrentDatasetIndex);
  const selectedCustomRange = useSelector(chartSettingsSelectors.selectSelectedCustomRange(chartId));
  const previousRange = usePrevious(plotRange);
  const prevDensityBandWidth = useRef({ x: 0, y: 0 });
  const origDataRange = useOrigDataRange({
    isSingleChart: pageType === EPageWithChartType.singleChart,
    scanId: scanId ?? currentChartData.scanId,
    laneId: laneId ?? currentChartData.laneId,
    xAxis,
    yAxis,
    xAxisScaleType,
    yAxisScaleType,
  });
  const origDataRangeDebounced = useDebounce(origDataRange);
  const lastUsedOrigDataRangeRef = useRef<string>();
  const axes = useMemo(() => ({ xAxis, yAxis }), [xAxis, yAxis]);

  const debouncedAxes = useDebounce(axes, 100);

  const getUpdatedData = useCallback(
    (currentDataConfigList: TPlotData[]) => {
      const newConfigData: {
        x: number[][];
        y: number[][];
        customdata: TEntity[][];
        marker: Partial<TPlotMarker>[];
        nbinsx: Nullable<number>[];
      } = {
        x: [],
        y: [],
        customdata: [],
        marker: [],
        nbinsx: [],
      };

      currentDataConfigList.forEach(({ marker }: TPlotData, index: number) => {
        const datasetScanId =
          pageType === EPageWithChartType.multiHistogram ? currentChartData.scanId : chartDataList?.[index]?.scanId;
        const datasetLaneId =
          pageType === EPageWithChartType.multiHistogram ? currentChartData.laneId : chartDataList?.[index]?.laneId;

        const pointsData = getCoordinatesByAxesAndGate({
          entityList: cageDataList[index].cageList,
          xAxis: getCorrectDimension(xAxis, cageDataList[index].datasetName, dimensionsMapping),
          yAxis: getCorrectDimension(yAxis, cageDataList[index].datasetName, dimensionsMapping),
          gate: selectedGate,
          entityLevelGateList,
          scanId: datasetScanId,
          laneId: datasetLaneId,
        });

        if (
          !isHistogramsChartType(currentChartType) &&
          (xAxisScaleType === EAxesScaleType.log || yAxisScaleType === EAxesScaleType.log)
        ) {
          const { filteredX, filteredY, filteredCustomdata } = getCoordinatesDataForLogScale({
            coordinates: pointsData.coordinates,
            xAxisScaleType,
            yAxisScaleType,
            customdata: pointsData.cagesDataByCoordinates,
          });

          pointsData.coordinates.x = filteredX;
          pointsData.coordinates.y = filteredY;
          pointsData.cagesDataByCoordinates = filteredCustomdata;
        }

        const lineHistogramCoordinates = isHistogramsChartType(currentChartType)
          ? getLineHistogramCoordinates({
              coords: pointsData.coordinates,
              customBinsAmount: customHistogramBinsAmount,
              currentHistogramDataGroupType,
              xAxisScaleType,
              kernelBinsAmountMultiplier,
              kernelBandwidthCoefficient,
            })
          : { x: [], y: [] };

        const coordinatesData: TScatterPlotCoordinates =
          currentChartType === EChartType.lineHistogram ? lineHistogramCoordinates : pointsData.coordinates;

        lastUsedOrigDataRangeRef.current = origDataRange && JSON.stringify(origDataRange);
        newConfigData.x.push(axisScaleHelper.preparePlotData(xAxisScaleType, coordinatesData.x, origDataRange?.x));
        newConfigData.y.push(axisScaleHelper.preparePlotData(yAxisScaleType, coordinatesData.y, origDataRange?.y));

        if (currentChartType === EChartType.histogram && lineHistogramCoordinates.y.length) {
          const nbinsx = defineMinHistogramsNbinsX(lineHistogramCoordinates.y.length);
          newConfigData.nbinsx.push(nbinsx);
        }

        newConfigData.customdata.push(pointsData.cagesDataByCoordinates);
        const newMarker = {
          ...marker,
          size: new Array(pointsData.cagesDataByCoordinates.length).fill(EMarkerSizes.default),
        };

        if (currentChartType === EChartType.dotDensity) {
          const { colorsByDensity, densityBandWidth } = calculateDensity(
            pointsData.coordinates.x,
            pointsData.coordinates.y,
            marker.colorscale
          );

          if (
            prevDensityBandWidth.current.x !== densityBandWidth.x ||
            prevDensityBandWidth.current.y !== densityBandWidth.y
          ) {
            const bandWidthToUpdate = {
              custom: { ...densityBandWidth },
              default: { ...densityBandWidth },
            };

            if (pageType !== EPageWithChartType.matrixView && !fullScreenChartData) {
              appDispatch(scatterplotsActions.setDensityBandWidth(bandWidthToUpdate));
            }
            prevDensityBandWidth.current = densityBandWidth;
          }

          newMarker.color = colorsByDensity;
        }
        newConfigData.marker.push(newMarker);
      });

      return newConfigData;
    },
    [
      xAxis,
      yAxis,
      selectedGate,
      currentChartType,
      cageDataList,
      entityLevelGateList,
      xAxisScaleType,
      kernelBandwidthCoefficient,
      pageType,
      chartDataList,
      currentChartData,
      origDataRange,
    ]
  );

  const updateOnlyRange = () => {
    if (!graphRef.current?.data?.length || !isAllPlotDataLoaded || !cageDataList) {
      return;
    }

    const isUpdateRange = calculateForNeedUpdateRange();

    if (!plotRange || !isUpdateRange) {
      return;
    }

    const updatedLayoutConfig = {
      'xaxis.autorange': false,
      'xaxis.range': [plotRange.xMin, plotRange.xMax],
      'yaxis.autorange': false,
      'yaxis.range': [plotRange.yMin, plotRange.yMax],
    };
    plotlyProxy.forceUpdate(null, updatedLayoutConfig);
  };

  const updateOnlyData = () => {
    if (!graphRef.current?.data?.length || !isAllPlotDataLoaded || !cageDataList) {
      return;
    }

    const updatedDataConfig = getUpdatedData(graphRef.current.data);
    plotlyProxy.forceUpdate(updatedDataConfig);
  };

  const updateDataAndRange = () => {
    if (!graphRef.current?.data?.length || !isAllPlotDataLoaded || !cageDataList) {
      return;
    }
    lastUsedOrigDataRangeRef.current = origDataRange && JSON.stringify(origDataRange);
    const updatedDataConfig = getUpdatedData(graphRef.current.data);

    const isUpdateRange = calculateForNeedUpdateRange();

    const updatedLayoutConfig =
      plotRange && isUpdateRange
        ? {
            'xaxis.autorange': false,
            'xaxis.range': [plotRange.xMin, plotRange.xMax],
            'yaxis.autorange': false,
            'yaxis.range': [plotRange.yMin, plotRange.yMax],
            ...axisScaleHelper.getPlotUpdateLayoutTicksPart(
              xAxisScaleType,
              'xaxis',
              [plotRange.xMin, plotRange.xMax],
              origDataRange?.x
            ),
            ...axisScaleHelper.getPlotUpdateLayoutTicksPart(
              yAxisScaleType,
              'yaxis',
              [plotRange.yMin, plotRange.yMax],
              origDataRange?.y
            ),
          }
        : null;

    plotlyProxy.forceUpdate(updatedDataConfig, updatedLayoutConfig, null);
  };

  const calculateForNeedUpdateRange = useCallback((): boolean => {
    if (!graphRef?.current?.layout) {
      return false;
    }

    if (
      !selectedCustomRange &&
      pageType === EPageWithChartType.multiHistogram &&
      currentChartType === EChartType.histogram
    ) {
      return false;
    }

    const realPrev = previousRange ?? { xMax: 0, xMin: 0, yMax: 0, yMin: 0 };
    const {
      xaxis: { range: xRange = [] },
      yaxis: { range: yRange = [] },
    } = graphRef.current.layout;
    const layoutRange = {
      x: [...xRange],
      y: [...yRange],
    };

    const nPrevRange = formatRange(realPrev);
    const nLayoutRange = formatRange(layoutRange);
    const nAxesRange = formatRange(axesRange);
    const nDefaultRange = formatRange(plotRange);

    const isUpdateUseless = isSameRanges(nDefaultRange, nAxesRange);
    const sameCurrentAndPrevDefaultRange = isSameRanges(nPrevRange, nAxesRange);
    const sameDefaultAndLayoutRange = isSameRanges(nDefaultRange, nLayoutRange);
    const sameAxesAndLayoutRange = isSameRanges(nAxesRange, nLayoutRange);

    const isUpdate =
      !isUpdateUseless &&
      (sameCurrentAndPrevDefaultRange ||
        isEmptyAxesRange(nPrevRange) ||
        isEmptyAxesRange(nAxesRange) ||
        !sameAxesAndLayoutRange ||
        !sameDefaultAndLayoutRange);

    if (plotRange && isUpdate) {
      setAxesRange?.({ x: [plotRange.xMin, plotRange.xMax], y: [plotRange.yMin, plotRange.yMax] });
    }

    return isUpdate;
  }, [plotRange, axesRange]);

  useEffect(() => {
    if (!isAllPlotDataLoaded) return;

    updateOnlyRange();
  }, [graphRef?.current, plotRange, selectedCustomRange, isAllPlotDataLoaded]);

  useEffect(() => {
    if (!isAllPlotDataLoaded || !isNumber(currentDatasetIndex)) return;
    updateOnlyData();
  }, [currentDatasetIndex]);

  useEffect(() => {
    if (!isAllPlotDataLoaded) return;

    if (selectedGate) {
      updateOnlyData();
    } else {
      updateDataAndRange();
    }
  }, [graphRef?.current, selectedGate]);

  useEffect(() => {
    if (!isAllPlotDataLoaded) return;
    updateDataAndRange();
  }, [graphRef.current, debouncedAxes.yAxis, debouncedAxes.xAxis, isAllPlotDataLoaded]);

  useEffect(() => {
    if (!isAllPlotDataLoaded || !origDataRange || lastUsedOrigDataRangeRef.current === JSON.stringify(origDataRange)) {
      return;
    }
    updateDataAndRange();
  }, [origDataRangeDebounced]);

  useEffect(() => {
    if (!isAllPlotDataLoaded || !plotRange) {
      return;
    }

    const updatedLayoutConfig = {
      ...axisScaleHelper.getPlotUpdateLayoutTicksPart(
        xAxisScaleType,
        'xaxis',
        [plotRange.xMin, plotRange.xMax],
        origDataRange?.x
      ),
      ...axisScaleHelper.getPlotUpdateLayoutTicksPart(
        yAxisScaleType,
        'yaxis',
        [plotRange.yMin, plotRange.yMax],
        origDataRange?.y
      ),
    };

    if (Object.keys(updatedLayoutConfig).length === 0) return;

    plotlyProxy.forceUpdate(null, updatedLayoutConfig);
  }, [graphRef?.current, origDataRange, plotRange, isAllPlotDataLoaded]);
}

export default usePlotGateAndAxesUpdate;
