import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import Button from '@/components/common/Button';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useSettingsNotification } from '@/hooks/plotSettings/useSettingsNotification';

import { chartSettingsSelectors, chartSettingsActions } from '@/store/slices/chartSettings';
import { datasetsSelectors } from '@/store/slices/datasets';

import { EAxesScaleType, EPageWithChartType } from '@/types/charts';

import styles from './LogScaleWarning.module.scss';

const cn = classnames.bind(styles);

const LogScaleWarning: FC<{ pageType: EPageWithChartType }> = ({ pageType }) => {
  const chartId = usePlotChartIdContext();
  const appDispatch = useAppDispatch();

  const { xAxisScaleType, yAxisScaleType } = useSelector(
    chartSettingsSelectors.selectCurrentScalesTypeForAxes(chartId)
  );
  const isPreprocessingView = useSelector(datasetsSelectors.selectIsPreprocessingView);
  const allGlobalChartSettings = useSelector(chartSettingsSelectors.selectAllGlobalChartSettings);
  const { isSettingsChangingConfirmed } = useSettingsNotification({ pageType });

  const switchToArcSinScale = useCallback(async () => {
    const specificSettings = Object.values(allGlobalChartSettings.specificDatasetOptionMap);

    const hasCustomSettingsLogScale = specificSettings.some((settings) => {
      const scaleTypes = settings.axesScaleTypes;
      return scaleTypes && (scaleTypes.x === EAxesScaleType.log || scaleTypes.y === EAxesScaleType.log);
    });

    const hasCustomSettingsArcSinhScale = specificSettings.some((settings) => {
      const scaleTypes = settings.axesScaleTypes;
      return scaleTypes && (scaleTypes.x === EAxesScaleType.arcSinh || scaleTypes.y === EAxesScaleType.arcSinh);
    });

    if (hasCustomSettingsLogScale || hasCustomSettingsArcSinhScale) {
      const isConfirmed = await isSettingsChangingConfirmed();

      if (!isConfirmed) {
        return;
      }
    }

    appDispatch(
      chartSettingsActions.setAxesScaleTypes({
        x: xAxisScaleType === EAxesScaleType.log ? EAxesScaleType.arcSinh : xAxisScaleType,
        y: yAxisScaleType === EAxesScaleType.log ? EAxesScaleType.arcSinh : yAxisScaleType,
      })
    );
  }, [xAxisScaleType, yAxisScaleType, allGlobalChartSettings, pageType]);

  return (
    <div className={cn('warning_block')}>
      <span>*</span>
      <div>
        The Log scale cannot display values less than or equal to zero.
        {!isPreprocessingView && (
          <Button className={cn('warning_block__button')} isEmptyStyles isFitContent onClick={switchToArcSinScale}>
            Switch to ArcSinh
          </Button>
        )}
      </div>
    </div>
  );
};

export default LogScaleWarning;
