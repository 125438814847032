import { JobDefinition } from '@/graphql/API';
import { TDetailsFields } from '../../common/DetailsPopover';

export const prepareJodDefinitionDetailsFields = (jobDefinition?: JobDefinition) => {
  if (!jobDefinition) return null;

  const definitionDetailsFields: TDetailsFields[] = [
    {
      label: 'Name',
      value: jobDefinition?.name,
      isFullWidth: true,
    },
    {
      label: 'Memory',
      value: jobDefinition?.revisions?.[0]?.container.memory,
    },
    {
      label: 'CPU',
      value: jobDefinition?.revisions?.[0]?.container.cpu,
    },
    {
      label: 'Image',
      value: jobDefinition?.revisions?.[0]?.container.image,
    },
    {
      label: 'Environments',
      value: jobDefinition?.revisions?.[0]?.container.environment,
      isFullWidth: true,
    },
  ];

  return definitionDetailsFields;
};
