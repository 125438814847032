import { FC, useCallback, useMemo } from 'react';
import classnames from 'classnames/bind';
import { useSelector } from 'react-redux';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useSettingsNotification } from '@/hooks/plotSettings/useSettingsNotification';

import { histogramSettingsActions, histogramSettingsSelectors } from '@/store/slices/histogramSettings';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';

import SwitchInput from '@/components/common/SwitchInput';
import CheckboxInput from '@/components/common/CheckboxInput';
import Select from '@/components/common/Select';
import { isBasicOption } from '@/components/common/Select/helpers';
import icons from '@/components/common/icons';
import NumberInput from '@/components/common/NumberInput';
import Range from '@/components/common/Range';
import { EHistogramDataGroupTypes, EPageWithChartType, EChartType } from '@/types/charts';
import { themeOptions } from '@/types/theme';

import {
  DEFAULT_KERNEL_BINS_AMOUNT,
  groupOptionsByHistogramType,
  linksToRuleDetails,
  MIN_POSITIVE_KERNEL_BANDWIDTH_COEFFICIENT,
} from '../constants';
import styles from '../SettingsPopover.module.scss';

const cn = classnames.bind(styles);
const MAX_BINS_AMOUNT = 2000;
const MAX_BANDWIDTH_COEFFICIENT = 4;
const MIN_BANDWIDTH_COEFFICIENT = MIN_POSITIVE_KERNEL_BANDWIDTH_COEFFICIENT;
const COEFFICIENT_STEP = 0.1;
const MAX_KERNEL_BINS_AMOUNT_MULTIPLIER = 4;
const MIN_KERNEL_BINS_AMOUNT_MULTIPLIER = 14;
const KERNEL_BINS_AMOUNT_MULTIPLIER_STEP = 1;

type THistogramsSettingsProps = {
  pageType: EPageWithChartType;
};

const HistogramsSettings: FC<THistogramsSettingsProps> = ({ pageType }) => {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));
  const fullScreenChartData = useSelector(chartSettingsSelectors.selectFullScreenChartData);

  const isChartFillEnabled = useSelector(histogramSettingsSelectors.selectIsChartFillEnabled(chartId));
  const isStackedAndFilledEnabled = useSelector(histogramSettingsSelectors.selectIsStackedAndFilledEnabled);
  const isStackedChartsChecked = useSelector(histogramSettingsSelectors.selectIsStackedChartsChecked);
  const customHistogramBinsAmount = useSelector(histogramSettingsSelectors.selectCustomHistogramBinsAmount(chartId));
  const currentHistogramDataGroupType = useSelector(
    histogramSettingsSelectors.selectCurrentHistogramDataGroupType(chartId)
  );
  const kernelBinsAmountMultiplier = useSelector(histogramSettingsSelectors.selectKernelBinsAmountMultiplier(chartId));
  const kernelBandwidthCoefficient = useSelector(histogramSettingsSelectors.selectKernelBandwidthCoefficient(chartId));

  const { isSettingsChangingConfirmed } = useSettingsNotification({ pageType });

  const onCurrentHistogramDataGroupType = useCallback(async (newType: EHistogramDataGroupTypes) => {
    const isConfirmed = await isSettingsChangingConfirmed();

    if (!isConfirmed) {
      return;
    }

    appDispatch(
      histogramSettingsActions.setCurrentHistogramDataGroupType({
        type: newType,
        fullScreenChartDataId: fullScreenChartData?.id,
      })
    );
  }, []);

  const onCustomHistogramBinsAmountChange = useCallback(
    (value: number) => {
      const newValue = value > MAX_BINS_AMOUNT ? MAX_BINS_AMOUNT : value;
      appDispatch(
        histogramSettingsActions.setCustomHistogramBinsAmount({
          bins: newValue,
          fullScreenChartDataId: fullScreenChartData?.id,
        })
      );
    },
    [fullScreenChartData]
  );

  const toggleIsChartFillEnabled = useCallback(async () => {
    const isConfirmed = await isSettingsChangingConfirmed();

    if (!isConfirmed) {
      return;
    }

    appDispatch(histogramSettingsActions.toggleIsChartFillEnabled({ fullScreenChartDataId: fullScreenChartData?.id }));
  }, []);

  const toggleIsStackedAndFilledEnabled = useCallback(() => {
    appDispatch(histogramSettingsActions.toggleIsStackedAndFilledEnabled());
  }, []);

  const toggleStackedChartsChecked = useCallback(() => {
    appDispatch(histogramSettingsActions.toggleIsStackedChartsChecked());
  }, []);

  const setKernelBandwidthCoefficient = useCallback((coefficient: number) => {
    appDispatch(
      histogramSettingsActions.setKernelBandwidthCoefficient({
        coefficient,
        fullScreenChartDataId: fullScreenChartData?.id,
      })
    );
  }, []);

  const onBandwidthChange = (value: number | number[]) => {
    setKernelBandwidthCoefficient(+value);
  };

  const onBinsAmountMultiplierChange = (value: number | number[]) => {
    appDispatch(
      histogramSettingsActions.setKernelBinsAmountMultiplier({
        bins: +value,
        fullScreenChartDataId: fullScreenChartData?.id,
      })
    );
  };

  const isLineHistogramSettingsVisible = useMemo(
    () => currentChartType === EChartType.lineHistogram,
    [currentChartType]
  );

  const isShowStackedSettingVisible = useMemo(() => pageType === EPageWithChartType.multiHistogram, [pageType]);

  const isStackedTypeSettingVisible = useMemo(
    () => isShowStackedSettingVisible && isStackedChartsChecked,
    [isShowStackedSettingVisible, isStackedChartsChecked]
  );

  const isKernelEstimationSettingsVisible = useMemo(
    () => isLineHistogramSettingsVisible && currentHistogramDataGroupType === EHistogramDataGroupTypes.kernelEstimation,
    [currentHistogramDataGroupType, isLineHistogramSettingsVisible]
  );

  const formatOptionLabel = (option: TOption) => (
    <div style={{ display: 'flex', gap: 10 }}>
      <div>{option.label}</div>
      {isBasicOption(option) && (
        <a rel="noreferrer" target="_blank" href={linksToRuleDetails[option.value]} aria-label="link to rule details">
          <icons.LinkIcon />
        </a>
      )}
    </div>
  );

  return (
    <>
      {isShowStackedSettingVisible && (
        <div className={cn('section__content')}>
          <CheckboxInput
            checked={isStackedChartsChecked}
            onChange={toggleStackedChartsChecked}
            className={cn('checkbox')}
            theme="light"
            label="Show stacked charts"
            isLabelAtLeft
          />
        </div>
      )}
      {isStackedTypeSettingVisible && (
        <div className={cn('section__content')}>
          <div className={cn('section__head', 'section__head_small')}>
            <span>Stack type</span>
          </div>
          <SwitchInput
            checked={isStackedAndFilledEnabled}
            onChange={toggleIsStackedAndFilledEnabled}
            offLabel="Horizontal"
            onLabel="Vertical"
            type="small"
          />
        </div>
      )}
      {isLineHistogramSettingsVisible && (
        <div className={cn('section__content')}>
          <CheckboxInput
            checked={isChartFillEnabled}
            onChange={toggleIsChartFillEnabled}
            className={cn('checkbox')}
            theme="light"
            label="Fill charts"
            isLabelAtLeft
          />
        </div>
      )}
      <div className={cn('section', 'section_multiple')}>
        {currentChartType === EChartType.histogram && (
          <div className={cn('section__content')}>
            <div className={cn('section__head', 'section__head_small')}>
              <span>Group by</span>
            </div>
            <Select
              value={currentHistogramDataGroupType}
              placeholder=""
              className={cn('section__select', 'plot-type-select')}
              controlClassName={cn('section__select')}
              theme={themeOptions.light}
              defaultValue={currentHistogramDataGroupType}
              onChange={onCurrentHistogramDataGroupType}
              options={groupOptionsByHistogramType[currentChartType]}
              formatOptionLabel={formatOptionLabel}
            />
          </div>
        )}
        {currentHistogramDataGroupType === EHistogramDataGroupTypes.custom && (
          <div className={cn('section__inputs')}>
            <NumberInput
              label="Bins"
              value={customHistogramBinsAmount}
              onChange={onCustomHistogramBinsAmountChange}
              className={cn('section__input')}
              min={1}
            />
          </div>
        )}
        {isKernelEstimationSettingsVisible && (
          <>
            <Range
              label="Histogram Smoothing"
              onChange={onBandwidthChange}
              min={MIN_BANDWIDTH_COEFFICIENT}
              max={MAX_BANDWIDTH_COEFFICIENT}
              step={COEFFICIENT_STEP}
              value={kernelBandwidthCoefficient}
              infoBlockClassName={cn('sidebar__range-info')}
              className={cn('sidebar__range')}
              rangeHeight={4}
              fixedValue={1}
            />
            <div className={cn('section__content')}>
              <Range
                label="Bins Amount"
                onChange={onBinsAmountMultiplierChange}
                min={MAX_KERNEL_BINS_AMOUNT_MULTIPLIER}
                max={MIN_KERNEL_BINS_AMOUNT_MULTIPLIER}
                step={KERNEL_BINS_AMOUNT_MULTIPLIER_STEP}
                value={kernelBinsAmountMultiplier}
                infoBlockClassName={cn('sidebar__range-info')}
                className={cn('sidebar__range')}
                rangeHeight={4}
                fixedValue={0}
                sliderValue={DEFAULT_KERNEL_BINS_AMOUNT ** kernelBinsAmountMultiplier}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default HistogramsSettings;
