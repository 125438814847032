import { density2d } from 'fast-kde';

import { findParentGate, isPointInGate } from '@/helpers/gates';
import { getCoordinatesByAxes } from '@/helpers/cages';
import { getLineHistogramCoordinates, isHistogramsChartType } from '@/helpers/charts/lineHistogram';
import {
  COLORSCALE_INTERPOLATOR,
  DEFAULT_D3_COLORSCALES,
  FULL_D3_COLORSCALES,
  formatColorscalesForContourCharts,
} from '@/helpers/charts/colorscales';
import axisScaleHelper from '@/helpers/axisScaleHelper';
import { TInitialHistogramSettingsState } from '@/store/slices/histogramSettings';
import { EAxesScaleType, EMarkerSizes, EPageWithChartType, EChartType, TColorscale } from '@/types/charts';
import { isNumber } from '@/helpers';

import { dataConfigByTypes, HISTOGRAM_DATA_CONFIG } from '@/pages/Dataset/constants';
import { EUnselectedOption } from '@/types/settings';

type THistogramChartSettings = Omit<TInitialHistogramSettingsState, 'kernelBandwidthCoefficient'>;

type TFilterCoordinatesDataByGateResponse = {
  coordinates: TScatterPlotCoordinates;
  cagesDataByCoordinates: TEntity[];
};

type TFilterCoordinatesDataByGatePayload = {
  coordinates: TScatterPlotCoordinates;
  xAxis: string;
  yAxis: string;
  gate?: Nullable<TGate>;
  gates?: Nullable<TGate[]>;
  parentGate?: Nullable<TGate>;
  cagesDataByCoordinates?: TEntity[];
  scanId?: string;
  laneId?: string;
};

type TGetFilteredPointsData = {
  xAxis: string;
  yAxis: string;
  gate: TGate;
  gates: TGate[];
  parentEntityList: TEntity[];
  scanId?: string;
  laneId?: string;
  parentGate?: Nullable<TGate>;
};

type TPrepareDataConfigPayload = Omit<
  THistogramChartSettings,
  'isStackedChartsChecked' | 'specificDatasetOptionMap'
> & {
  coordinates: Record<'x' | 'y', number[]>;
  xAxisScaleType: EAxesScaleType;
  yAxisScaleType: EAxesScaleType;
  currentChartType: EChartType;
  currentColorScale: TColorscale;
  customdata?: TEntity[];
  bandWidth?: number[];
  dataName?: string;
  datasetName?: string;
  dataIndex?: number;
  kernelBinsAmountMultiplier: number;
  pageType?: EPageWithChartType;
  kernelBandwidthCoefficient?: number;
  selectedGate: Nullable<TGate>;
  origDataRange?: TPlotAxisRange;
  highlightDotsBy?: string;
  chartEntityList?: TEntity[];
};

type TPrepareConfigPayload = Omit<THistogramChartSettings, 'isStackedChartsChecked' | 'specificDatasetOptionMap'> & {
  dataConfig: TPlotData;
  currentColorScale: TColorscale;
  bandWidth?: number[];
  kernelBandwidthCoefficient?: number;
  xAxisScaleType: EAxesScaleType;
  kernelBinsAmountMultiplier: number;
  pageType?: EPageWithChartType;
  highlightDotsBy?: string;
  chartEntityList?: TEntity[];
};

type TPrepareChartDataConfigList = Omit<THistogramChartSettings, 'specificDatasetOptionMap'> & {
  cageDataList: TExtendedCageDataList;
  xAxis: string;
  yAxis: string;
  xAxisScaleType: EAxesScaleType;
  yAxisScaleType: EAxesScaleType;
  dimensionsMapping?: Record<string, Record<string, string>>;
  currentChartType: EChartType;
  currentColorScale: TColorscale;
  kernelBinsAmountMultiplier: number;
  pageType?: EPageWithChartType;
  kernelBandwidthCoefficient?: number;
  selectedGate?: Nullable<TGate>;
  entityLevelGateList?: TGate[];
  origDataRange?: TPlotAxisRange;
  saveNewDensityBandwidth?: (bandwidth: { x: number; y: number }) => void;
  highlightDotsBy?: string;
};

const MIN_HISTOGRAM_NBINSX = 2;

export const defineMinHistogramsNbinsX = (value: number) => {
  if (value < MIN_HISTOGRAM_NBINSX) return MIN_HISTOGRAM_NBINSX;

  return value;
};

export const prepareGradient = (colorList: string[][]) => {
  const gradientString = colorList.reduce((gradientStr, colorData, index) => {
    if (index === 0) return gradientStr;

    const pct = `${Number(colorData[0]) * 100}%`;
    const color = colorData[1];
    const str = `${gradientStr}, ${color} ${pct}`;

    return str;
  }, '');

  return `linear-gradient(to right ${gradientString})`;
};

export const getFilteredPointsData = ({
  xAxis,
  yAxis,
  gate,
  gates,
  parentEntityList,
  scanId,
  laneId,
  parentGate,
}: TGetFilteredPointsData): Nullable<TFilterCoordinatesDataByGateResponse> => {
  for (let i = 0; i < gates.length; i++) {
    const gateItem = gates[i];
    if (gateItem.shape.type === 'polar') {
      const data = getFilteredPointsData({
        xAxis,
        yAxis,
        gate,
        gates: gateItem.gateNodes,
        parentEntityList,
        scanId,
        laneId,
        parentGate,
      });

      if (data) return data;
    }

    const gateEntityList = parentEntityList.filter((entity: TEntity) =>
      isPointInGate({
        x: entity[gateItem.xDimension],
        y: entity[gateItem.yDimension],
        gate: gateItem,
        parentGate,
        scanId,
        laneId,
      })
    );

    if (gateItem.id === gate.id) {
      const data = getCoordinatesByAxes({
        entityList: gateEntityList,
        xAxis,
        yAxis,
      });
      return data;
    }

    if (gateItem?.gateNodes) {
      const data = getFilteredPointsData({
        xAxis,
        yAxis,
        gate,
        gates: gateItem?.gateNodes,
        parentEntityList: gateEntityList,
        scanId,
        laneId,
        parentGate,
      });

      if (data) return data;
    }
  }

  return null;
};

export const filterCoordinatesDataByGate = ({
  scanId,
  laneId,
  coordinates,
  xAxis,
  yAxis,
  gate = null,
  gates = null,
  cagesDataByCoordinates = [],
  parentGate,
}: TFilterCoordinatesDataByGatePayload): TFilterCoordinatesDataByGateResponse => {
  if (!gate || !gates) {
    return {
      coordinates,
      cagesDataByCoordinates,
    };
  }

  const data = getFilteredPointsData({
    xAxis,
    yAxis,
    gate,
    gates,
    parentEntityList: cagesDataByCoordinates,
    scanId,
    laneId,
    parentGate,
  });

  return {
    coordinates: data?.coordinates ?? { x: [], y: [] },
    cagesDataByCoordinates: data?.cagesDataByCoordinates ?? [],
  };
};

const componentToHex = (component: number) => {
  const hex = component.toString(16);
  return hex.length === 1 ? `0${hex}` : hex;
};

const rgbToHex = (r: number, g: number, b: number) => `#${componentToHex(r)}${componentToHex(g)}${componentToHex(b)}`;

export const calculateDensity = (
  xCoords: number[],
  yCoords: number[],
  colorscale: TColorscale,
  bandWidth?: number[]
) => {
  const matrixSize = 1024;
  const { xMin, xMax, yMin, yMax } = axisScaleHelper.getLinearRangeWithoutGaps(xCoords, yCoords);
  const xWidth = xMax - xMin;
  const yWidth = yMax - yMin;
  const xScaleDelta = xWidth > 0 ? matrixSize / xWidth : 0;
  const yScaleDelta = yWidth > 0 ? matrixSize / yWidth : 0;
  const shiftedCoords = xCoords.map((x, i) => [x - xMin, yCoords[i] - yMin]);
  const xExtentMax = xWidth === 0 ? 0.001 : xWidth;
  const yExtentMax = yWidth === 0 ? 0.001 : yWidth;
  const isEmptyBandWidth = !bandWidth || (bandWidth[0] === 0 && bandWidth[1] === 0);

  const densityEstimator = density2d(shiftedCoords, {
    ...(!isEmptyBandWidth && {
      bandwidth: bandWidth,
    }),
    extent: [
      [0, xExtentMax],
      [0, yExtentMax],
    ],
    bins: [matrixSize, matrixSize],
  });

  const currentBandWidth: number[] = densityEstimator.bandwidth();
  const densityBandWidth = {
    x: Number(currentBandWidth[0].toFixed(3)),
    y: Number(currentBandWidth[1].toFixed(3)),
  };

  const canvas = densityEstimator.heatmap({ color: COLORSCALE_INTERPOLATOR[colorscale] });
  const context = canvas.getContext('2d', { willReadFrequently: true }) as CanvasRenderingContext2D;
  const colorsByDensity = shiftedCoords.map(([x, y]) => {
    const xPoint = x * xScaleDelta;
    const yPoint = matrixSize - y * yScaleDelta;
    // now data for chart density gets from the generated canvas hitmap
    // TODO: get dencity from the densityEstimator.grid() matrix
    const pixel = context.getImageData(Math.floor(xPoint), Math.floor(yPoint), 1, 1).data;

    return rgbToHex(pixel[0], pixel[1], pixel[2]);
  });

  return { colorsByDensity, densityBandWidth };
};

export const getRandomInt = (max: number) => Math.floor(Math.random() * max);

const correctDataColors = (
  dataConfig: TPlotData,
  currentChartType: EChartType,
  dataName: string,
  dataIndex: number,
  colorScale: TColorscale,
  isChartFillEnabled: boolean
) => {
  const colorsAmount = FULL_D3_COLORSCALES[colorScale].length;
  const lineColor: string =
    dataIndex < colorsAmount
      ? FULL_D3_COLORSCALES[colorScale][dataIndex][1]
      : FULL_D3_COLORSCALES[colorScale][getRandomInt(colorsAmount)][1];

  if (currentChartType === EChartType.lineHistogram) {
    dataConfig.line = { ...dataConfig.line, color: lineColor };
    dataConfig.fill = isChartFillEnabled ? 'tozeroy' : null;
    dataConfig.fillcolor = isChartFillEnabled ? 'default' : 'rgba(0,0,0,0)';
  }

  if (currentChartType === EChartType.histogram) {
    dataConfig.marker = {
      ...dataConfig.marker,
      color: lineColor,
      line: { ...dataConfig.marker.line, color: lineColor },
    };
  }

  dataConfig.showlegend = true;
  dataConfig.name = dataName;
};

export const getColorValuesFromEntityList = (
  chartEntityList?: TEntity[],
  highlightDotsBy: string = EUnselectedOption.value
) => {
  if (highlightDotsBy === EUnselectedOption.value || !chartEntityList) return '#1f1f1f';

  const colorsByEntity: number[] = chartEntityList.map((entityItem) => entityItem?.[highlightDotsBy] ?? 0);

  return colorsByEntity;
};

const getConfigForDot = ({
  dataConfig,
  highlightDotsBy,
  chartEntityList,
  currentColorScale,
}: TPrepareConfigPayload) => {
  const { marker, x } = dataConfig;

  const colorsByEntityData = getColorValuesFromEntityList(chartEntityList, highlightDotsBy);
  const updatedDataConfig = {
    ...dataConfig,
    marker: {
      ...marker,
      size: new Array(x.length).fill(EMarkerSizes.default),
      color: colorsByEntityData,
      colorscale: DEFAULT_D3_COLORSCALES[currentColorScale],
    },
  };

  return {
    dataConfig: { ...updatedDataConfig },
    densityBandWidth: null,
  };
};

const getConfigForDotDensity = ({ dataConfig, currentColorScale, bandWidth }: TPrepareConfigPayload) => {
  const { x, y } = dataConfig;
  const { colorsByDensity, densityBandWidth: newBandWidth } = calculateDensity(x, y, currentColorScale, bandWidth);

  const updatedDataConfig = {
    ...dataConfig,
    marker: {
      size: new Array(x.length).fill(EMarkerSizes.default),
      symbol: 'circle',
      colorscale: currentColorScale,
      color: colorsByDensity,
      line: {
        width: 0,
      },
    },
  };

  return {
    dataConfig: { ...updatedDataConfig },
    densityBandWidth: newBandWidth,
  };
};

const getConfigForLineHistogram = ({
  dataConfig,
  isStackedAndFilledEnabled,
  isChartFillEnabled,
  currentHistogramDataGroupType,
  customHistogramBinsAmount,
  xAxisScaleType,
  kernelBinsAmountMultiplier,
  kernelBandwidthCoefficient,
}: TPrepareConfigPayload) => {
  const { x, y } = dataConfig;
  const newCoordinates = getLineHistogramCoordinates({
    coords: { x, y },
    customBinsAmount: customHistogramBinsAmount,
    currentHistogramDataGroupType,
    xAxisScaleType,
    kernelBinsAmountMultiplier,
    kernelBandwidthCoefficient,
  });
  const updatedDataConfig = {
    ...dataConfig,
    x: newCoordinates.x,
    y: newCoordinates.y,
    fill: isChartFillEnabled ? 'tozeroy' : null,
    fillcolor: isChartFillEnabled ? 'default' : 'rgba(0,0,0,0)',
    ...(isStackedAndFilledEnabled && { stackgroup: 'one' }),
  };

  return {
    dataConfig: { ...updatedDataConfig },
    densityBandWidth: null,
  };
};

const getConfigForHistogram = ({
  dataConfig,
  currentHistogramDataGroupType,
  customHistogramBinsAmount,
  xAxisScaleType,
  kernelBinsAmountMultiplier,
}: TPrepareConfigPayload) => {
  const { y } = getLineHistogramCoordinates({
    coords: { x: [...dataConfig.x], y: [...dataConfig.y] },
    customBinsAmount: customHistogramBinsAmount,
    currentHistogramDataGroupType,
    xAxisScaleType,
    kernelBinsAmountMultiplier,
  });

  return {
    dataConfig: {
      ...dataConfig,
      ...HISTOGRAM_DATA_CONFIG(),
      nbinsx: defineMinHistogramsNbinsX(y.length),
    },
    densityBandWidth: null,
  };
};

const getConfigForContourHistograms = ({ dataConfig, currentColorScale }: TPrepareConfigPayload) => {
  const updatedDataConfig = {
    ...dataConfig,
    colorscale: formatColorscalesForContourCharts(FULL_D3_COLORSCALES[currentColorScale]),
  };

  return {
    dataConfig: { ...updatedDataConfig },
    densityBandWidth: null,
  };
};

export const getCoordinatesDataForLogScale = ({
  xAxisScaleType,
  yAxisScaleType,
  coordinates,
  customdata,
}: {
  xAxisScaleType: EAxesScaleType;
  yAxisScaleType: EAxesScaleType;
  coordinates: Record<'x' | 'y', number[]>;
  customdata?: TEntity[];
}) => {
  const filteredX: number[] = [];
  const filteredY: number[] = [];
  const filteredCustomdata: TEntity[] = [];
  coordinates.x.forEach((val, index) => {
    const isNegativeXInLogScale = xAxisScaleType === EAxesScaleType.log && val < 0;
    const isNegativeYInLogScale = yAxisScaleType === EAxesScaleType.log && coordinates.y[index] < 0;

    if (isNegativeYInLogScale || isNegativeXInLogScale) return;

    filteredX.push(val);
    filteredY.push(coordinates.y[index]);

    if (customdata?.[index]) {
      filteredCustomdata.push(customdata?.[index]);
    }
  });

  return {
    filteredX,
    filteredY,
    filteredCustomdata,
  };
};

export const prepareDataConfig = ({
  coordinates,
  xAxisScaleType,
  yAxisScaleType,
  currentChartType,
  currentColorScale,
  customdata,
  bandWidth,
  dataName,
  datasetName,
  dataIndex = 0,
  isChartFillEnabled,
  isStackedAndFilledEnabled,
  currentHistogramDataGroupType,
  customHistogramBinsAmount,
  kernelBinsAmountMultiplier,
  pageType,
  kernelBandwidthCoefficient,
  selectedGate,
  origDataRange,
  highlightDotsBy,
  chartEntityList,
}: TPrepareDataConfigPayload) => {
  const defaultConfig = {
    ...dataConfigByTypes[currentChartType],
    x: coordinates.x,
    y: coordinates.y,
    customdata, // needs to be passed to every type because after that the type might be changed back to scattergl or dotDensity
  };

  if (
    !isHistogramsChartType(currentChartType) &&
    (xAxisScaleType === EAxesScaleType.log || yAxisScaleType === EAxesScaleType.log)
  ) {
    const { filteredX, filteredY, filteredCustomdata } = getCoordinatesDataForLogScale({
      coordinates,
      xAxisScaleType,
      yAxisScaleType,
      customdata,
    });

    defaultConfig.x = filteredX;
    defaultConfig.y = filteredY;
    defaultConfig.customdata = filteredCustomdata;
  }

  const payload = {
    dataConfig: defaultConfig,
    currentColorScale,
    bandWidth,
    isStackedAndFilledEnabled,
    isChartFillEnabled,
    currentHistogramDataGroupType,
    customHistogramBinsAmount,
    xAxisScaleType,
    kernelBinsAmountMultiplier,
    pageType,
    kernelBandwidthCoefficient,
    selectedGate,
    highlightDotsBy,
    chartEntityList,
  };

  const configHandlers = {
    [EChartType.dot]: getConfigForDot,
    [EChartType.dotDensity]: getConfigForDotDensity,
    [EChartType.lineHistogram]: getConfigForLineHistogram,
    [EChartType.histogram2dcontour]: getConfigForContourHistograms,
    [EChartType.histogram2d]: getConfigForContourHistograms,
    [EChartType.histogram]: getConfigForHistogram,
    [EChartType.heatmap]: () => ({
      dataConfig: { type: EChartType.heatmap, colorscale: FULL_D3_COLORSCALES[currentColorScale] },
      densityBandWidth: null,
    }),
    [EChartType.violin]: () => ({
      dataConfig: { type: EChartType.violin },
      densityBandWidth: null,
    }),
    [EChartType.knee]: () => ({
      dataConfig: { type: EChartType.knee },
      densityBandWidth: null,
    }),
  };

  const { dataConfig, densityBandWidth } = configHandlers[currentChartType](payload);

  if (dataName) {
    // There is multi data
    correctDataColors(dataConfig, currentChartType, dataName, dataIndex, currentColorScale, isChartFillEnabled);
  }

  if (datasetName) {
    dataConfig.legendgrouptitle = datasetName;
  }

  if (Array.isArray(dataConfig.x)) {
    dataConfig.x = axisScaleHelper.preparePlotData(xAxisScaleType, dataConfig.x, origDataRange?.x);
  }

  if (Array.isArray(dataConfig.x)) {
    dataConfig.y = axisScaleHelper.preparePlotData(yAxisScaleType, dataConfig.y, origDataRange?.y);
  }

  return {
    dataConfig,
    densityBandWidth,
  };
};

export const groupDataByAxesValue = (data: TEntity[], xAxis: string) => {
  const groupedData: Record<number, number | undefined> = {};
  data.forEach((item: TEntity) => {
    if (item?.globalCageIdMatched) {
      groupedData[item.globalCageIdMatched] = item[xAxis];
    }
  });

  return groupedData;
};

export const getCorrectDimension = (
  dimension: string,
  datasetName?: string,
  dimensionsMapping?: Record<string, Record<string, string>>
) => (datasetName && dimensionsMapping?.[dimension]?.[datasetName]) ?? dimension;

export const getDataConfigList = ({
  cageDataList,
  xAxis,
  yAxis,
  xAxisScaleType,
  yAxisScaleType,
  dimensionsMapping,
  currentChartType,
  currentColorScale,
  customHistogramBinsAmount,
  isChartFillEnabled,
  isStackedChartsChecked,
  isStackedAndFilledEnabled,
  currentHistogramDataGroupType,
  kernelBinsAmountMultiplier,
  pageType,
  kernelBandwidthCoefficient,
  selectedGate = null,
  entityLevelGateList,
  origDataRange,
  saveNewDensityBandwidth,
  highlightDotsBy,
}: TPrepareChartDataConfigList) =>
  cageDataList.map(({ cageList, dataName, datasetName }, index) => {
    const { coordinates, cagesDataByCoordinates } = getCoordinatesByAxesAndGate({
      entityList: cageList,
      xAxis: getCorrectDimension(xAxis, datasetName, dimensionsMapping),
      yAxis: getCorrectDimension(yAxis, datasetName, dimensionsMapping),
      gate: selectedGate,
      entityLevelGateList,
    });

    const stackedDataConfigPart: Partial<TPlotData> =
      !isStackedAndFilledEnabled && isStackedChartsChecked && index > 0
        ? { xaxis: `x${index + 1}`, yaxis: `y${index + 1}` }
        : {};

    const { dataConfig, densityBandWidth } = prepareDataConfig({
      coordinates,
      xAxisScaleType,
      yAxisScaleType,
      currentChartType,
      currentColorScale,
      customdata: cagesDataByCoordinates,
      dataName,
      datasetName,
      dataIndex: index,
      isChartFillEnabled,
      isStackedAndFilledEnabled: isStackedAndFilledEnabled && isStackedChartsChecked,
      currentHistogramDataGroupType,
      customHistogramBinsAmount,
      kernelBinsAmountMultiplier,
      pageType,
      ...(isNumber(kernelBandwidthCoefficient) && {
        kernelBandwidthCoefficient,
      }),
      selectedGate,
      origDataRange,
      highlightDotsBy,
      chartEntityList: cageList,
    });

    if (saveNewDensityBandwidth && densityBandWidth) {
      saveNewDensityBandwidth(densityBandWidth);
    }

    return { ...dataConfig, ...stackedDataConfigPart };
  });

export const getCoordinatesByAxesAndGate = ({
  entityList,
  xAxis,
  yAxis,
  gate,
  entityLevelGateList,
  scanId,
  laneId,
}: {
  entityList: TEntity[];
  xAxis: string;
  yAxis: string;
  gate?: Nullable<TGate>;
  entityLevelGateList?: TGate[];
  scanId?: string;
  laneId?: string;
}) => {
  if (!gate) {
    return getCoordinatesByAxes({
      entityList,
      xAxis,
      yAxis,
    });
  }

  const parent = entityLevelGateList ? findParentGate(entityLevelGateList, gate.parentId) : null;

  const xDimension = parent ? parent.xDimension : gate.xDimension;
  const yDimension = parent ? parent.yDimension : gate.yDimension;

  const { coordinates, cagesDataByCoordinates } = getCoordinatesByAxes({
    entityList,
    xAxis: gate.xDimension,
    yAxis: gate.yDimension,
  });

  const isChartAndGateAxesMatched = xAxis === gate.xDimension && yAxis === gate.yDimension;
  const filterCoordinatesDataPayload = {
    coordinates,
    xAxis: xDimension,
    yAxis: yDimension,
    gate,
    gates: entityLevelGateList,
    parentGate: parent,
    cagesDataByCoordinates,
    scanId,
    laneId,
  };

  const data = filterCoordinatesDataByGate(filterCoordinatesDataPayload);

  if (isChartAndGateAxesMatched && !parent) {
    return data;
  }
  const dataByAxes = getCoordinatesByAxes({
    entityList: data.cagesDataByCoordinates,
    xAxis,
    yAxis,
  });

  return dataByAxes;
};
