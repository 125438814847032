import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { API as awsAPI } from 'aws-amplify';

import graphqlEvents, { EOnExperimentEventType } from '@/helpers/graphqlEvents';
import ProgressToast from '@/helpers/progressToast';

import { ProcessCageLevelAnalysisStatus } from '@/graphql/API';
import * as mutations from '@/graphql/mutations';

import { experimentSelectors } from '@/store/slices/experiment';

const progressToast = new ProgressToast(
  (
    <span>
      Generate Primary Analysis Gates started.
      <br />
      We will notify you when data is updated.
    </span>
  ),
  'Generate Primary Analysis Gates finished. Files available to download.',
  'Generate Primary Analysis Gates failed.',
  true
);

export function useGeneratePrimaryAnalysisGates() {
  const experimentId = useSelector(experimentSelectors.selectCurrentExperimentId);
  const experimentData = useSelector(experimentSelectors.selectCurrentExperiment);

  const [isGeneratingInProgress, setIsGeneratingInProgress] = useState(false);

  const eventsSubscription = useRef<string | undefined>(undefined);

  const unsubscribeEvents = () => {
    if (eventsSubscription.current) {
      graphqlEvents.unsubscribe(eventsSubscription.current);
    }
  };

  const subscribeEvents = () => {
    unsubscribeEvents();
    graphqlEvents
      .subscribe(experimentId, experimentData?.organizationId ?? '', {
        eventType: EOnExperimentEventType.primaryAnalysisGatesCreated,
        success: endGeneratingWithSuccess,
        experimentChange: endGenerating,
        error: endGeneratingWithError,
      })
      .then((callBackUuid) => {
        eventsSubscription.current = callBackUuid;
      });
  };

  const endGenerating = () => {
    progressToast.hideMessage();
    setIsGeneratingInProgress(false);
    unsubscribeEvents();
  };

  const endGeneratingWithSuccess = () => {
    progressToast.showSuccessMessage();
    setIsGeneratingInProgress(false);
    unsubscribeEvents();
  };

  const endGeneratingWithError = (error?: unknown) => {
    progressToast.showErrorMessage(error);
    setIsGeneratingInProgress(false);
    unsubscribeEvents();
  };

  const startGenerating = async () => {
    progressToast.showLoadingMessage();

    setIsGeneratingInProgress(true);
    try {
      const response = await awsAPI.graphql({
        query: mutations.createPrimaryAnalysisGates,
        variables: {
          organizationId: experimentData?.organizationId ?? '',
          experimentId,
        },
        authMode: 'AMAZON_COGNITO_USER_POOLS',
      });
      // @ts-ignore
      const status = response?.data?.createPrimaryAnalysisGates?.status;
      if (status === ProcessCageLevelAnalysisStatus.PENDING) {
        subscribeEvents();
      } else {
        endGeneratingWithError();
      }
    } catch (error) {
      endGeneratingWithError(error);
    }
  };

  return useMemo(
    () => ({
      startGenerating,
      isGeneratingInProgress,
    }),
    [startGenerating, isGeneratingInProgress]
  );
}
