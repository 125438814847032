import { MutableRefObject, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { EMarkerSizes, EPageWithChartType, EChartType } from '@/types/charts';

import { calculateDensity, getCoordinatesByAxesAndGate, getRandomInt } from '@/helpers/charts/chartsData';
import { formatColorscalesForContourCharts, FULL_D3_COLORSCALES } from '@/helpers/charts/colorscales';
import { isHistogramsChartType } from '@/helpers/charts/lineHistogram';

import { EAxesGroupName, scatterplotsSelectors } from '@/store/slices/scatterplots';
import { histogramSettingsSelectors } from '@/store/slices/histogramSettings';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { PAGE_TYPES_WITH_HIDDEN_Y_TICK_LABELS } from '@/helpers/constants';

import { getChartsLayoutConfig } from '../datasetAnalysis/helpers';
import usePlotProxy from '../usePlotProxy';

type TUsePlotSettingsResponse = {
  isYAxisDisabled: boolean;
  getLayoutConfig: (dataCount: number, dragmode: string | boolean, range?: Nullable<TPopulationRange>) => TPlotLayout;
};

type TUsePlotSettingsProps = {
  graphRef: MutableRefObject<Nullable<IPlotlyHTMLDivElement>>;
  customXAxis?: string;
  customYAxis?: string;
  isPlotLoaded?: boolean;
  plotRangeName?: string;
  pageType?: EPageWithChartType;
  specificAxesGroupName?: EAxesGroupName;
};

export function usePlotSettings({
  graphRef,
  customXAxis,
  customYAxis,
  isPlotLoaded = true,
  pageType,
  specificAxesGroupName,
}: TUsePlotSettingsProps): TUsePlotSettingsResponse {
  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');
  const chartId = usePlotChartIdContext();
  const currentColorScale = useSelector(chartSettingsSelectors.selectCurrentColorScale(chartId));
  const isTickLabelsVisible = useSelector(chartSettingsSelectors.selectIsTickLabelsVisible(chartId));
  const contourBandWidth = useSelector(chartSettingsSelectors.selectContourBandWidth(chartId));
  const fullScreenChartData = useSelector(chartSettingsSelectors.selectFullScreenChartData);

  const storedXAxis = useSelector(scatterplotsSelectors.selectXAxis(specificAxesGroupName));

  const xAxis = useMemo(() => customXAxis ?? storedXAxis, [customXAxis, storedXAxis]);
  const storedYAxis = useSelector(scatterplotsSelectors.selectYAxis(specificAxesGroupName));
  const yAxis = useMemo(() => customYAxis ?? storedYAxis, [customYAxis, storedYAxis]);

  const { custom: customBandWidth } = useSelector(scatterplotsSelectors.selectDensityBandWidth);
  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));
  const selectedCustomRange = useSelector(chartSettingsSelectors.selectSelectedCustomRange(chartId));
  const { xAxisScaleType, yAxisScaleType } = useSelector(
    chartSettingsSelectors.selectCurrentScalesTypeForAxes(chartId)
  );

  const isStackedAndFilledEnabled = useSelector(histogramSettingsSelectors.selectIsStackedAndFilledEnabled);
  const isStackedChartsChecked = useSelector(histogramSettingsSelectors.selectIsStackedChartsChecked);

  const isHistogram = useMemo(() => isHistogramsChartType(currentChartType), [currentChartType]);

  const isYTickLabelsHiddenForHistograms = useMemo(
    () =>
      pageType &&
      PAGE_TYPES_WITH_HIDDEN_Y_TICK_LABELS.includes(pageType) &&
      currentChartType === EChartType.lineHistogram,
    [pageType, currentChartType]
  );

  useEffect(() => {
    if (!graphRef?.current?.data?.[0]) return;

    const update = {
      ncontours: contourBandWidth,
    };

    plotlyProxy.restyle(update);
  }, [contourBandWidth]);

  useEffect(() => {
    if (
      currentChartType !== EChartType.dotDensity ||
      !graphRef.current?.data?.[0] ||
      pageType === EPageWithChartType.matrixView ||
      fullScreenChartData
    ) {
      return;
    }

    const markerColorList = graphRef.current.data.map((dataConfig) => {
      const { colorsByDensity } = calculateDensity(dataConfig.x, dataConfig.y, currentColorScale, [
        customBandWidth.x,
        customBandWidth.y,
      ]);
      return colorsByDensity;
    });
    const update = {
      'marker.color': markerColorList,
      'marker.colorscale': currentColorScale,
    };

    plotlyProxy.restyle(update);
  }, [customBandWidth]);

  const getLayoutConfig = useCallback(
    (dataCount: number, dragmode: string | boolean, range?: Nullable<TPopulationRange>) =>
      getChartsLayoutConfig({
        dataCount,
        currentChartType,
        xAxisScaleType,
        yAxisScaleType,
        isTickLabelsVisible,
        dragmode,
        range: selectedCustomRange?.range ?? range,
        isStackedChartsChecked,
        isStackedAndFilledEnabled,
        withCustomRange: !!selectedCustomRange,
        pageType,
      }),
    [currentChartType, xAxisScaleType, yAxisScaleType, isTickLabelsVisible, selectedCustomRange]
  );

  const getColorscaleUpdateForDotDensity = useCallback(
    (entityList: TEntity[], isMatrixView = false) => {
      const {
        coordinates: { x, y },
      } = getCoordinatesByAxesAndGate({
        entityList,
        xAxis,
        yAxis,
      });

      const { colorsByDensity } = calculateDensity(
        x,
        y,
        currentColorScale,
        isMatrixView ? undefined : [customBandWidth.x, customBandWidth.y]
      );

      return {
        marker: {
          size: new Array(x.length).fill(EMarkerSizes.default),
          symbol: 'circle',
          color: colorsByDensity,
          colorscale: currentColorScale,
          line: {
            width: 0,
          },
        },
      };
    },
    [currentColorScale, xAxis, yAxis, customBandWidth]
  );

  const getNewColorsForHistograms = useCallback(() => {
    if (!graphRef.current?.data || !graphRef.current.data.length) {
      return {};
    }

    const newLineColors: string[] = [];
    const { data } = graphRef.current;
    const colorsAmount = FULL_D3_COLORSCALES[currentColorScale].length;

    if (data.length > 1) {
      data.forEach((_, index) => {
        const lineColor: string =
          index < colorsAmount
            ? FULL_D3_COLORSCALES[currentColorScale][index][1]
            : FULL_D3_COLORSCALES[currentColorScale][getRandomInt(colorsAmount)][1];

        newLineColors.push(lineColor);
      });
    } else {
      newLineColors.push('#01a7e9');
    }

    if (currentChartType === EChartType.histogram) {
      return { 'marker.color': newLineColors };
    }

    return {
      'line.color': newLineColors,
    };
  }, [currentColorScale]);

  useEffect(() => {
    if (!graphRef.current?.data || graphRef.current.data.length === 0 || !graphRef.current.layout || !isPlotLoaded) {
      return;
    }

    const customdata = graphRef.current.data[0]?.customdata ?? []; // data[0] is used because this data needed only for the dotDensity single chart;
    const isMatrixView = pageType === EPageWithChartType.matrixView;

    const newColorscaleByType: Record<string, any> = {
      [EChartType.dotDensity]: getColorscaleUpdateForDotDensity,
      [EChartType.histogram2d]: () => ({
        colorscale: [formatColorscalesForContourCharts(FULL_D3_COLORSCALES[currentColorScale])],
      }),
      [EChartType.histogram2dcontour]: () => ({
        colorscale: [formatColorscalesForContourCharts(FULL_D3_COLORSCALES[currentColorScale])],
      }),
      [EChartType.heatmap]: () => ({ colorscale: [FULL_D3_COLORSCALES[currentColorScale]] }),
      [EChartType.lineHistogram]: getNewColorsForHistograms,
      [EChartType.histogram]: getNewColorsForHistograms,
    };

    const dataConfigList = newColorscaleByType?.[currentChartType]
      ? newColorscaleByType[currentChartType](customdata, isMatrixView)
      : null;

    const changeableLayoutConfigPart = {
      'xaxis.showticklabels': isTickLabelsVisible,
      'yaxis.showticklabels': isTickLabelsVisible && !isYTickLabelsHiddenForHistograms,
    };

    plotlyProxy.update({ ...dataConfigList }, changeableLayoutConfigPart);
  }, [currentColorScale, isTickLabelsVisible, plotlyProxy.id]);

  return {
    isYAxisDisabled: isHistogram,
    getLayoutConfig,
  };
}

export default usePlotSettings;
