import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useExperimentContext } from '@/hooks/useExperimentContext';

import { chartDataSelectors } from '@/store/slices/chartData';
import { scatterplotsSelectors } from '@/store/slices/scatterplots';
import { experimentSelectors } from '@/store/slices/experiment';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { EAxesScaleType } from '@/types/charts';

import { getOrigDataRange, getOrigMultiDataRange } from './helpers';

export const useOrigDataRange = ({
  isSingleChart,
  scanId,
  laneId,
  xAxis,
  yAxis,
  xAxisScaleType,
  yAxisScaleType,
}: {
  isSingleChart: boolean;
  scanId?: string;
  laneId?: string;
  xAxis: string;
  yAxis: string;
  xAxisScaleType: EAxesScaleType;
  yAxisScaleType: EAxesScaleType;
}) => {
  const { chartDataList } = useExperimentContext();
  const currentAppLane = useSelector(experimentSelectors.selectCurrentLane);
  const allOrigDataRanges = useSelector(chartDataSelectors.selectAllOrigDataRanges);
  const allAxes = useSelector(scatterplotsSelectors.selectAxes);
  const specificDatasetOptionMap = useSelector(chartSettingsSelectors.selectSpecificDatasetOptionMap);
  const globalScaleTypes = useSelector(chartSettingsSelectors.selectCurrentScalesTypeForAxes());

  return useMemo(() => {
    if (isSingleChart) {
      return getOrigDataRange({
        allOrigDataRanges,
        scanId: scanId ?? currentAppLane?.dataset.scanId,
        laneId: laneId ?? currentAppLane?.dataset.laneId,
        xAxis,
        yAxis,
      });
    }
    return getOrigMultiDataRange({
      allOrigDataRanges,
      allAxes,
      chartDataList,
      xAxis,
      yAxis,
      specificDatasetOptionMap,
      xAxisScaleType,
      yAxisScaleType,
      globalScaleTypes,
    });
  }, [
    isSingleChart,
    allOrigDataRanges,
    currentAppLane,
    scanId,
    laneId,
    xAxis,
    yAxis,
    allAxes,
    chartDataList,
    xAxisScaleType,
    yAxisScaleType,
    specificDatasetOptionMap,
  ]);
};
