import { FC } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import Select from '@/components/common/Select';
import { themeOptions } from '@/types/theme';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useSettingsNotification } from '@/hooks/plotSettings/useSettingsNotification';
import { scatterplotsActions, scatterplotsSelectors } from '@/store/slices/scatterplots';
import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { EUnselectedOption } from '@/types/settings';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import styles from '../SettingsPopover.module.scss';
import { TSettingsPopover } from '../types';

const cn = classnames.bind(styles);

export type TDotSettings = Pick<TSettingsPopover, 'scatterPlotAxesOptions' | 'pageType'>;

const DotSettings: FC<TDotSettings> = ({ scatterPlotAxesOptions, pageType }) => {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const highlightDotsBy = useSelector(scatterplotsSelectors.selectHighlightDotsBy(chartId));
  const fullScreenChartData = useSelector(chartSettingsSelectors.selectFullScreenChartData);

  const { isSettingsChangingConfirmed } = useSettingsNotification({ pageType });

  const changeHighlightDotsBy = async (value: string) => {
    const isConfirmed = await isSettingsChangingConfirmed();

    if (!isConfirmed) {
      return;
    }

    appDispatch(
      scatterplotsActions.setHighlightDotsBy({ highlightBy: value, fullScreenChartDataId: fullScreenChartData?.id })
    );
  };

  return (
    scatterPlotAxesOptions?.length && (
      <div className={cn('section')}>
        <div className={cn('section__head')}>
          <span>Color by</span>
        </div>
        <Select
          onChange={changeHighlightDotsBy}
          value={highlightDotsBy}
          options={[{ label: EUnselectedOption.label, value: EUnselectedOption.value }, ...scatterPlotAxesOptions]}
          placeholder="Label type"
          className={cn('section__select', 'gate-label-format-select')}
          theme={themeOptions.light}
        />
      </div>
    )
  );
};

export default DotSettings;
