import { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';
import 'react-datepicker/dist/react-datepicker.css';

import styles from './InstrumentCard.module.scss';

const cn = classnames.bind(styles);

type TInstrumentCardHeaderProps = {
  className?: string;
  title: string;
  children?: ReactNode;
};

const InstrumentCardHeader: FC<TInstrumentCardHeaderProps> = ({ className, title, children }) => (
  <div className={cn('header', className)}>
    <h2 className={cn('header__title')}>{title}</h2>
    {children}
  </div>
);

export default InstrumentCardHeader;
