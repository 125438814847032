import { ChangeEvent, FC, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import { useQuery } from '@apollo/client';

import PageHeader from '@/components/Layout/Page/PageHeader';
import SearchInput from '@/components/common/SearchInput';
import ControlPanel from '@/components/Layout/ControlPanel';
import NoDataFound from '@/components/common/NoDataFound';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { headerActions } from '@/store/slices/header';

import { searchInstruments } from '@/helpers/instruments/searchInstruments';

import * as queries from '@/graphql/queries';

import styles from './InstrumentsDashboard.module.scss';
import InstrumentCard from './components/InstrumentCard';

const cn = classnames.bind(styles);

const InstrumentsDashboard: FC = () => {
  const appDispatch = useAppDispatch();

  const [searchQuery, setSearchQuery] = useState('');

  const {
    data,
    loading: isInstrumentListLoading,
    error: isInstrumentListError,
  } = useQuery(queries.instruments, {
    errorPolicy: 'all',
    variables: {
      limit: 50,
    },
  });

  const foundInstruments = useMemo(
    () => searchInstruments(searchQuery, data?.instruments ?? []),
    [searchQuery, data?.instruments]
  );

  const handleSearchInputChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(value);
  };
  const handleResetSearchClick = () => {
    setSearchQuery('');
  };

  const isErrorWithNoData = useMemo(
    () => isInstrumentListError && !data?.instruments?.length,
    [isInstrumentListError, data]
  );

  const isEmptyData = useMemo(
    () => !isInstrumentListLoading && (isErrorWithNoData || !foundInstruments?.length),
    [isInstrumentListLoading, isErrorWithNoData, foundInstruments?.length]
  );

  const getInstrumentsCards = () => {
    if (isEmptyData) {
      return (
        <NoDataFound
          size="normal"
          alignment="center"
          textData={isErrorWithNoData ? 'Something went wrong' : 'No data found'}
        />
      );
    }

    return (
      <div className={cn('dashboard__items_cards')}>
        {foundInstruments?.map((instrument) => (
          <InstrumentCard key={instrument.id} instrument={instrument} searchQuery={searchQuery} />
        ))}
      </div>
    );
  };

  useEffect(() => {
    appDispatch(headerActions.removeAllLinks());
  }, []);

  return (
    <div className={cn('dashboard')}>
      <div className={cn('dashboard__content')}>
        <PageHeader>
          <PageHeader.Section>
            <PageHeader.Title as="h1">Instruments Dashboard</PageHeader.Title>
          </PageHeader.Section>
          <PageHeader.Section>
            <PageHeader.Title isStatistics>{foundInstruments?.length}</PageHeader.Title>
            <PageHeader.Subtitle>{foundInstruments?.length === 1 ? 'Instrument' : 'Instruments'}</PageHeader.Subtitle>
          </PageHeader.Section>
        </PageHeader>
        <ControlPanel isLoading={isInstrumentListLoading} className={cn('control-panel')}>
          <ControlPanel.RightActions>
            <SearchInput
              onChange={handleSearchInputChange}
              onReset={handleResetSearchClick}
              value={searchQuery}
              disabled={isInstrumentListLoading}
              className={cn('control-panel__search')}
            />
          </ControlPanel.RightActions>
        </ControlPanel>
        {getInstrumentsCards()}
      </div>
    </div>
  );
};

export default InstrumentsDashboard;
