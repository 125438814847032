import { ComponentType, FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { findLaneInScanList } from '@/helpers/scans';

import { useParamsExperimentId } from '@/hooks';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import useGeneralGates from '@/hooks/preprocessing/useGeneralGates';
import { useObjectEntitiesByLanesAndGates } from '@/hooks/useExperimentContext/useObjectEntitiesByLanesAndGates';
import { useChartWithGatesProps } from '@/hooks/useChartWithGatesProps';
import useSelectedGateForSpecificDataset from '@/hooks/preprocessing/useSelectedGateForSpecificDataset';

import { cdnAPI } from '@/store/services/cdnData';
import { preprocessingSelectors } from '@/store/slices/preprocessing';
import { experimentSelectors } from '@/store/slices/experiment';

import SingleChartWithGates from '@/components/charts/SingleChartWithGates';
import DatasetChartHeading, { TDatasetChartHeadingProps } from '@/components/charts/DatasetChartHeading';
import DatasetChart from '@/pages/Dataset/components/DatasetChart';
import type {
  TCustomCreateGateComponentProps,
  TGateList,
  TGeneralChartProps,
} from '@/components/charts/SingleChartWithGates/types';
import { EPageWithChartType } from '@/types/charts';

import styles from './PreprocessingDatasetChart.module.scss';

const cn = classnames.bind(styles);

export type TPreprocessingDatasetChart = {
  deleteGate: TGeneralChartProps['deleteGate'];
  createGate: TGeneralChartProps['createGate'];
  updateGate: TGeneralChartProps['updateGate'];
  deleteGateChildren: TGeneralChartProps['deleteGateChildren'];
  customPlotlySelectedHandler?: (newGateData: Nullable<TNewGateModelData>) => void;
  noExpandModeClassName?: string;
  onlyShowAxisValue?: boolean;
  customScatterAxesOptions?: TOption[];
  needResetRange?: boolean;
  datasetDetails: TDatasetDetails;
  headingChildren?: TDatasetChartHeadingProps['children'];
  GatesComponent: ComponentType<TGateList>;
  CustomCreateGateComponent?: ComponentType<TCustomCreateGateComponentProps>;
  disabled?: boolean;
  handleSwapChannels?: () => void;
  xAxisSpecific?: string;
  yAxisSpecific?: string;
};

const PreprocessingDatasetChart: FC<TPreprocessingDatasetChart> = ({
  deleteGate,
  createGate,
  updateGate,
  deleteGateChildren,
  customPlotlySelectedHandler,
  noExpandModeClassName = '',
  onlyShowAxisValue = false,
  customScatterAxesOptions,
  needResetRange,
  datasetDetails,
  headingChildren,
  GatesComponent,
  CustomCreateGateComponent,
  disabled,
  handleSwapChannels,
  xAxisSpecific,
  yAxisSpecific,
}) => {
  const appDispatch = useAppDispatch();
  const experimentId = useParamsExperimentId();
  const datasets = useSelector(preprocessingSelectors.selectDatasets);

  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const [fetchObjectEntityList] = cdnAPI.useLazyFetchObjectEntityListQuery();

  const { scanId, laneId, channelName = '', id } = datasetDetails;
  const selectedGate = useSelectedGateForSpecificDataset(id);
  const [isExpandMode, setIsExpandMode] = useState(false);

  const fullGateList = useGeneralGates(id);
  const datasetList = useMemo(() => datasets.map((dataset) => dataset.dataset), datasets);

  const currentLane = useMemo(() => findLaneInScanList(scanList, scanId, laneId), [scanList, scanId, laneId]);

  const entityLevelGateList = useMemo(
    () => (selectedGate ? [{ ...selectedGate, gateNodes: fullGateList }] : fullGateList),
    [selectedGate, fullGateList]
  );

  const { objectEntitiesByLanesAndGates, objectEntitiesLoadedLaneList } = useObjectEntitiesByLanesAndGates({
    experimentId,
    currentAppDatasetList: datasetList,
    currentAppDataset: datasetDetails.dataset,
    gateList: entityLevelGateList,
  });

  const handleExpandMode = useCallback((isExpand: boolean) => {
    setIsExpandMode(isExpand);
  }, []);

  const changeSelectedChartData: TGeneralChartProps['changeSelectedChartData'] = useCallback(
    (data) => {
      // eslint-disable-next-line no-console
      console.log(data);
    },
    [appDispatch]
  );

  useEffect(() => {
    fetchObjectEntityList(currentLane, true);
  }, [currentLane]);

  const { allProps, ...componentsProps } = useChartWithGatesProps({
    scanId,
    laneId,
    channelName,
    experimentId,
    createGate,
    updateGate,
    deleteGate,
    deleteGateChildren,
    withoutSearchParams: true,
    scanList,
    entityLevelGateList,
    changeSelectedChartData,
    chartDataList: [datasetDetails],
    currentAppLane: currentLane,
    selectedChartData: datasetDetails,
    entitiesByLanesAndGates: objectEntitiesByLanesAndGates,
    entitiesLoadedLaneList: objectEntitiesLoadedLaneList,
    currentAppDatasetList: datasetList,
    cageEntitiesByLanesAndGates: objectEntitiesByLanesAndGates,
    fullGateList,
    onlyShowAxisValue,
    plotId: `preprocessing-chart-${datasetDetails.id}`,
    needResetRange,
    handleSwapChannels,
    ...(xAxisSpecific &&
      yAxisSpecific && {
        xAxisSpecific,
        yAxisSpecific,
      }),
  });

  return (
    <SingleChartWithGates
      ChartHeadingComponent={DatasetChartHeading}
      ChartComponent={DatasetChart}
      GatesComponent={GatesComponent}
      CustomCreateGateComponent={CustomCreateGateComponent}
      {...componentsProps}
      className={cn('dataset-chart', {
        [noExpandModeClassName]: !isExpandMode,
        'dataset-chart__single-chart': !isExpandMode,
        'dataset-chart_disabled': disabled,
      })}
      chartContainerClassName={cn({ 'dataset-chart__chart-container': !isExpandMode })}
      handleExpandMode={handleExpandMode}
      customScatterAxesOptions={customScatterAxesOptions}
      headingChildren={headingChildren}
      customPlotlySelectedHandler={customPlotlySelectedHandler}
      pageType={EPageWithChartType.preprocessing}
    />
  );
};

export default memo(PreprocessingDatasetChart);
