import { ChangeEvent, FC, memo, useCallback, useEffect, useState } from 'react';
import classnames from 'classnames/bind';
import { toast } from 'react-toastify';

import { inviteUserSchema } from '@/validationSchemas';
import { PhoneNumberInputRegex } from '@/helpers/validator';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';

import { authAPI } from '@/store/services/auth';

import Modal from '@/components/common/Modal';
import TextInput from '@/components/common/TextInput';
import Button from '@/components/common/Button';

import styles from './AdminModals.module.scss';

import type { TAdminModalProps, TErrorsMapGeneric } from './types';

const cn = classnames.bind(styles);

type TUpdateUserAttributesModalProps = TAdminModalProps & {
  user: TUser;
};

const validationFields = {
  email: 'email',
  name: 'name',
  phoneNumber: 'phoneNumber',
} as const;

type TErrorsMap = TErrorsMapGeneric<typeof validationFields>;

const UpdateUserAttributesModal: FC<TUpdateUserAttributesModalProps> = ({ closeModal, isModalOpen, user }) => {
  const [updateUserAttributes, { isLoading: isSaveInProgress }] = authAPI.useUpdateUserAttributesMutation();

  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [phoneNumber, setPhoneNumber] = useState(user.phoneNumber);
  const [errors, setErrors] = useState<TErrorsMap>({});

  const handleChangeName = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  }, []);

  const handleChangeEmail = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.trim());
    setErrors((prev) => {
      delete prev.email;
      return prev;
    });
  }, []);

  const handleChangePhoneNumber = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (!PhoneNumberInputRegex?.test(event.target.value)) {
      return;
    }
    setErrors((prev) => {
      delete prev.phoneNumber;
      return prev;
    });
    setPhoneNumber(event.target.value);
  }, []);

  const handleFormSubmit = useCallback(() => {
    const validate = inviteUserSchema.safeParse({
      email,
      phoneNumber: phoneNumber ?? '',
      name,
    });

    if (!validate.success) {
      setErrors(
        validate.error.issues.reduce((acc: TErrorsMap, { message, path }) => {
          acc[path[0].toString() as keyof typeof validationFields] = message;
          return acc;
        }, {})
      );
      return;
    }

    updateUserAttributes({
      email,
      name: name ?? '',
      phoneNumber: phoneNumber ?? '',
      username: user.username,
    })
      .unwrap()
      .then(() => {
        closeModal();
      })
      .catch((error) => {
        showErrorToast(getErrorMessage(error));
      });
  }, [email, name, phoneNumber]);

  useEffect(() => {
    Object.values(errors).forEach((message) => {
      toast.error(message);
    });
  }, [errors]);

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      shouldCloseOnOverlayClick
      className={cn('modal')}
      onFormSubmit={handleFormSubmit}
    >
      <Modal.Header onRequestClose={closeModal}>
        <h2>Update user attributes</h2>
      </Modal.Header>
      <Modal.Content>
        <div className={cn('modal__row')}>
          <TextInput
            className={cn('modal__input', 'modal__input_error')}
            value={email}
            label={
              <>
                <span>Email</span>
                <span className={cn('required')}>*</span>
              </>
            }
            labelClassName={cn('modal__input-label', { 'modal__input-label_error': errors.email })}
            onChange={handleChangeEmail}
            isBgLight
            id="update-user__email-input"
            errorMessage={errors.email}
          />
        </div>
        <div className={cn('modal__row')}>
          <TextInput
            className={cn('modal__input')}
            value={name}
            label={<span>Full name</span>}
            labelClassName={cn('modal__input-label')}
            onChange={handleChangeName}
            isBgLight
            id="update-user__name-input"
            errorMessage={errors.name}
          />
        </div>
        <div className={cn('modal__row')}>
          <TextInput
            className={cn('modal__input')}
            value={phoneNumber}
            label="Phone number"
            labelClassName={cn('modal__input-label', { 'modal__input-label_error': errors.phoneNumber })}
            onChange={handleChangePhoneNumber}
            isBgLight
            id="update-user__phone-number-input"
            errorMessage={errors.phoneNumber}
          />
        </div>
      </Modal.Content>
      <Modal.Footer className={cn('modal__footer')}>
        <Button
          type="button"
          id="update-user__cancel"
          onClick={closeModal}
          color="white"
          className={cn('modal__button')}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          id="update-user__save"
          color="yellow"
          className={cn('modal__button')}
          isLoading={isSaveInProgress}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default memo(UpdateUserAttributesModal);
