import { FC, ReactNode } from 'react';
import classnames from 'classnames/bind';
import 'react-datepicker/dist/react-datepicker.css';

import LoaderProgress from '@/components/common/LoaderProgress';
import NoDataFound from '@/components/common/NoDataFound';
import styles from './InstrumentCard.module.scss';

const cn = classnames.bind(styles);

type TInstrumentCardContentProps = {
  children: ReactNode;
  isLoading?: boolean;
  isNoData?: boolean;
  noDataMessage?: string;
  className?: string;
  id?: string;
};

const InstrumentCardContent: FC<TInstrumentCardContentProps> = ({
  children,
  isLoading,
  isNoData,
  noDataMessage,
  className,
  id,
}) =>
  isLoading ? (
    <div className={cn('loader')}>
      <LoaderProgress isLoading={isLoading} theme="light" />
    </div>
  ) : (
    <div className={cn('content', className)} id={id}>
      {isNoData ? (
        <NoDataFound alignment="center" size="big" textData={noDataMessage ?? 'No Data'} className={cn('no-data')} />
      ) : (
        children
      )}
    </div>
  );

export default InstrumentCardContent;
