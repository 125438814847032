import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import { shallowEqual } from 'react-redux';

import { generateJwtToken, removeDuplicates } from '@/helpers';
import { isDefined } from '@/helpers/typeGuards';

import { transformUserListResponse } from './helpers';

export const authAPI = createApi({
  reducerPath: 'authAPI',
  keepUnusedDataFor: 120, // This is how long (in seconds, Defaults to 60) RTK Query will keep your data cached for after the last component unsubscribes
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/authsvc`,
    prepareHeaders: (headers) =>
      new Promise((resolve) => {
        generateJwtToken().then(
          (token) => {
            headers.set('authorization', `Bearer ${token}`);
            resolve(headers);
          },
          () => {
            resolve(headers);
          }
        );
      }),
  }),
  tagTypes: ['User', 'Team', 'TeamUser', 'TeamProject'],
  endpoints: (build) => ({
    fetchUserList: build.query<TFetchUserListResult, TFetchUserListQuery>({
      query: ({ nextToken }) => ({
        url: '/users',
        method: 'GET',
        params: {
          nextToken,
          limit: 50,
        },
      }),
      providesTags: ['User'],
      transformResponse: transformUserListResponse,
      // Have cache entry without nextToken because the arg maps without it
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        const { nextToken: _, ...argsWithoutNextToken } = queryArgs; // do not use nextToken
        return `${endpointName}(${JSON.stringify(argsWithoutNextToken)})`;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        currentCache.nextToken = newItems.nextToken;
        const mergedList = removeDuplicates(currentCache.list.concat(newItems.list), 'username');
        currentCache.list = mergedList;
      },
      // Refetch when the page arg changes
      forceRefetch: ({ currentArg, previousArg }) => {
        if (!previousArg) {
          // first page
          return true;
        }
        const { nextToken: prevNextToken, ...prevArgsWithoutNextToken } = previousArg;
        const { nextToken: currNextToken, ...currArgsWithoutNextToken } = currentArg ?? {};
        if (!shallowEqual(prevArgsWithoutNextToken, currArgsWithoutNextToken)) {
          // some parameters (except nextToken) have changed
          return true;
        }
        // empty currNextToken and not empty prevNextToken -> the page is after the last one -> do not refetch
        if (!currNextToken && !!prevNextToken) {
          return false;
        }
        // refetch if nextToken has changed
        return prevNextToken !== currNextToken;
      },
    }),
    fetchTeamUserList: build.query<TFetchUserListResult, TFetchTeamUserListQuery>({
      query: ({ nextToken, teamId }) => ({
        url: `/teams/${teamId}/users`,
        method: 'GET',
        params: {
          nextToken,
          limit: 50,
        },
      }),
      providesTags: ['TeamUser'],
      transformResponse: transformUserListResponse,
      // Have cache entry without nextToken because the arg maps without it
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        const { nextToken, ...argsWithoutNextToken } = queryArgs; // do not use nextToken
        return `${endpointName}(${JSON.stringify(argsWithoutNextToken)})`;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        currentCache.nextToken = newItems.nextToken;
        const mergedList = removeDuplicates(currentCache.list.concat(newItems.list), 'username');
        currentCache.list = mergedList;
      },
      // Refetch when the page arg changes
      forceRefetch: ({ currentArg, previousArg }) => {
        if (!previousArg) {
          // first page
          return true;
        }
        const { nextToken: prevNextToken, ...prevArgsWithoutNextToken } = previousArg;
        const { nextToken: currNextToken, ...currArgsWithoutNextToken } = currentArg ?? {};
        if (!shallowEqual(prevArgsWithoutNextToken, currArgsWithoutNextToken)) {
          // some parameters (except nextToken) have changed
          return true;
        }
        // empty currNextToken and not empty prevNextToken -> the page is after the last one -> do not refetch
        if (!currNextToken && !!prevNextToken) {
          return false;
        }
        // refetch if nextToken has changed
        return prevNextToken !== currNextToken;
      },
    }),

    inviteUser: build.mutation<Partial<TUser>, TInviteUserMutation>({
      query: ({ name, email, phoneNumber, role, teamId }) => ({
        url: `/users`,
        method: 'POST',
        body: { name, email, phoneNumber, role, teamId },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(authAPI.endpoints.fetchUserList.initiate({}, { forceRefetch: true }));
      },
    }),

    removeUserFromOrganization: build.mutation<unknown, string>({
      query: (username) => ({
        url: `/users/${username}`,
        method: 'DELETE',
      }),
      onQueryStarted(username, { dispatch }) {
        dispatch(
          authAPI.util.updateQueryData('fetchUserList', {}, (cache) => {
            cache.list = cache.list.filter((user) => user.username !== username);
            return cache;
          })
        );
      },
    }),

    fetchUserRoles: build.query<TFetchUserPermisisons, { username: string }>({
      query: ({ username }) => ({
        url: `users/${username}/permission`,
        method: 'GET',
      }),
    }),

    addUserToTeam: build.mutation<Partial<TUser>, TAddUserToTeamMutation>({
      query: ({ teamId, username, role }) => ({
        url: `/teams/${teamId}/users/${username}`,
        method: 'PUT',
        body: {
          role,
        },
      }),
      async onQueryStarted({ teamId }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(authAPI.endpoints.fetchTeamUserList.initiate({ teamId }, { forceRefetch: true }));
      },
    }),

    removeUserFromTeam: build.mutation<unknown, TRemoveUserFromTeamMutation>({
      query: ({ teamId, username }) => ({
        url: `/teams/${teamId}/users/${username}`,
        method: 'DELETE',
      }),
      onQueryStarted({ teamId, username }, { dispatch }) {
        dispatch(
          authAPI.util.updateQueryData('fetchTeamUserList', { teamId }, (cache) => {
            cache.list = cache.list.filter((user) => user.username !== username);
            return cache;
          })
        );
      },
    }),

    updateUserAttributes: build.mutation<Partial<TUser>, TUpdateUserAttributesMutation>({
      query: ({ username, name, email, phoneNumber }) => ({
        url: `/users/${username}/attributes`,
        method: 'PUT',
        body: { name, email, phoneNumber },
      }),
      onQueryStarted({ username, name, email, phoneNumber }, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              authAPI.util.updateQueryData('fetchUserList', {}, (cache) => {
                cache.list = cache.list.map((user) => {
                  if (user.username === username) {
                    return { ...user, name, email, phoneNumber };
                  }
                  return user;
                });
                return cache;
              })
            );
          })
          .catch((_error) => null);
      },
    }),

    createTeam: build.mutation<TTeam, TCreateTeamMutation>({
      query: ({ name, description }) => ({
        url: `/teams`,
        method: 'POST',
        body: { name, description },
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(authAPI.endpoints.fetchTeamList.initiate({}, { forceRefetch: true }));
      },
    }),

    // not sure that is endpoint correct
    deleteTeam: build.mutation<unknown, TDeleteTeamMutation>({
      query: ({ id }) => ({
        url: `/teams/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted({ id }, { dispatch }) {
        dispatch(
          authAPI.util.updateQueryData('fetchTeamList', {}, (cache) => {
            cache.list = cache.list.filter((team) => team.id !== id);
            return cache;
          })
        );
      },
    }),

    fetchTeamList: build.query<TFetchTeamListResult, TFetchTeamListQuery>({
      query: ({ nextToken }) => ({
        url: '/teams',
        method: 'GET',
        params: {
          nextToken,
          limit: 50,
        },
      }),
      providesTags: ['Team'],
      transformResponse: (teamListFromServer: TTeam[], meta): TFetchTeamListResult => ({
        nextToken: meta?.response?.headers.get('X-Pagination-Next-Token') ?? undefined,
        list: teamListFromServer,
      }),
      // Have cache entry without nextToken because the arg maps without it
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        const { nextToken: _, ...argsWithoutNextToken } = queryArgs; // do not use nextToken
        return `${endpointName}(${JSON.stringify(argsWithoutNextToken)})`;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        currentCache.nextToken = newItems.nextToken;
        const mergedList = removeDuplicates(currentCache.list.concat(newItems.list), 'id');
        currentCache.list = mergedList;
      },
      // Refetch when the page arg changes
      forceRefetch: ({ currentArg, previousArg }) => {
        if (!previousArg) {
          // first page
          return true;
        }
        const { nextToken: prevNextToken, ...prevArgsWithoutNextToken } = previousArg;
        const { nextToken: currNextToken, ...currArgsWithoutNextToken } = currentArg ?? {};
        if (!shallowEqual(prevArgsWithoutNextToken, currArgsWithoutNextToken)) {
          // some parameters (except nextToken) have changed
          return true;
        }
        // empty currNextToken and not empty prevNextToken -> the page is after the last one -> do not refetch
        if (!currNextToken && !!prevNextToken) {
          return false;
        }
        // refetch if nextToken has changed
        return prevNextToken !== currNextToken;
      },
    }),
    fetchTeamProjectList: build.query<TFetchTeamProjectListResult, TFetchTeamProjectListQuery>({
      query: ({ nextToken, teamId }) => ({
        url: `/teams/${teamId}/projects`,
        method: 'GET',
        params: {
          nextToken,
          limit: 50,
        },
      }),
      providesTags: ['TeamProject'],
      transformResponse: (teamProjectListFromServer: TTeamProject[], meta): TFetchTeamProjectListResult => ({
        nextToken: meta?.response?.headers.get('X-Pagination-Next-Token') ?? undefined,
        list: teamProjectListFromServer,
      }),
      // Have cache entry without nextToken because the arg maps without it
      serializeQueryArgs: ({ queryArgs, endpointName }) => {
        const { nextToken: _, ...argsWithoutNextToken } = queryArgs; // do not use nextToken
        return `${endpointName}(${JSON.stringify(argsWithoutNextToken)})`;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems) => {
        currentCache.nextToken = newItems.nextToken;
        const mergedList = removeDuplicates(currentCache.list.concat(newItems.list), 'id');
        currentCache.list = mergedList;
      },
      // Refetch when the page arg changes
      forceRefetch: ({ currentArg, previousArg }) => {
        if (!previousArg) {
          // first page
          return true;
        }
        const { nextToken: prevNextToken, ...prevArgsWithoutNextToken } = previousArg;
        const { nextToken: currNextToken, ...currArgsWithoutNextToken } = currentArg ?? {};
        if (!shallowEqual(prevArgsWithoutNextToken, currArgsWithoutNextToken)) {
          // some parameters (except nextToken) have changed
          return true;
        }
        // empty currNextToken and not empty prevNextToken -> the page is after the last one -> do not refetch
        if (!currNextToken && !!prevNextToken) {
          return false;
        }
        // refetch if nextToken has changed
        return prevNextToken !== currNextToken;
      },
    }),

    addProjectToTeam: build.mutation<unknown, TAddProjectToTeamMutation>({
      query: ({ teamId, projectId, allowedRoles }) => ({
        url: `/teams/${teamId}/projects/${projectId}`,
        method: 'PUT',
        body: {
          allowedRoles,
        },
      }),
      async onQueryStarted({ teamId }, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(authAPI.endpoints.fetchTeamProjectList.initiate({ teamId }, { forceRefetch: true }));
      },
    }),
    removeProjectFromTeam: build.mutation<unknown, TRemoveProjectFromTeamMutation>({
      query: ({ teamId, projectId }) => ({
        url: `/teams/${teamId}/projects/${projectId}`,
        method: 'DELETE',
      }),
      onQueryStarted({ teamId, projectId }, { dispatch }) {
        dispatch(
          authAPI.util.updateQueryData('fetchTeamProjectList', { teamId }, (cache) => {
            cache.list = cache.list.filter((project) => project.id !== projectId);
            return cache;
          })
        );
      },
    }),
    changeMFA: build.mutation<unknown, TChangeMFAMutation>({
      query: ({ username, ...bodyProps }) => ({
        url: `/users/${username}/mfa`,
        method: 'PUT',
        body: bodyProps,
      }),
      onQueryStarted({ username, required, status }, { dispatch, queryFulfilled }) {
        queryFulfilled
          .then(() => {
            dispatch(
              authAPI.util.updateQueryData('fetchUserList', {}, (cache) => {
                cache.list = cache.list.map((user) => {
                  if (user.username === username && isDefined(required)) {
                    user.mfa.required = required;
                  }
                  if (user.username === username && isDefined(status)) {
                    user.mfa.status = status;
                  }
                  return user;
                });
                return cache;
              })
            );
          })
          .catch(() => null);
      },
    }),
  }),
});
