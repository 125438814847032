import { FC, memo, useMemo } from 'react';
import classnames from 'classnames/bind';
import { useSearchParams } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';

import { getInstrumentCurrentStatusBadgeData } from '@/components/experiment/StatusesInfo/helpers';
import StatusBadge from '@/components/experiment/StatusesInfo/StatusBadge';
import LinkElement from '@/components/common/LinkElement';
import DetailsPopover, { TDetailsFields } from '@/components/common/DetailsPopover';
import InfoButton from '@/components/common/InfoButton';

import { camelCaseToWords, isKeyOf, MDASH } from '@/helpers';
import { isMultipleInstrumentVersionGroups } from '@/helpers/typeGuards';

import { formatDateTime } from '@/pages/Home/helpers';

import styles from './InstrumentPageHeader.module.scss';

const cn = classnames.bind(styles);

type TInstrumentPageHeaderProps = {
  instrument?: TInstrumentFromServer;
  className?: string;
};

const InstrumentPageHeader: FC<TInstrumentPageHeaderProps> = ({ instrument, className }) => {
  const [searchParams] = useSearchParams();
  const instrumentName = searchParams.get('name') ?? null;

  const badgeData = useMemo(
    () => (instrument?.currentStatus ? getInstrumentCurrentStatusBadgeData(instrument.currentStatus) : null),
    [instrument?.currentStatus]
  );

  const details = useMemo(() => {
    if (!instrument?.componentsVersion?.data) return null;

    if (Array.isArray(instrument?.componentsVersion?.data)) {
      return [
        {
          label: 'System Component Versions',
          value:
            instrument?.componentsVersion?.data.map((item) => ({
              name: item?.name ?? '',
              value: item?.version ?? '',
            })) ?? [],
          isFullWidth: true,
        },
      ];
    }

    const isMultipleVersionGroups = isMultipleInstrumentVersionGroups(instrument.componentsVersion?.data);

    if (!isMultipleVersionGroups) return null;

    const { data } = instrument.componentsVersion;
    const versionsDetails: TDetailsFields[] = [];

    Object.keys(data).forEach((key) => {
      if (isKeyOf(key, instrument.componentsVersion?.data ?? {})) {
        versionsDetails.push({
          label: camelCaseToWords(key),
          value: data?.[key]?.map((item: TComponentsVersion) => ({
            name: item?.name ?? MDASH,
            value: item?.version ?? MDASH,
          })),
          isFullWidth: false,
          className: cn('instrument__version-block'),
        });
      }
    });

    return versionsDetails;
  }, [instrument?.componentsVersion]);

  return (
    <div className={cn('instrument', className)}>
      <div className={cn('instrument__title-block')}>
        <h2 className={cn('instrument__name')}>{instrumentName ?? instrument?.name}</h2>
        {badgeData && <StatusBadge className={cn('instrument__status')} {...badgeData} />}
      </div>

      <div className={cn('instrument__details')}>
        <div className={cn('instrument__details-block')}>
          <div className={cn('instrument__row')}>
            <span>Operator</span>
            <span>{instrument?.currentStatus?.operator ?? MDASH}</span>
          </div>
          <div className={cn('instrument__row')}>
            <span>SW Version</span>
            <div className={cn('instrument__column-content')}>
              <span>{instrument?.currentStatus?.version ?? MDASH}</span>
              {details && (
                <DetailsPopover
                  details={details}
                  className={cn('instrument__version', {
                    instrument__version_wide: isMultipleInstrumentVersionGroups(instrument?.componentsVersion?.data),
                  })}
                  tooltip="System Component Versions"
                  CustomButton={InfoButton}
                />
              )}
            </div>
          </div>
        </div>
        {instrument?.currentStatus?.experiment?.name && (
          <div className={cn('instrument__details-block')}>
            <div className={cn('instrument__row')}>
              <span>Run name</span>
              <LinkElement
                link={`/experiment/${instrument?.currentStatus?.experiment?.id}`}
                disabled={!instrument?.currentStatus?.experiment?.id}
                title={instrument.currentStatus.experiment.name}
              />
            </div>
            <div className={cn('instrument__row')}>
              <span>Run start</span>
              <span>{formatDateTime(instrument?.currentStatus?.timestamp, 'yyyy-MM-dd HH:mm:ss') ?? MDASH}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(InstrumentPageHeader);
