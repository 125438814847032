import { select as d3Select } from 'd3-selection';
import { toast } from 'react-toastify';
import { ApolloClient, DocumentNode } from '@apollo/client';

import { TD3DragEvent } from '@/hooks/gates/types';
import { handlePlotlyResize } from '@/helpers/plotly';
import { getErrorMessage } from '@/helpers/errors';

import { TFetchTokenResult, TApolloResponse } from './types';

const MIN_WRAPPER_HEIGHT = 250;
const MIN_LIST_BLOCK_HEIGHT = 100;

export const onDrag = (event: TD3DragEvent) => {
  const timelineWrapper = d3Select('#timeline-wrapper').node() as Element;
  const contentWrapper = d3Select('#content');
  const listBlock = d3Select('#sensor-list').node() as Element;

  const timelineWrapperClientData = timelineWrapper?.getBoundingClientRect() ?? null;
  const listBlockClientData = listBlock?.getBoundingClientRect() ?? null;

  if (timelineWrapperClientData || !listBlockClientData) {
    const newWrapperHeight = timelineWrapperClientData.height + event.y;
    const newListBlockHeight = listBlockClientData.height - event.y;

    const validatedHeight =
      newListBlockHeight < MIN_LIST_BLOCK_HEIGHT || newWrapperHeight < MIN_WRAPPER_HEIGHT
        ? timelineWrapperClientData.height
        : newWrapperHeight;
    contentWrapper.style('grid-template-rows', `${validatedHeight}px 1fr`);
    handlePlotlyResize();
  }
};

export const fetchAllDataWithPagination = async <T>(
  query: DocumentNode,
  initialVariables: Record<string, unknown>,
  client: ApolloClient<unknown>, // ApolloClient instance
  select: (data: TFetchTokenResult<T>) => TApolloResponse<T>
): Promise<T[]> => {
  const allResults: T[] = [];

  await fetchPage<T>(query, initialVariables, allResults, client, select);

  return allResults;
};

export const fetchPage = async <T>(
  query: DocumentNode,
  variables: Record<string, unknown>,
  allResults: T[],
  client: ApolloClient<unknown>, // ApolloClient instance
  select: (data: TFetchTokenResult<T>) => TApolloResponse<T>
): Promise<void> => {
  try {
    const { data } = await client.query({
      query,
      variables: { ...variables },
    });

    const response = select(data);

    const { items, nextToken } = response || {};

    if (items && items.length > 0) {
      allResults.push(...items);
    }

    if (nextToken) {
      await fetchPage(query, { ...variables, startToken: nextToken }, allResults, client, select);
    }
  } catch (error) {
    toast.error(getErrorMessage(error));
    throw error;
  }
};
