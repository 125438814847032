import { FC, memo, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';

import { useDebounce } from '@/hooks';
import { useSettingsNotification } from '@/hooks/plotSettings/useSettingsNotification';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { EAxesScaleType, EPageWithChartType } from '@/types/charts';
import { scatterplotsSelectors } from '@/store/slices/scatterplots';
import { TCustomRange, chartSettingsActions, chartSettingsSelectors } from '@/store/slices/chartSettings';
import { histogramSettingsSelectors } from '@/store/slices/histogramSettings';

import Accordion from '@/components/common/Accordion/Accordion';
import Button from '@/components/common/Button';

import stylesSettings from '../../SettingsPopover.module.scss';
import styles from './CustomRanges.module.scss';
import SingleCustomRange from './SingleCustomRange';
// import CustomRangesPopover from './CustomRangesPopover';

type TCustomRanges = {
  pageType: EPageWithChartType;
};

const cnSettings = classnames.bind(stylesSettings);
const cn = classnames.bind(styles);

const defaultRange: TCustomRange = {
  label: 'Default',
  rangeName: '',
  range: null,
};

const CustomRanges: FC<TCustomRanges> = ({ pageType }) => {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const xAxis = useSelector(scatterplotsSelectors.selectXAxis());
  const yAxis = useSelector(scatterplotsSelectors.selectYAxis());
  const objectType = useSelector(chartSettingsSelectors.selectObjectType(chartId));

  const customRangesMap = useSelector(chartSettingsSelectors.selectCustomRangesMap(chartId));
  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));
  const axesScaleTypes = useSelector(chartSettingsSelectors.selectCurrentScalesTypeForAxes(chartId));
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const selectedCustomRangeFromStore = useSelector(chartSettingsSelectors.selectSelectedCustomRange(chartId));
  const plotRange = useSelector(chartSettingsSelectors.selectPlotRangeFactory(chartId)());
  const lastPlotRange = useSelector(chartSettingsSelectors.selectLastPlotRange(chartId));
  const currentHistogramDataGroupType = useSelector(
    histogramSettingsSelectors.selectCurrentHistogramDataGroupType(chartId)
  );

  const debounceNewRangeData = useDebounce(lastPlotRange, 150);

  const filteredCustomRanges = useMemo<TCustomRange[]>(() => {
    const filtered = Object.values(customRangesMap).filter((customRange) => {
      const isSameAxes = customRange.xAxis === xAxis && customRange.yAxis === yAxis;
      const isSameChartType = customRange.currentChartType === currentChartType;

      const isSameXScaleType = customRange.axesScaleTypes?.x === axesScaleTypes.xAxisScaleType;
      const isSameYScaleType = customRange.axesScaleTypes?.y === axesScaleTypes.yAxisScaleType;
      const isSameScaleType = isSameXScaleType && isSameYScaleType;

      const isSameObjectEntity = customRange.isObjectEntityEnabled === isObjectEntityEnabled;
      const isSameHistogramDataGroupType = customRange.currentHistogramDataGroupType === currentHistogramDataGroupType;

      return isSameAxes && isSameChartType && isSameScaleType && isSameObjectEntity && isSameHistogramDataGroupType;
    });

    return filtered;
  }, [
    customRangesMap,
    xAxis,
    yAxis,
    currentChartType,
    axesScaleTypes.xAxisScaleType,
    axesScaleTypes.yAxisScaleType,
    isObjectEntityEnabled,
    currentHistogramDataGroupType,
  ]);

  const selectedCustomRange = useMemo(
    () => selectedCustomRangeFromStore ?? defaultRange,
    [selectedCustomRangeFromStore]
  );

  const isDefaultRange = useMemo(
    () => selectedCustomRange?.rangeName === defaultRange.rangeName,
    [selectedCustomRange, defaultRange]
  );

  const { isSettingsChangingConfirmed } = useSettingsNotification({ pageType });

  const setDefaultRange = useCallback(async () => {
    const isConfirmed = await isSettingsChangingConfirmed();

    if (!isConfirmed) {
      return;
    }

    appDispatch(chartSettingsActions.setCustomRangeName(defaultRange.rangeName));

    if (!plotRange) return;
    appDispatch(
      chartSettingsActions.updateCustomPlotRange({
        rangeName: filteredCustomRanges[0].rangeName,
        updatedData: { range: { ...plotRange } },
      })
    );
  }, [filteredCustomRanges, plotRange]);

  useEffect(() => {
    if (filteredCustomRanges.length) {
      return;
    }

    const rangeName = [
      xAxis,
      yAxis,
      currentChartType,
      axesScaleTypes.xAxisScaleType,
      axesScaleTypes.yAxisScaleType,
      isObjectEntityEnabled,
      objectType,
      currentHistogramDataGroupType,
    ].join('_');

    appDispatch(
      chartSettingsActions.addCustomPlotRange({
        customRange: {
          label: 'Custom range',
          range: debounceNewRangeData,
          rangeName,
          xAxis,
          yAxis,
          currentChartType,
          axesScaleTypes: {
            x: axesScaleTypes.xAxisScaleType,
            y: axesScaleTypes.yAxisScaleType,
          },
          isObjectEntityEnabled,
          currentHistogramDataGroupType,
          isEditable: true,
        },
        isUnique: true,
      })
    );
  }, [debounceNewRangeData]);

  return (
    <Accordion
      defaultActive
      className={cnSettings('accordion')}
      theme="light"
      heading={
        <div className={cnSettings('accordion__header', 'section__head', 'section__head_no-gap')}>
          <span>Plot Range {selectedCustomRange && `(${selectedCustomRange.label})`}</span>
        </div>
      }
    >
      <div className={cn('ranges-buttons')}>
        <Button color={!isDefaultRange ? 'white' : 'yellow'} onClick={setDefaultRange}>
          Default
        </Button>
        {filteredCustomRanges[0] && (
          <SingleCustomRange
            rangeData={filteredCustomRanges[0]}
            isSelected={!isDefaultRange}
            isXLogScale={axesScaleTypes.xAxisScaleType === EAxesScaleType.log}
            isYLogScale={axesScaleTypes.yAxisScaleType === EAxesScaleType.log}
            generalPlotRange={plotRange}
            pageType={pageType}
          />
        )}
        {/* <CustomRangesPopover
            ranges={filteredCustomRanges}
            selectedRange={selectedCustomRange}
            isCustomRangeActive={!isDefaultRange}
          /> */}
      </div>
    </Accordion>
  );
};

export default memo(CustomRanges);
