import { FC } from 'react';
import classnames from 'classnames/bind';

import NoDataFound from '@/components/common/NoDataFound';
import { addTooltip, MDASH } from '@/helpers';

import styles from '../DetailsPopover.module.scss';

const cn = classnames.bind(styles);

type TDetailsAsArrayProps = {
  detail: Record<string, string>[];
};

const DetailsAsArray: FC<TDetailsAsArrayProps> = ({ detail }) => {
  if (!Array.isArray(detail) || !detail.length) {
    return <NoDataFound textData={MDASH} alignment="left" />;
  }

  return (
    <div className={cn('array-details')}>
      {detail.map((data) => (
        <div className={cn('array-details__item')} key={data.name}>
          <div className={cn('title')}>{data.name}</div>
          <span className={cn('value')} {...addTooltip(data.value, 'right')}>
            {data.value}
          </span>
        </div>
      ))}
    </div>
  );
};

export default DetailsAsArray;
