import { FC, useCallback, useEffect } from 'react';
import { LazyLog } from 'react-lazylog'; // See: https://mozilla-frontend-infra.github.io/react-lazylog/ | https://melloware.github.io/react-logviewer/
import { format } from 'date-fns';
import classnames from 'classnames/bind';

import useGraphqlQueryFullList from '@/hooks/graphql/useGraphqlQueryFullList';
import icons from '@/components/common/icons';

import * as queries from '@/graphql/queries';
import { JobRunLogEdge, ListJobRunLogsInput } from '@/graphql/API';

import { white, darkGray, red, green, yellow, blue } from 'ansicolor';
import CopyToClipboardButton from '@/components/common/CopyToClipboardButton';
import Button from '@/components/common/Button';
import { removeAnsiSymbols } from '@/helpers';

import styles from '../JobRunList.module.scss';

const cn = classnames.bind(styles);

type TJobRunLogsProps = {
  jobRunId: string;
  logsText: string;
  setJobRunLogsText: (jobRunId: string, text: string) => void;
  jobRunName: string;
};

const LOG_COLOR_FUNCTION = {
  default: white,
  error: red,
  success: green,
  warning: yellow,
  info: blue,
};

const LOG_SEARCH_INCLUSIONS = {
  error: ['[ERROR]', 'ERROR:', 'Execution halted', 'Error in'],
  success: ['Succeeded', 'Download done'],
  warning: ['[WARN]', 'WARN:', 'Warning:', '######', '!!!!!!'],
  info: ['[INFO]', 'Completed at:', 'Objects inside gates:', 'Duration', 'CPU hours', '=====', '------'],
} as const;

const getLogColorFunctionByMessage = (message: string) => {
  let result = LOG_COLOR_FUNCTION.default;

  Object.entries(LOG_SEARCH_INCLUSIONS).forEach(([key, inclusions]) => {
    if (inclusions.find((inclusion) => message.includes(inclusion))) {
      result = LOG_COLOR_FUNCTION[key as keyof typeof LOG_COLOR_FUNCTION];
    }
  });

  return result;
};

const JobRunLogs: FC<TJobRunLogsProps> = ({ jobRunId, jobRunName, logsText, setJobRunLogsText }) => {
  const {
    edgeList: logEdgeList,
    isError,
    error,
    isLoading,
  } = useGraphqlQueryFullList<ListJobRunLogsInput, JobRunLogEdge>({
    query: queries.jobRunLogs,
    dataName: 'jobRunLogs',
    skip: logsText !== 'Loading...',
    input: {
      jobRunId,
    },
  });

  const exportLogs = useCallback(() => {
    const fileData = removeAnsiSymbols(logsText);
    const blob = new Blob([fileData], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.download = `${jobRunName}-job-logs.txt`;
    link.href = url;
    link.click();
  }, [logsText, jobRunName]);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isError) {
      setJobRunLogsText(jobRunId, `An error occurred during fetching the logs: ${error}`);
      return;
    }
    if (logEdgeList.length === 0) {
      setJobRunLogsText(jobRunId, 'No logs found');
      return;
    }

    let prevLogTimestamp = '';

    const text = logEdgeList
      .map((logData) => {
        const timestamp = logData.node.timestamp ?? '';
        const message = logData.node.message ?? '';

        let dateInfo = `[${format(new Date(+timestamp), 'MMM d, hh:mm:ss aaa')}]`;
        if (prevLogTimestamp === timestamp) {
          dateInfo = ' '.repeat(dateInfo.length);
        }

        const colorFunction = getLogColorFunctionByMessage(message);

        prevLogTimestamp = timestamp;

        return `${darkGray(dateInfo)} ${colorFunction(message)}`;
      })
      .join('\n');

    setJobRunLogsText(jobRunId, text);
  }, [isLoading, isError, error, logEdgeList]);

  return (
    <div className={cn('logs')}>
      <div className={cn('buttons')}>
        <CopyToClipboardButton content={logsText} className={cn('buttons__item')} disabled={isLoading} />
        <Button isFitContent color="light" onClick={exportLogs} className={cn('buttons__item')} disabled={isLoading}>
          <icons.DownloadIcon />
        </Button>
      </div>
      <LazyLog text={logsText} follow selectableLines enableSearch caseInsensitive extraLines={1} height="500" />
    </div>
  );
};

export default JobRunLogs;
