import { SVGProps } from 'react';

const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M12.2095 7.27898C11.5907 7.27898 11.1189 6.81492 11.1189 6.19617C11.1189 5.58516 11.5907 5.12109 12.2095 5.12109C12.8282 5.12109 13.3 5.58516 13.3 6.19617C13.3 6.81492 12.8282 7.27898 12.2095 7.27898ZM15.7286 17.7281H8.77539V16.6376H11.6526V10.4269H8.79859V9.33633H12.952V16.6376H15.7286V17.7281Z"
      fill="currentColor"
    />
  </svg>
);

export default InfoIcon;
