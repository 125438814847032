import { DEFAULT_TITLE_FONT_LAYOUT } from '@/components/charts/DownloadChartButton/prepareLayouts';

export const GRAPHIC_HEIGHT = 600;
export const GRAPHIC_XAXES_TITLE = 'Flow Cell Lane';
export const GRAPHIC_YAXES_TITLE = 'Number of cells';

export const STATISTICS_CHART_CONFIG = {
  responsive: true,
  displayModeBar: false,
};

export const STATISTICS_CHART_LAYOUT = {
  autosize: true,
  height: GRAPHIC_HEIGHT,
  xaxis: {
    autorange: true,
    automargin: true,
    title: {
      text: GRAPHIC_XAXES_TITLE,
      font: {
        ...DEFAULT_TITLE_FONT_LAYOUT,
        size: 17,
      },
    },

    rangemode: 'tozero',
  },
  yaxis: {
    autorange: true,
    automargin: true,
    title: {
      text: GRAPHIC_YAXES_TITLE,
      color: '#000000',
      font: {
        ...DEFAULT_TITLE_FONT_LAYOUT,
        size: 17,
      },
    },
    rangemode: 'tozero',
  },
};
