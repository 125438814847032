import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames/bind';

import NoDataFound from '@/components/common/NoDataFound';
import DownloadChartButton from '@/components/charts/DownloadChartButton';
import ChartSettingsButton from '@/components/charts/ChartSettingsButton';
import AxisXSelect from '@/components/charts/AxisXSelect';
import type { TDatasetChart } from '@/components/charts/SingleChartWithGates/types';
import AxisYSelect from '@/components/charts/AxisYSelect';
import RangeResetButton from '@/components/charts/RangeResetButton';
import { getOption } from '@/components/common/Select/helpers';

import { handlePlotlyResize } from '@/helpers/plotly';
import { getDatasetFriendlyName } from '@/helpers/datasetDetails';
import { getHistogramsYAxisLabel, getLineHistogramCoordinates } from '@/helpers/charts/lineHistogram';
import axisScaleHelper from '@/helpers/axisScaleHelper';
import { getMax } from '@/helpers/arrays';
import { formatRangeObj } from '@/helpers/charts/ranges';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';

import { withDefaultChartSettings } from '@/hoc/withDefaultChartSettings';
import { useChartRangeHandling, useDebounce, usePlotGateAndAxesUpdate, usePlotSettings } from '@/hooks';
import { getChartsLayoutConfig } from '@/hooks/datasetAnalysis/helpers';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useHistogramPlotsSettings } from '@/hooks/plotSettings/useHistogramPlotsSettings';
import usePrimaryAxisOptionListForMultiDatasets from '@/hooks/charts/usePrimaryAxisOptionListForMultiDatasets';
import usePlotProxy from '@/hooks/usePlotProxy';

import { CONFIG, DRAGMODES } from '@/pages/Dataset/constants';
import { chartDataSelectors, TChartEntityListData } from '@/store/slices/chartData';
import { gatesActions, gatesSelectors } from '@/store/slices/gates';
import { scatterplotsSelectors } from '@/store/slices/scatterplots';
import { histogramSettingsSelectors } from '@/store/slices/histogramSettings';
import { chartSettingsActions, chartSettingsSelectors } from '@/store/slices/chartSettings';
import { CUSTOM_PLOT_RANGE_DIGITS_AFTER_DECIMAL_POINT } from '@/components/charts/ChartSettingsButton/SettingsPopover/constants';
import { toFixed } from '@/helpers';
import { useGatesOnPlot } from '@/hooks/gates/useGatesOnPlot';

import { EPageWithChartType, EModebarTools, EChartType } from '@/types/charts';
import { getCorrectDimension, getDataConfigList, getCoordinatesByAxesAndGate } from '@/helpers/charts/chartsData';
import { experimentSelectors } from '@/store/slices/experiment';
import { useGatesLabelSettings } from '@/hooks/gates/useGatesLabelSettings';

import '@/styles/gates.scss';

import styles from './MultiHistogram.module.scss';

const cn = classnames.bind(styles);

type TMultiHistogramProps = TDatasetChart;

const LEFT_PLOT_MARGIN = 60;

const MultiHistogram: FC<TMultiHistogramProps> = ({
  isTransitionEnd,
  entityLevelGateList,
  isError,
  chartDataList = [],
  scatterPlotAxesOptions = [],
  dimensionsMapping,
  scanList,
  plotRangeName,
  entitiesByLanesAndGates,
  fullGateList,
  scanId,
  laneId,
}) => {
  const graphRef = useRef<Nullable<IPlotlyHTMLDivElement>>(null);
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const plotlyProxy = usePlotProxy(graphRef.current?.id ?? '');
  const experimentName = useSelector(experimentSelectors.selectCurrentExperimentName);
  const currentChartType = useSelector(chartSettingsSelectors.selectCurrentChartType(chartId));
  const currentColorScale = useSelector(chartSettingsSelectors.selectCurrentColorScale(chartId));
  const selectedGate = useSelector(gatesSelectors.selectSelectedGate);
  const xAxis = useSelector(scatterplotsSelectors.selectXAxis());
  const activeGate = useSelector(gatesSelectors.selectActiveGate);
  const currentChartData = useSelector(chartDataSelectors.selectCurrentChartData) ?? chartDataList[0];
  const chartLane = useSelector(experimentSelectors.selectLane(currentChartData.scanId, currentChartData.laneId));
  const plotRange = useSelector(chartSettingsSelectors.selectPlotRangeFactory(chartId)(plotRangeName));
  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const isTickLabelsVisible = useSelector(chartSettingsSelectors.selectIsTickLabelsVisible(chartId));

  const isChartFillEnabled = useSelector(histogramSettingsSelectors.selectIsChartFillEnabled(chartId));
  const isStackedAndFilledEnabled = useSelector(histogramSettingsSelectors.selectIsStackedAndFilledEnabled);
  const isStackedChartsChecked = useSelector(histogramSettingsSelectors.selectIsStackedChartsChecked);
  const customHistogramBinsAmount = useSelector(histogramSettingsSelectors.selectCustomHistogramBinsAmount(chartId));
  const currentHistogramDataGroupType = useSelector(
    histogramSettingsSelectors.selectCurrentHistogramDataGroupType(chartId)
  );
  const kernelBinsAmountMultiplier = useSelector(histogramSettingsSelectors.selectKernelBinsAmountMultiplier(chartId));
  const kernelBandwidthCoefficient = useSelector(histogramSettingsSelectors.selectKernelBandwidthCoefficient(chartId));

  const { xAxisScaleType, yAxisScaleType } = useSelector(
    chartSettingsSelectors.selectCurrentScalesTypeForAxes(chartId)
  );

  const [isPlotLoaded, setIsPlotLoaded] = useState(false);
  const [isReadyForFirstDraw, setIsReadyForFirstDraw] = useState(false);

  const primaryAxisOptionList = usePrimaryAxisOptionListForMultiDatasets({
    scanList,
    chartDataList,
  });

  const entitiesDataByGates = useMemo(
    () => chartLane && entitiesByLanesAndGates[chartLane.path],
    [chartLane, entitiesByLanesAndGates]
  );
  const filteredEntityLevelGateList = useMemo(
    () =>
      entityLevelGateList?.filter((gate: TGate) =>
        gate.properties?.processType ? gate?.laneId === laneId && gate?.scanId === scanId : true
      ) ?? [],
    [entityLevelGateList, scanId, laneId]
  );

  const xAxisLabel = useMemo(() => {
    if (!Array.isArray(scatterPlotAxesOptions)) return '';

    return getOption(scatterPlotAxesOptions, xAxis)?.label ?? '';
  }, [scatterPlotAxesOptions, xAxis]);

  const yAxisLabel = useMemo(() => {
    const titleByGroupType = getHistogramsYAxisLabel(currentHistogramDataGroupType);

    return titleByGroupType;
  }, [currentHistogramDataGroupType]);

  const multiChartEntityListData = useSelector(chartDataSelectors.selectMultiChartEntityListData);
  const debouncedMultiChartEntityListData = useDebounce(multiChartEntityListData, 50);
  const currentEntityListData = useMemo(() => {
    const entitiesDataList: TChartEntityListData[] = [];
    chartDataList.forEach((chartData) => {
      const entitiesData = debouncedMultiChartEntityListData[chartData.dataset.path];
      if (entitiesData) {
        entitiesDataList.push(entitiesData);
      }
    });
    return entitiesDataList;
  }, [chartDataList, debouncedMultiChartEntityListData]);

  const multiChartEntityList = useMemo(
    () => currentEntityListData.map((data) => data.data ?? []),
    [currentEntityListData]
  );

  const entityListError = useMemo(
    () => currentEntityListData.find((data) => data.isError)?.error,
    [currentEntityListData]
  );

  const extendedEntityListData = useMemo(
    () =>
      multiChartEntityList.map((chartEntityList, index) => ({
        cageList: chartEntityList,
        dataName: getDatasetFriendlyName(chartDataList[index]),
        datasetName: chartDataList[index].dataset.name,
      })),
    [multiChartEntityList, chartDataList]
  );

  const isMultiEntityListEmpty = useMemo(
    () => !multiChartEntityList.length || multiChartEntityList.every((list) => !list.length),
    [multiChartEntityList]
  );

  usePlotSettings({
    graphRef,
    isPlotLoaded,
    pageType: EPageWithChartType.multiHistogram,
  });

  useHistogramPlotsSettings({
    graphRef,
    defaultChartConfigs: { isTickLabelsVisible },
    isPlotLoaded,
    pageType: EPageWithChartType.multiHistogram,
    plotRangeName,
    dimensionsMapping,
  });

  const selectedTool = EModebarTools.zoom;

  const { axesRange, isCustomRangeUsed, resetRangeToDefault, handlePlotZoomed, setAxesRange } = useChartRangeHandling({
    openedAxisSettingsName: null,
    selectedTool,
    graphRef,
  });

  const debouncedTransitionEnd = useDebounce(isTransitionEnd, 100);
  const isAllPlotDataLoaded = useMemo(
    () => Boolean(isPlotLoaded && graphRef.current && debouncedTransitionEnd),
    [isPlotLoaded, graphRef.current, debouncedTransitionEnd]
  );

  const handleRangeResetClick = () => {
    resetRangeToDefault();
  };

  const usePlotGateAndAxesUpdateProps = useMemo(
    () => ({
      cageDataList: extendedEntityListData,
      graphRef,
      isAllPlotDataLoaded,
      entityLevelGateList,
      axesRange,
      setAxesRange,
      chartDataList,
      pageType: EPageWithChartType.multiHistogram,
    }),
    [graphRef, extendedEntityListData, isAllPlotDataLoaded, entityLevelGateList, axesRange, setAxesRange, chartDataList]
  );

  usePlotGateAndAxesUpdate(usePlotGateAndAxesUpdateProps);

  const fileName = useMemo(() => {
    const labels = chartDataList.map((chartDataItem) => chartDataItem?.name ?? '').join(', ');
    return `${experimentName} (${labels}) multi-histogram`;
  }, [experimentName, xAxis, chartDataList]);

  // create plot when all data received. Do not add other dependencies to this hook. This can cause Plotly configurations to overlap and the chart to display incorrectly.
  useEffect(() => {
    if (!graphRef.current || !xAxis || !isReadyForFirstDraw || isMultiEntityListEmpty) {
      return;
    }

    if (graphRef.current?.data?.length && graphRef.current?.layout) return;

    const dataConfigList = getDataConfigList({
      cageDataList: extendedEntityListData,
      xAxis,
      yAxis: xAxis, // Histograms don't need yAxis. If y data is invalid histogram won't be drawn and there is no way to change it with UI. That's why xAxis is passed to yAxis
      xAxisScaleType,
      yAxisScaleType,
      dimensionsMapping,
      currentChartType,
      currentColorScale,
      isChartFillEnabled,
      isStackedChartsChecked,
      isStackedAndFilledEnabled,
      customHistogramBinsAmount,
      currentHistogramDataGroupType,
      kernelBandwidthCoefficient,
      kernelBinsAmountMultiplier,
      pageType: EPageWithChartType.multiHistogram,
      entityLevelGateList,
      selectedGate,
    });

    const layoutConfig = getChartsLayoutConfig({
      dataCount: dataConfigList.length,
      currentChartType,
      xAxisScaleType,
      yAxisScaleType,
      isTickLabelsVisible,
      dragmode: DRAGMODES[selectedTool as EModebarTools],
      isStackedChartsChecked,
      isStackedAndFilledEnabled,
      layoutMarginsPreset: {
        l: LEFT_PLOT_MARGIN,
      },
      pageType: EPageWithChartType.multiHistogram,
      range: plotRange,
    });

    plotlyProxy.react(
      dataConfigList,
      layoutConfig,
      {
        ...CONFIG(),
        scrollZoom: EModebarTools.zoom === selectedTool,
      },
      (plot: IPlotlyHTMLDivElement) => {
        defineGatesLayoutParameters();
        setIsPlotLoaded(true);
        const {
          xaxis: { range: xRange },
          yaxis: { range: yRange },
        } = plot.layout;

        if (!xRange || !yRange) return;

        setAxesRange?.({ x: xRange, y: yRange });
      }
    );
  }, [xAxis, isReadyForFirstDraw, plotlyProxy.id]);

  const memoData = useMemo(
    () => ({
      isPlotLoaded,
      currentHistogramDataGroupType,
      kernelBinsAmountMultiplier,
      kernelBandwidthCoefficient,
      isStackedChartsChecked,
      xAxisScaleType,
      plotRange,
      multiChartEntityList,
      xAxis,
    }),
    [
      isPlotLoaded,
      currentHistogramDataGroupType,
      kernelBinsAmountMultiplier,
      isStackedChartsChecked,
      xAxisScaleType,
      kernelBandwidthCoefficient,
      plotRange,
      multiChartEntityList,
      xAxis,
    ]
  );

  const debounceData = useDebounce(memoData, 150);

  // TODO: temp solution. There should be one hook in which all range calculations and settings will take place.
  // We need to refactor the logic of the useChartSideEffects hook. To apply work multiple entity lists
  useEffect(() => {
    if (isMultiEntityListEmpty || currentChartType === EChartType.histogram) return;

    const histogramCoordinates = extendedEntityListData.map(({ cageList, datasetName }) => {
      const { coordinates } = getCoordinatesByAxesAndGate({
        entityList: cageList,
        xAxis: getCorrectDimension(xAxis, datasetName, dimensionsMapping),
        yAxis: getCorrectDimension(xAxis, datasetName, dimensionsMapping), // Histograms don't need yAxis. If y data is invalid histogram won't be drawn and there is no way to change it with UI. That's why xAxis is passed to yAxis
        gate: selectedGate,
        entityLevelGateList,
      });

      return getLineHistogramCoordinates({
        coords: coordinates,
        customBinsAmount: customHistogramBinsAmount,
        currentHistogramDataGroupType,
        xAxisScaleType,
        kernelBandwidthCoefficient,
        kernelBinsAmountMultiplier,
      });
    });

    const range = histogramCoordinates.reduce(
      (acc, coordinates, index) => {
        const xData = coordinates.x;
        const isRangeForFirstDataset = index === 0;

        let yMax = getMax(coordinates.y);
        yMax += yMax * 0.05;

        const yData = [0, yMax];

        const calculatedRange = axisScaleHelper.getPlotRange({
          xAxisScaleType,
          yAxisScaleType,
          x: xData,
          y: yData,
          withGap: false,
        });

        if (!calculatedRange) return acc;

        if (isRangeForFirstDataset) {
          return calculatedRange;
        }

        acc.xMin = toFixed(Math.min(calculatedRange.xMin, acc.xMin), CUSTOM_PLOT_RANGE_DIGITS_AFTER_DECIMAL_POINT);
        acc.xMax = toFixed(Math.max(calculatedRange.xMax, acc.xMax), CUSTOM_PLOT_RANGE_DIGITS_AFTER_DECIMAL_POINT);
        acc.yMin = toFixed(Math.min(calculatedRange.yMin, acc.yMin), CUSTOM_PLOT_RANGE_DIGITS_AFTER_DECIMAL_POINT);
        acc.yMax = toFixed(Math.max(calculatedRange.yMax, acc.yMax), CUSTOM_PLOT_RANGE_DIGITS_AFTER_DECIMAL_POINT);

        return acc;
      },
      { xMin: 0, xMax: 0, yMin: 0, yMax: 0 }
    );

    appDispatch(chartSettingsActions.setPlotRange({ range, rangeName: plotRangeName }));
  }, [
    debounceData.isPlotLoaded,
    debounceData.currentHistogramDataGroupType,
    debounceData.kernelBinsAmountMultiplier,
    debounceData.kernelBandwidthCoefficient,
    debounceData.isStackedChartsChecked,
    debounceData.xAxisScaleType,
    multiChartEntityList,
    xAxis,
  ]);

  useEffect(() => {
    if (debounceData.plotRange && !isMultiEntityListEmpty) {
      setIsReadyForFirstDraw(true);
    } else {
      setIsReadyForFirstDraw(false);
    }
  }, [debounceData.plotRange, isMultiEntityListEmpty]);

  useEffect(() => {
    appDispatch(chartSettingsActions.setCustomRangeName(''));
  }, [currentHistogramDataGroupType]);

  useEffect(() => {
    if (!isPlotLoaded || !graphRef.current?.data || !graphRef.current?.layout) return;
    const dataList = getDataConfigList({
      cageDataList: extendedEntityListData,
      xAxis,
      yAxis: xAxis, // Histograms don't need yAxis. If y data is invalid histogram won't be drawn and there is no way to change it with UI. That's why xAxis is passed to yAxis
      xAxisScaleType,
      yAxisScaleType,
      dimensionsMapping,
      currentChartType,
      currentColorScale,
      isChartFillEnabled,
      isStackedChartsChecked,
      isStackedAndFilledEnabled,
      customHistogramBinsAmount,
      currentHistogramDataGroupType,
      kernelBandwidthCoefficient,
      kernelBinsAmountMultiplier,
      pageType: EPageWithChartType.multiHistogram,
      entityLevelGateList,
      selectedGate,
    });

    const dataToUpdate = {
      customdata: dataList.map(({ customdata }) => customdata),
      x: dataList.map(({ x }) => x),
      y: dataList.map(({ y }) => y),
      nbinsx: currentChartType === EChartType.histogram ? dataList.map(({ nbinsx }) => nbinsx) : undefined,
    };

    plotlyProxy.forceRestyle(dataToUpdate, undefined, () => defineGatesLayoutParameters());
  }, [extendedEntityListData]);

  useEffect(() => {
    const xAxisScaleTypePart = axisScaleHelper.getPlotLayoutTypePart(xAxisScaleType);
    const yAxisScaleTypePart = axisScaleHelper.getPlotLayoutTypePart(yAxisScaleType);

    const update = {
      'xaxis.type': xAxisScaleTypePart.type,
      'xaxis.exponentformat': xAxisScaleTypePart.exponentformat,
      'yaxis.type': yAxisScaleTypePart.type,
      'yaxis.exponentformat': yAxisScaleTypePart.exponentformat,
    };

    plotlyProxy.forceRelayout(update, () => defineGatesLayoutParameters());
  }, [xAxisScaleType, yAxisScaleType]);

  useEffect(() => {
    window.addEventListener('resize', handlePlotlyResize);

    return () => {
      window.removeEventListener('resize', handlePlotlyResize);
    };
  }, []);

  useEffect(() => {
    if (!graphRef.current?.data?.length) {
      return;
    }

    const graphDiv = graphRef.current;

    graphRef.current.on('plotly_relayouting', (eventData: TPlotRelayoutEvent) =>
      handlePlotZoomed(eventData, 'plotly_relayouting')
    ); // event for zoom via scroll
    graphRef.current.on('plotly_relayout', (eventData: TPlotRelayoutEvent) =>
      handlePlotZoomed(eventData, 'plotly_relayout')
    ); // event for zoom via selection
    graphRef.current.on('plotly_doubleclick', resetRangeToDefault);

    return () => {
      if (!graphDiv.removeAllListeners) {
        return;
      }
      graphDiv.removeAllListeners('plotly_relayouting');
      graphDiv.removeAllListeners('plotly_relayout');
      graphDiv.removeAllListeners('plotly_doubleclick');
    };
  }, [entityLevelGateList, activeGate, selectedGate, handlePlotZoomed, isAllPlotDataLoaded]);

  useEffect(() => {
    if (
      !isPlotLoaded ||
      !graphRef.current?.data ||
      graphRef.current.data.length === 0 ||
      !graphRef.current.layout ||
      currentChartType === EChartType.heatmap
    ) {
      return;
    }

    const dataConfigList = getDataConfigList({
      cageDataList: extendedEntityListData,
      xAxis,
      yAxis: xAxis, // Histograms don't need yAxis. If y data is invalid histogram won't be drawn and there is no way to change it with UI. That's why xAxis is passed to yAxis
      xAxisScaleType,
      yAxisScaleType,
      dimensionsMapping,
      currentChartType,
      currentColorScale,
      isChartFillEnabled,
      isStackedChartsChecked,
      isStackedAndFilledEnabled: isStackedAndFilledEnabled && isStackedChartsChecked,
      currentHistogramDataGroupType,
      customHistogramBinsAmount,
      kernelBinsAmountMultiplier,
      kernelBandwidthCoefficient,
      pageType: EPageWithChartType.multiHistogram,
      entityLevelGateList,
      selectedGate,
    });

    const layoutConfig = getChartsLayoutConfig({
      dataCount: dataConfigList.length,
      currentChartType,
      xAxisScaleType,
      yAxisScaleType,
      isTickLabelsVisible: true,
      dragmode: true,
      isStackedChartsChecked,
      isStackedAndFilledEnabled,
      layoutMarginsPreset: {
        l: LEFT_PLOT_MARGIN,
      },
      pageType: EPageWithChartType.multiHistogram,
      range: plotRange,
    });

    plotlyProxy.forceReact(dataConfigList, layoutConfig, null, (plot: IPlotlyHTMLDivElement) => {
      const { xaxis, yaxis } = plot.layout;

      if ('range' in xaxis && 'range' in yaxis && currentChartType === EChartType.histogram) {
        appDispatch(
          chartSettingsActions.setPlotRange({
            range: formatRangeObj({
              x: xaxis.range,
              y: yaxis.range,
            }),
            rangeName: plotRangeName,
          })
        );
      }

      defineGatesLayoutParameters();
    });
  }, [currentChartType, xAxisScaleType, isPlotLoaded]);

  const { defineGatesLayoutParameters, overflowContainer } = useGatesOnPlot({
    graphRef,
    scanId: currentChartData.scanId,
    laneId: currentChartData.laneId,
    selectedTool: null,
    entitiesDataByGates,
    plotId: 'plot-chart',
    isStatic: true,
    chartClassName: '.bg',
    isAllPlotDataLoaded,
    axesRange,
    entityLevelGateList: filteredEntityLevelGateList,
    isObjectEntityEnabled,
    gates: fullGateList as TGate[],
    updateGate: () => null,
    createGate: () => null,
    chartXAxis: xAxis,
    chartYAxis: '',
    isEmptyData: isMultiEntityListEmpty,
    defaultPlotRange: plotRange,
  });

  useGatesLabelSettings({ entitiesDataByGates });
  useEffect(() => {
    appDispatch(gatesActions.setIsGateDrawn(!isStackedChartsChecked));
  }, [isStackedChartsChecked]);

  return (
    <div
      className={cn('chart')}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      {!isError
        ? !isAllPlotDataLoaded && !isMultiEntityListEmpty && <Skeleton className={cn('skeleton')} />
        : !isAllPlotDataLoaded &&
          !entityListError && <NoDataFound className={cn('chart__no-data')} alignment="center" size="normal" />}
      {isAllPlotDataLoaded && (
        <div className={cn('chart__controls')}>
          <div className={cn('chart__modebar', 'modebar')}>
            <ChartSettingsButton pageType={EPageWithChartType.multiHistogram} />
            <DownloadChartButton
              graphRef={graphRef}
              fileName={fileName}
              xAxisLabel={xAxisLabel}
              overflowContainer={overflowContainer}
            />
            <RangeResetButton onClick={handleRangeResetClick} disabled={!isCustomRangeUsed} />
          </div>
        </div>
      )}
      <div className={cn('plot')}>
        {isAllPlotDataLoaded && (
          <>
            <AxisYSelect
              scatterPlotAxesOptions={scatterPlotAxesOptions}
              onlyShowValue
              primaryOptionList={primaryAxisOptionList}
              customLabel={yAxisLabel}
              className={cn('plot__y-axis-label')}
            />
            <AxisXSelect
              scatterPlotAxesOptions={scatterPlotAxesOptions}
              primaryOptionList={primaryAxisOptionList}
              className={cn('plot__x-axis-label')}
            />
          </>
        )}
        <div className={cn('plot__container')}>
          <div ref={graphRef} id="plot-chart" className={cn('plot-chart')}>
            {isMultiEntityListEmpty && (
              <NoDataFound
                className={cn('chart__no-data')}
                alignment="center"
                size="small"
                textData={
                  entityListError ?? (
                    <span>
                      No data matching the current settings. <br /> Please select a different gate or change the axes
                    </span>
                  )
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(withDefaultChartSettings(MultiHistogram, EPageWithChartType.multiHistogram));
