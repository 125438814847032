export const getProjectOwnerData = (ownedBy?: { id: string; name: string; email: string }) => {
  if (!ownedBy) return '';

  if (ownedBy.name.trim()) {
    return ownedBy.name;
  }

  if (ownedBy.email.trim()) {
    return ownedBy.email;
  }

  return '';
};
