import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import 'react-datepicker/dist/react-datepicker.css';
import { endOfDay, startOfDay, sub } from 'date-fns';
import { useApolloClient, useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { select as d3Select } from 'd3-selection';
import { drag as d3Drag } from 'd3-drag';

import * as queries from '@/graphql/queries';

import Button from '@/components/common/Button';
import icons from '@/components/common/icons';

import { handlePlotlyResize } from '@/helpers/plotly';
import { throttle, sortData } from '@/helpers';

import useParamsInstrumentId from '@/hooks/useParamsInstrumentId';
import useFlipBlock from '@/hooks/charts/useFlipBlock';

import instrumentDashboardStyles from '../../InstrumentDashboard.module.scss';
import styles from './HistoricalSensors.module.scss';

import DateRange from '../DateRange';
import InstrumentCard from '../InstrumentCard';
import SensorListItem from '../SensorListItem';
import SensorByTelemetryTimeline from '../SensorByTelemetryTimeline';
import { fetchAllDataWithPagination, onDrag } from './helpers';
import { TSensorsDataForTimeline, TSensorTelemetryPayload } from './types';
import DownloadTelemetryButton from '../DownloadTelemetryButton';

const cn = classnames.bind({ ...instrumentDashboardStyles, ...styles });

const currentDate = endOfDay(new Date());
const defaultStartDate = startOfDay(sub(currentDate, { days: 7 }));

const DEFAULT_SENSOR_NAME = 'Chiller.CurrentTemp2';

const HistoricalSensors: FC = () => {
  const instrumentId = useParamsInstrumentId();
  const [searchParams, setSearchParams] = useSearchParams();
  const instrumentName = searchParams.get('name') ?? null;

  const [isPlotReadyToDisplay, setIsPlotReadyToDisplay] = useState(false);
  const [selectedSensorValues, setSelectedSensorValues] = useState<string[]>([]);
  const [sensorsTimelineData, setSensorsTimelineData] = useState<Nullable<TTelemetryBySensorFromServer[][]>>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSensorTimelineDataError, setIsSensorTimelineDataError] = useState<boolean>(false);

  const preselectedStartDate = searchParams.get('startDate') ?? null;
  const preselectedEndDate = searchParams.get('endDate') ?? null;

  const [startDate, setStartDate] = useState<Date>(
    preselectedStartDate ? new Date(preselectedStartDate) : defaultStartDate
  );
  const [endDate, setEndDate] = useState<Date>(preselectedEndDate ? new Date(preselectedEndDate) : currentDate);

  const prevDateValuesRef = useRef<Nullable<[Date, Date]>>([startDate, endDate]);

  const throttledSetStartDate = throttle((value: Date) => {
    setStartDate(value);
  }, 300);
  const throttledSetEndDate = throttle((value: Date) => {
    setEndDate(value);
  }, 300);

  const client = useApolloClient();

  const csvFileName = useMemo(() => {
    const sensors = selectedSensorValues.join('_');

    return `${instrumentName ?? ''} ${sensors}.csv`;
  }, [instrumentName, selectedSensorValues]);

  const setSearchParamItem = (searchParamName: string, searchParamValue: string) => {
    if (searchParamValue) {
      searchParams.set(searchParamName, searchParamValue);
    } else {
      searchParams.delete(searchParamName);
    }
    setSearchParams(searchParams);
  };

  const fetchSensorsTelemetry = useCallback(
    async (updatedPayload?: Partial<TSensorTelemetryPayload>) => {
      const variablesArray = selectedSensorValues.map((name) => ({
        instrumentId,
        name,
        endDate: endDate?.toISOString() ?? null,
        startDate: startDate?.toISOString() ?? null,
        limit: 100,
        startToken: null,
      }));

      if (!variablesArray.length) return;

      const promises = variablesArray.map((variables) =>
        fetchAllDataWithPagination<TTelemetryBySensorFromServer>(
          queries.telemetryBySensor,
          { ...variables, ...updatedPayload },
          client,
          (res) => res?.telemetryBySensor
        )
      );

      try {
        setIsLoading(true);
        const results = await Promise.all(promises);
        setSensorsTimelineData(results);
        setIsLoading(false);
      } catch (error) {
        setIsSensorTimelineDataError(true);
      }
    },
    [selectedSensorValues, instrumentId, endDate, startDate]
  );

  const onZoomChange = useCallback(
    (minXRange: Nullable<Date>, maxXRange: Nullable<Date>) => {
      const newMinX = minXRange ?? defaultStartDate;
      const newMaxX = maxXRange ?? currentDate;

      throttledSetStartDate(newMinX);
      throttledSetEndDate(newMaxX);
    },
    [startDate, endDate]
  );

  const onChangeStartDate = useCallback(
    (date: Nullable<Date>) => {
      if (!date) return;
      setSearchParamItem('startDate', date.toISOString());
      setStartDate(date);
      prevDateValuesRef.current = [date, endDate ?? currentDate];
      fetchSensorsTelemetry({ startDate: date });
    },
    [endDate, fetchSensorsTelemetry]
  );

  const onChangeEndDate = useCallback(
    (date: Nullable<Date>) => {
      if (!date) return;
      setSearchParamItem('endDate', date.toISOString());
      setEndDate(date);
      prevDateValuesRef.current = [startDate ?? defaultStartDate, date];
      fetchSensorsTelemetry({ endDate: date });
    },
    [startDate, fetchSensorsTelemetry]
  );

  const changeCurrentSensor = (value: TTelemetryByInstrument) => {
    const isSelected = selectedSensorValues.find((sensorName) => sensorName === value.name);

    if (isSelected) {
      setSelectedSensorValues((prev) => prev.filter((sensorName) => sensorName !== value.name));
      return;
    }

    setSelectedSensorValues([value.name]);

    setIsPlotReadyToDisplay(false);
  };

  const changeCurrentSensorFactory = (value: TTelemetryByInstrument) => () => changeCurrentSensor(value);

  const {
    data,
    loading: isInstrumentTelemetryLoading,
    error: isInstrumentTelemetryError,
  } = useQuery<{
    telemetryByInstrument: TTelemetryByInstrument[];
  }>(queries.telemetryByInstrument, {
    variables: {
      instrumentId,
    },
  });

  const isEmptySensorsData = useMemo(
    () => !isInstrumentTelemetryLoading && !isInstrumentTelemetryError && !data?.telemetryByInstrument?.length,
    [isInstrumentTelemetryLoading, isInstrumentTelemetryError, data?.telemetryByInstrument]
  );

  const {
    flipBlockRef,
    flipBlockClassName,
    flipBlockBackgroundClassName,
    toggleFullScreen,
    isExpandMode,
    isTransitionEnd,
  } = useFlipBlock(() => {
    const contentWrapper = d3Select('#content');
    contentWrapper.style('grid-template-rows', '2fr 1fr');
    handlePlotlyResize();
  }, cn('card_full-screen'));

  useEffect(
    () => () => {
      searchParams.delete('startDate');
      searchParams.delete('endDate');
    },
    []
  );

  const handleRectangleDrag = d3Drag<Element, unknown>()
    .on('start', null)
    .on('drag', (event) => {
      onDrag(event);
    })
    .on('end', null);

  const handleSelectedSensorsChange = (newValues: string[]) => {
    setSelectedSensorValues(newValues);
  };

  useEffect(() => {
    if (!selectedSensorValues.length) return;
    const shapesContainer = d3Select(`#handler`).node() as Element;
    d3Select(shapesContainer).call(handleRectangleDrag);

    return () => {
      const Container = d3Select(`#handler`).node() as Element;

      d3Select(Container).on('.drag', null);
    };
  }, [selectedSensorValues.length]);

  useEffect(() => {
    if (isInstrumentTelemetryLoading || isInstrumentTelemetryError) return;
    const defaultSensorData =
      data?.telemetryByInstrument?.find((instrumentSensor) => instrumentSensor.name === DEFAULT_SENSOR_NAME) ??
      data?.telemetryByInstrument?.[0];

    if (!defaultSensorData) return;

    handleSelectedSensorsChange([defaultSensorData.name]);
  }, [data, isInstrumentTelemetryLoading, isInstrumentTelemetryError]);

  useEffect(() => {
    fetchSensorsTelemetry();
  }, [selectedSensorValues]);

  const sortedSensorsData = useMemo(() => {
    if (!data?.telemetryByInstrument) {
      return [];
    }

    return data.telemetryByInstrument.toSorted((current: TTelemetryByInstrument, next: TTelemetryByInstrument) => {
      if (current.sequence === null) {
        return 1;
      }

      if (next.sequence === null) {
        return -1;
      }
      return sortData(current.sequence, next.sequence);
    });
  }, [data?.telemetryByInstrument]);

  const selectedSensorsData = useMemo(() => {
    const selectedSensors: Record<string, TSensorsDataForTimeline> = {};
    data?.telemetryByInstrument.forEach((sensorData: TTelemetryByInstrument) => {
      if (selectedSensorValues.includes(sensorData.name)) {
        selectedSensors[sensorData.name] = {
          sensor: sensorData,
          timelineData: sensorsTimelineData?.find((timelineData) => timelineData[0].name === sensorData.name) ?? [],
        };
      }
    });

    return selectedSensors;
  }, [data?.telemetryByInstrument, selectedSensorValues, sensorsTimelineData]);

  return (
    <>
      <div onClick={toggleFullScreen} role="presentation" className={flipBlockBackgroundClassName} />
      <InstrumentCard
        className={cn('content-block', 'card', flipBlockClassName, {
          'card_full-screen': isExpandMode,
          'card_transition-end': !isExpandMode && isTransitionEnd,
        })}
        innerRef={flipBlockRef}
      >
        <InstrumentCard.Header title="Historical sensor readings">
          {!isInstrumentTelemetryLoading && !isInstrumentTelemetryError && !isEmptySensorsData && (
            <InstrumentCard.HeaderRightActions>
              <DateRange
                startDate={startDate}
                onChangeStartDate={onChangeStartDate}
                endDate={endDate}
                onChangeEndDate={onChangeEndDate}
                disabled={isLoading}
              />
              <DownloadTelemetryButton
                selectedSensorsTimelineData={sensorsTimelineData ?? []}
                disabled={isLoading}
                fileName={csvFileName}
              />
              {isTransitionEnd && !isExpandMode ? (
                <Button
                  tooltip="Full screen"
                  color="light"
                  className={cn('action-btn')}
                  onClick={toggleFullScreen}
                  disabled={isExpandMode}
                >
                  <icons.ExpandScreenIcon className={cn('action-btn__icon')} />
                </Button>
              ) : (
                <Button
                  color="light"
                  className={cn('action-btn', 'close-full-screen-button')}
                  disabled={!isExpandMode}
                  onClick={toggleFullScreen}
                >
                  <icons.CloseIcon className={cn('action-btn__icon', 'close-full-screen-button__icon')} />
                </Button>
              )}
            </InstrumentCard.HeaderRightActions>
          )}
        </InstrumentCard.Header>
        <InstrumentCard.Content
          isLoading={isInstrumentTelemetryLoading}
          isNoData={!!(isEmptySensorsData || isInstrumentTelemetryError)}
          noDataMessage={isInstrumentTelemetryError ? 'Something went wrong' : 'No data found'}
          className={cn('content', { content_long: !!selectedSensorValues.length })}
          id="content"
        >
          {!isTransitionEnd && <Skeleton className={cn('skeleton')} />}

          {!!selectedSensorValues.length && (
            <div id="timeline-wrapper" className={cn('timeline-wrapper')}>
              <SensorByTelemetryTimeline
                selectedSensorsData={selectedSensorsData}
                isPlotReadyToDisplay={isPlotReadyToDisplay}
                setIsPlotReadyToDisplay={setIsPlotReadyToDisplay}
                className={cn('timeline', { 'timeline_full-screen': isExpandMode })}
                isLoading={isLoading}
                isError={!!isSensorTimelineDataError}
                onZoomChange={onZoomChange}
                prevDateValuesRef={prevDateValuesRef}
                handleSelectedSensorsChange={handleSelectedSensorsChange}
                sensorList={data?.telemetryByInstrument ?? []}
                selectedSensorValues={selectedSensorValues}
                changeCurrentSensorFactory={changeCurrentSensorFactory}
              />
              <div className={cn('resize-handler')}>
                <button id="handler" className={cn('resize-handler__btn')} aria-label="resize">
                  <icons.DotsIcon className={cn('resize-handler__icon')} />
                </button>
              </div>
            </div>
          )}
          <div
            id="sensor-list"
            className={cn('list-block', { 'list-block_full-height': !!selectedSensorValues.length })}
          >
            <div className={cn('list-block__list')}>
              {sortedSensorsData?.map((telemetry: TTelemetryByInstrument) => (
                <SensorListItem
                  onClick={changeCurrentSensor}
                  key={telemetry.name}
                  telemetrySensor={telemetry}
                  isClickable
                  className={cn('list-block__item')}
                  selected={selectedSensorValues.includes(telemetry.name)}
                  disabled={isLoading}
                />
              ))}
            </div>
          </div>
        </InstrumentCard.Content>
      </InstrumentCard>
    </>
  );
};

export default memo(HistoricalSensors);
