import { FC, useMemo } from 'react';
import classnames from 'classnames/bind';

import { formatDate } from '@/helpers';

import icons from '@/components/common/icons';
import MemberList from '@/pages/ProjectList/MemberList';

import { formatHighlightSearchQuery } from '@/helpers/formatHighlightSearchQuery';
import Highlighter from 'react-highlight-words';
import { Link } from 'react-router-dom';
import ProjectOptionsPopoverWrapper from '@/pages/ProjectList/ProjectOptionsPopoverWrapper';
import { useProjectListContext } from '@/hooks/useProjectListContext';
import styles from './ProjectListItem.module.scss';
import { getProjectOwnerData } from '../helpers';

const cn = classnames.bind(styles);

type TProjectListItemProps = {
  project: TProject;
};

const ProjectListItem: FC<TProjectListItemProps> = ({ project }) => {
  const { name, dateCreated, dateModified, experimentCount, ownedBy, collaborators, id: projectId } = project;
  const { searchQuery } = useProjectListContext();

  const projectOwner = useMemo(() => getProjectOwnerData(ownedBy), [ownedBy]);

  return (
    <ProjectOptionsPopoverWrapper project={project}>
      <Link to={`/project/${projectId}`} className={cn('project-list-item')} id={projectId}>
        <div className={cn('project-list-item__column', 'project-list-item__column_title')}>
          <Highlighter textToHighlight={name} searchWords={formatHighlightSearchQuery(searchQuery)} />
        </div>
        <div className={cn('project-list-item__column', 'project-list-item__column_count')}>{experimentCount}</div>
        <div className={cn('project-list-item__column', 'project-list-item__column_owner')}>
          <MemberList name={projectOwner} />
          <div className={cn('project-list-item__member-name')}>
            <Highlighter textToHighlight={projectOwner} searchWords={formatHighlightSearchQuery(searchQuery)} />
          </div>
        </div>
        <div className={cn('project-list-item__column', 'project-list-item__column_team')}>
          <MemberList nameList={collaborators} />
        </div>
        <div className={cn('project-list-item__column', 'project-list-item__column_created-at')}>
          {formatDate(dateCreated)}
        </div>
        <div className={cn('project-list-item__column', 'project-list-item__column_modified-at')}>
          {formatDate(dateModified)}
        </div>
        <div className={cn('project-list-item__column', 'project-list-item__column_icon-wrap')}>
          <icons.ProjectCardArrowIcon />
        </div>
      </Link>
    </ProjectOptionsPopoverWrapper>
  );
};

export default ProjectListItem;
