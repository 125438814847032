import { FC, ReactNode, createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { getChartDataListFromIdList } from '@/helpers/datasetDetails';
import { b64DecodeUnicode, parseJSON, removeDuplicates } from '@/helpers';
import { findDefaultScanInScanList, findLaneInScanList, findScanInScanList } from '@/helpers/scans';

import { useAppDispatch } from '@/hooks/useAppDispatch';

import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { experimentActions, experimentSelectors } from '@/store/slices/experiment';
import { chartDataActions, chartDataSelectors } from '@/store/slices/chartData';

import { useObjectEntitiesByLanesAndGates } from './useObjectEntitiesByLanesAndGates';
import { useCageEntitiesByLanesAndGates } from './useCageEntitiesByLanesAndGates';
import { useGateList } from './useGateList';

type TExperimentContext = {
  changeCurrentAppLane: (dataset: TLane) => void;
  currentAppDatasetList: Array<TDataset>;
  isAppDatasetListReady: boolean;
  chartDataList: TDatasetDetails[];
  selectedChartData: Nullable<TDatasetDetails>;
  changeSelectedChartData: (chartData: Nullable<TDatasetDetails>) => void;
  isAllLanesDataShow: boolean;
  toggleAllLanesDataShow: () => void;
  currentExperimentAssays: Record<string, TAssay[]>;

  entitiesByLanesAndGates: TEntitiesByLanesAndGates;
  cageEntitiesByLanesAndGates: TEntitiesByLanesAndGates;
  entitiesLoadedLaneList: string[];

  isError: boolean;
};

const ExperimentContext = createContext<TExperimentContext>({} as TExperimentContext);

export const useExperimentContext = (): TExperimentContext => useContext(ExperimentContext);

type TExperimentContextProviderProps = {
  children: ReactNode;
};

export const ExperimentContextProvider: FC<TExperimentContextProviderProps> = ({ children }) => {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const { experimentId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const scanId = searchParams.get('scanId') ?? '';
  const laneId = searchParams.get('laneId') ?? '';
  const channelName = searchParams.get('channelName') ?? '';
  const markerName = searchParams.get('markerName') ?? '';
  const datasetsData = searchParams.get('datasets') ?? '';

  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const isCageLvlForced = useSelector(chartSettingsSelectors.selectIsCageLvlForced(chartId));

  const scanList = useSelector(experimentSelectors.selectCurrentScanList);
  const allLaneList = useSelector(experimentSelectors.selectAllLaneList);
  const currentAppLane = useSelector(experimentSelectors.selectCurrentLane);
  const currentAppDataset = useSelector(experimentSelectors.selectCurrentDataset);
  const currentChartData = useSelector(chartDataSelectors.selectCurrentChartData);
  const { fetchExperimentGateList, objectLevelGateList, cageLevelGateList } = useGateList(experimentId);

  const [isAllLanesDataShow, setAllLanesDataShow] = useState(true);
  const [previousAppLane, setPreviousAppLane] = useState<Nullable<TLane>>(null);
  const [currentAppDatasetList, setCurrentAppDatasetList] = useState<Array<TDataset>>([]);
  const [isAppDatasetListReady, setIsAppDatasetListReady] = useState(false);
  const [chartDataList, setChartDataList] = useState<TDatasetDetails[]>([]);
  const [currentExperimentAssays, setCurrentExperimentAssays] = useState<Record<string, TAssay[]>>({});

  const isObjectEntityLevel = useMemo(
    () => isObjectEntityEnabled && !isCageLvlForced,
    [isObjectEntityEnabled, isCageLvlForced]
  );

  const { objectEntitiesByLanesAndGates, objectEntitiesLoadedLaneList } = useObjectEntitiesByLanesAndGates({
    experimentId,
    currentAppDatasetList,
    currentAppDataset,
    gateList: objectLevelGateList,
    isForceUpdateEntities: false,
    chartDataList,
  });

  const { cageEntitiesByLanesAndGates, cageEntitiesLoadedLaneList, isError } = useCageEntitiesByLanesAndGates({
    experimentId,
    currentAppDatasetList,
    currentAppDataset,
    gateList: cageLevelGateList,
    chartDataList,
  });

  const handleCurrentAppLane = (lane: Nullable<TLane>) => {
    if (lane?.path === currentAppLane?.path) {
      return;
    }
    const scan = lane ? findScanInScanList(scanList, lane.dataset.scanId) : null;
    appDispatch(experimentActions.setCurrentScanId(scan?.id));
    setPreviousAppLane(currentAppLane);
    appDispatch(experimentActions.setCurrentLaneId(lane?.id));
  };

  const changeCurrentAppLane = (lane: Nullable<TLane>) => {
    setAllLanesDataShow(false);
    const correctLane = allLaneList.find((el) => el.id === lane?.id) ?? null;
    handleCurrentAppLane(correctLane);
  };

  const changeCurrentAppDataset = (dataset: TDataset) => {
    if (dataset.path === currentAppDataset?.path) {
      return;
    }
    const lane = findLaneInScanList(scanList, dataset.scanId, dataset.laneId);
    const scan = findScanInScanList(scanList, dataset.scanId);
    appDispatch(experimentActions.setCurrentScanId(scan?.id));
    setPreviousAppLane(lane);
    appDispatch(experimentActions.setCurrentLaneId(lane?.id));
  };

  const changeSelectedChartData = (chartData: Nullable<TDatasetDetails>) => {
    appDispatch(chartDataActions.setCurrentChartData(chartData));
    if (chartData?.dataset) {
      changeCurrentAppDataset(chartData.dataset);
    }
  };

  const toggleAllLanesDataShow = () => {
    if (!isAllLanesDataShow) {
      setPreviousAppLane(currentAppLane);
      handleCurrentAppLane(allLaneList[0]);
    } else {
      handleCurrentAppLane(previousAppLane);
    }

    setAllLanesDataShow((value) => !value);
  };

  const updateCurrentAppDatasetList = (chartDataIdsList: TDatasetDetailsIdData[]) => {
    // todo: Remove Promise.resolve().then when find the cause of the issue that charts are drawn with delay when navigating from a single chart page
    Promise.resolve().then(() => {
      const selectedChartDataList = getChartDataListFromIdList(scanList, chartDataIdsList);
      setCurrentAppDatasetList(selectedChartDataList.map((details) => details.dataset));
      setChartDataList(selectedChartDataList);
      changeSelectedChartData(selectedChartDataList[0] ?? null);
      setIsAppDatasetListReady(true);
    });
  };

  const experimentContextData = useMemo(
    () => ({
      changeCurrentAppLane,
      currentAppDatasetList,
      isAppDatasetListReady,
      chartDataList,
      selectedChartData: currentChartData,
      changeSelectedChartData,
      isAllLanesDataShow,
      toggleAllLanesDataShow,
      currentExperimentAssays,

      entitiesByLanesAndGates: isObjectEntityLevel ? objectEntitiesByLanesAndGates : cageEntitiesByLanesAndGates,
      entitiesLoadedLaneList: isObjectEntityLevel ? objectEntitiesLoadedLaneList : cageEntitiesLoadedLaneList,
      cageEntitiesByLanesAndGates,

      isError,
    }),
    [
      changeCurrentAppLane,
      currentAppDatasetList,
      isAppDatasetListReady,
      chartDataList,
      currentChartData,
      changeSelectedChartData,
      isAllLanesDataShow,
      toggleAllLanesDataShow,
      currentExperimentAssays,

      objectEntitiesByLanesAndGates,
      objectEntitiesLoadedLaneList,
      cageEntitiesByLanesAndGates,
      cageEntitiesLoadedLaneList,

      isError,
    ]
  );

  useEffect(() => {
    const experimentAssays = scanList.reduce((acc, scan) => {
      const arr: TAssay[] = [];
      scan.lanes?.forEach((lane) => {
        Object.values(lane.channelsProperties ?? {}).forEach((prop) => {
          if (!prop.assay) {
            return;
          }
          arr.push(prop.assay);
        });
      });
      acc[scan.id] = removeDuplicates(arr, 'id');
      return acc;
    }, {} as Record<string, TAssay[]>);

    setCurrentExperimentAssays(experimentAssays);
  }, [scanList]);

  useEffect(() => {
    if (scanId && laneId) {
      // dataset page
      const lane = findLaneInScanList(scanList, scanId, laneId);
      handleCurrentAppLane(lane);
      const marker = markerName ? { name: markerName } : undefined;
      updateCurrentAppDatasetList([{ scanId, laneId, channelName, marker }]);
      appDispatch(experimentActions.setCurrentLaneId(laneId));
      appDispatch(experimentActions.setCurrentScanId(scanId));
      fetchExperimentGateList();
    } else if (datasetsData) {
      // analytics page
      const chartDataIdsList = parseJSON(b64DecodeUnicode(datasetsData));
      updateCurrentAppDatasetList(chartDataIdsList ?? []);
      fetchExperimentGateList();
    } else {
      // experiment page
      const defaultScan = findDefaultScanInScanList(scanList);
      const laneList = defaultScan?.lanes ?? [];
      const lane = laneList.length > 0 ? laneList[0] : null;
      appDispatch(experimentActions.setCurrentScanId(defaultScan?.id));
      setPreviousAppLane(lane);
      appDispatch(experimentActions.setCurrentLaneId(lane?.id));
    }
  }, [scanList, scanId, laneId, channelName, markerName, datasetsData]);

  return <ExperimentContext.Provider value={experimentContextData}>{children}</ExperimentContext.Provider>;
};
