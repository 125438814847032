import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AXES_DEFAULT_PRESET } from '@/helpers';

import { useAppDispatch } from '@/hooks/useAppDispatch';
import { usePlotChartIdContext } from '@/contexts/PlotChartIdContext';
import { getScatterPlotObjectLevelAxesOptions } from '@/helpers/channels';

import { chartSettingsSelectors } from '@/store/slices/chartSettings';
import { scatterplotsActions, EAxesGroupName, TAxes, scatterplotsSelectors } from '@/store/slices/scatterplots';
import { selectAxesOptionListForMultiLanes } from '@/store/services/app/selectors';
import { isBasicOption } from '@/components/common/Select/helpers';

import { EChartType } from '@/types/charts';
import { chartDataSelectors } from '@/store/slices/chartData';

export function useMultiHistogramSettingsPreset(chartDataList: TDatasetDetails[]) {
  const appDispatch = useAppDispatch();
  const chartId = usePlotChartIdContext();

  const isObjectEntityEnabled = useSelector(chartSettingsSelectors.selectIsObjectEntityEnabled(chartId));
  const cageLevelAxesOptionListByLanes = useSelector(chartSettingsSelectors.selectCageLevelAxesOptionListByLanes);
  const currentChartData = useSelector(chartDataSelectors.selectCurrentChartData);
  const generalAxes = useSelector(scatterplotsSelectors.selectAxesByGroup(EAxesGroupName.general)) as Record<
    string,
    TAxes
  >;
  const isCageLvlForced = useSelector(chartSettingsSelectors.selectIsCageLvlForced(chartId));

  const { optionList: cageLevelMultiLanesAxesOptionList } = useSelector(
    chartSettingsSelectors.selectMultiLanesAxesOptionList(chartDataList, isObjectEntityEnabled && !isCageLvlForced)
  );

  const currentPlotAxesOptionList = useMemo(
    () =>
      isObjectEntityEnabled
        ? chartDataList.flatMap((chartData) => getScatterPlotObjectLevelAxesOptions(chartData.channelList))
        : cageLevelMultiLanesAxesOptionList,
    [isObjectEntityEnabled, chartDataList]
  );

  const defineXAxisPreset = useCallback(
    (plotAxesOptionList: TOption[]): Nullable<string> => {
      if (isObjectEntityEnabled) {
        const xAxisFromGeneral = generalAxes?.[currentChartData?.name ?? '']?.x;
        const optionListForMultiLines = selectAxesOptionListForMultiLanes(chartDataList, isObjectEntityEnabled);

        const xAxisFromGeneralInOptionList = optionListForMultiLines.find(
          (axesOption) => axesOption.value === xAxisFromGeneral
        );

        if (xAxisFromGeneralInOptionList?.value) return xAxisFromGeneralInOptionList.value.toString();

        return AXES_DEFAULT_PRESET.xAxis;
      }

      if (!plotAxesOptionList.length) return null;

      const axesDataFromGeneral = generalAxes?.[currentChartData?.id ?? ''];

      if (axesDataFromGeneral?.x && !axesDataFromGeneral.isObjectEntityEnabled) {
        const xAxisFromGeneralInOptionList = plotAxesOptionList.find(
          (axesOption) => isBasicOption(axesOption) && axesOption.value === axesDataFromGeneral.x
        );

        if (xAxisFromGeneralInOptionList && isBasicOption(xAxisFromGeneralInOptionList))
          return xAxisFromGeneralInOptionList?.value.toString();
      }
      return isBasicOption(plotAxesOptionList[0]) ? plotAxesOptionList[0].value.toString() : null;
    },
    [generalAxes, currentChartData, isObjectEntityEnabled]
  );

  useEffect(() => {
    if (chartDataList.length === 0) {
      return;
    }

    const newAxes: TAxes = {};

    const presetedXAxis = defineXAxisPreset(currentPlotAxesOptionList);

    if (presetedXAxis) {
      newAxes.x = presetedXAxis;
    }

    appDispatch(
      scatterplotsActions.setAxes({
        axesGroupName: EAxesGroupName.multiHistogram,
        newAxes: { ...newAxes, isObjectEntityEnabled },
      })
    );
  }, [chartDataList, isObjectEntityEnabled, cageLevelAxesOptionListByLanes, currentPlotAxesOptionList]);

  return useMemo(
    () => ({
      isReady: true,
      plotType: EChartType.lineHistogram,
      colorScale: 'schemeTableau10',
      histogramPreset: {
        isChartFillEnabled: false,
      },
    }),
    []
  );
}
