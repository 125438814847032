import { FC, ReactNode, memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames/bind';
import { toast } from 'react-toastify';

import { useExperimentContext, useUserRole } from '@/hooks';

import { formatDate, formatNumber, formatExperimentDuration, removeDuplicates, DOUBLE_DASH } from '@/helpers';
import { getErrorMessage, showErrorToast } from '@/helpers/errors';
import { getExperimentProjectsDataToDisplay, getInstrumentDashboardLink } from '@/helpers/experiment/summary';

import { datasetsSelectors } from '@/store/slices/datasets';
import { experimentSelectors } from '@/store/slices/experiment';
import { appAPI } from '@/store/services/app';

import PageHeader from '@/components/Layout/Page/PageHeader';
import Portal from '@/components/common/Portal';
import Metadata from '@/components/common/Metadata';
import StatusesInfo from '@/components/experiment/StatusesInfo';

import styles from './ExperimentPageHeader.module.scss';
import IconsAssaySet from '../IconsAssaySet';
import NoDataFound from '../NoDataFound';
import LinkElement from '../LinkElement';

const cn = classnames.bind(styles);

const defaultPortalId = 'layout-page-header';

type TExperimentPageHeader = {
  experimentData?: TExperimentFromServer;
  title: string;
  experimentControls?: ReactNode | ReactNode[];
  isBasicInfoHidden?: boolean;
  totalCages?: number;
  portalId?: string;
  isEditableHeader?: boolean;
};

const ExperimentPageHeader: FC<TExperimentPageHeader> = ({
  experimentData,
  title,
  experimentControls,
  isBasicInfoHidden = false,
  totalCages,
  portalId = defaultPortalId,
  isEditableHeader = false,
}) => {
  const { currentExperimentAssays } = useExperimentContext();
  const assaysProperties = useSelector(datasetsSelectors.selectAssayProperties(experimentData?.id ?? ''));
  const { isInstrumentReadAllowed } = useUserRole();
  const cagingScan = useSelector(experimentSelectors.selectCagingScan);

  const instrumentDashboardLink = useMemo(() => getInstrumentDashboardLink(experimentData), [experimentData]);

  const basicInfo = [
    {
      id: 0,
      title: 'Operator',
      data: experimentData?.operator ?? <NoDataFound alignment="left" textData={DOUBLE_DASH} />,
    },
    {
      id: 1,
      title: 'Instrument',
      data:
        experimentData?.instrument?.friendlyName && instrumentDashboardLink ? (
          <LinkElement
            title={experimentData?.instrument?.friendlyName}
            link={instrumentDashboardLink}
            disabled={!isInstrumentReadAllowed}
          />
        ) : (
          <NoDataFound alignment="left" textData={DOUBLE_DASH} />
        ),
    },
    {
      id: 2,
      title: 'Date',
      data: formatDate(experimentData?.dateCreated, 'MMMM d, yyyy') ?? (
        <NoDataFound alignment="left" textData={DOUBLE_DASH} />
      ),
    },
    {
      id: 3,
      title: 'Duration',
      data: experimentData?.duration ? (
        formatExperimentDuration(experimentData?.duration)
      ) : (
        <NoDataFound alignment="left" textData={DOUBLE_DASH} />
      ),
    },
    { id: 4, title: 'Scans total', data: experimentData?.numberOfScans ?? 0 },
  ];

  const assayList = useMemo(() => {
    const assays = removeDuplicates<TAssay>(Object.values(currentExperimentAssays).flat(), 'id');
    const assaysWithProps = assays.map((el) => ({
      ...el,
      properties: assaysProperties.get(el.id),
    }));
    return assaysWithProps;
  }, [currentExperimentAssays, assaysProperties]);

  const [updateExperimentName] = appAPI.useUpdateExperimentNameMutation();

  const onSaveClick = (newExperimentName: string) => {
    if (!experimentData?.id) return;

    updateExperimentName({
      experimentId: experimentData?.id,
      name: newExperimentName,
    })
      .unwrap()
      .then(() => toast.success('The experiment name has been updated'))
      .catch((error) => {
        showErrorToast(getErrorMessage(error));
      });
  };

  const [projectNames, projectListPluralSymbol] = useMemo(
    () => getExperimentProjectsDataToDisplay(experimentData?.projects),
    [experimentData?.projects]
  );

  return (
    <Portal portalId={portalId}>
      <PageHeader className={cn('experiment-page-header')}>
        <PageHeader.Section>
          <PageHeader.Title
            as="h1"
            wrapperClassName={cn('experiment-page-header__title-block')}
            statusBadge={
              experimentData && (
                <StatusesInfo experiment={experimentData} className={cn('experiment-page-header__status')} />
              )
            }
            isEditable={isEditableHeader}
            onSave={onSaveClick}
            title={title}
          />
          <div className={cn('experiment-page-header__controls')}>{experimentControls}</div>
        </PageHeader.Section>

        <PageHeader.Section>
          <PageHeader.Title isStatistics>{formatNumber(totalCages)}</PageHeader.Title>
          <PageHeader.Subtitle
            infoTooltip={`Number of cages is coming from <b>the caging scan ${
              cagingScan?.time ? `${cagingScan.time}</b>` : ''
            }`}
          >
            Cages total
          </PageHeader.Subtitle>
          {!isBasicInfoHidden && (
            <Metadata className={cn('experiment-page-header__metadata')}>
              {basicInfo.map((elem) => (
                <Metadata.Item title={elem.title} description={elem.data} key={elem.id} />
              ))}
            </Metadata>
          )}
        </PageHeader.Section>

        <PageHeader.Section>
          <div className={cn('experiment-page-header__assay-info')}>
            <div>
              <PageHeader.Title isStatistics>{formatNumber(experimentData?.numberOfSamples)}</PageHeader.Title>
              <PageHeader.Subtitle>Samples total</PageHeader.Subtitle>
            </div>

            <IconsAssaySet
              className={cn('experiment-page-header__assay-icons')}
              assayList={assayList}
              color="yellow"
              border="default"
              withPreprocessingIcon
            />
          </div>

          {!isBasicInfoHidden && projectNames.trim() && (
            <Metadata className={cn('experiment-page-header__metadata', 'experiment-page-header__metadata_second')}>
              <Metadata.Item
                title={`Project${projectListPluralSymbol}`}
                description={projectNames ?? <NoDataFound alignment="left" textData={DOUBLE_DASH} />}
              />
            </Metadata>
          )}
        </PageHeader.Section>
      </PageHeader>
    </Portal>
  );
};

export default memo(ExperimentPageHeader);
