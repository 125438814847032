import type { TInitialHistogramSettingsState } from '@/store/slices/histogramSettings';
import { EPageWithChartType, type EChartType } from '@/types/charts';

import type { TPreprocessingAssay } from './preprocessing';

export const NAVIGATOR_ZOOM_STEP = 0.25;
export const MIN_NAVIGATOR_ZOOM = 1;
export const MAX_NAVIGATOR_ZOOM = 5;
export const DISPLAY_CONTOURS_NAVIGATOR_ZOOM = 2;
export const NDASH = '\u2013';
export const MDASH = '\u2014';
export const DOUBLE_DASH = '\u254C';
export const CELSIUS = '°C';
export const FAHRENHEIT = '°F';

export const LAST_EDIT = {
  LANE: 'lane',
  ARROW_CONTROL: 'arrowControl',
  MINIMAP: 'minimap',
  GRID_MINIMAP: 'grid-minimap',
  VIEWER: 'viewer',
  INPUT: 'input',
  SEARCH: 'search',
};

export const GRID_MINIMAP_ROWS = 5;
export const GRID_MINIMAP_COLUMNS = 75;
export const MINI_GRID_MINIMAP_ROWS = 3;
export const MINI_GRID_MINIMAP_COLUMNS = 13;

export const AXES_DEFAULT_PRESET = {
  xAxis: 'perimeter_area_ratio',
  yAxis: 'eccentricity',
};

export const GATE_API_VALIDATION_ERROR = {
  invalidId: 'Invalid gate id',
  invalidGate: 'Invalid new gate data types',
  invalidGateList: 'Invalid new gate list data types',
};

export enum EPreprocessingAssayType {
  cytokineSecretion = 'Cytokine Secretion',
  cellKilling = 'Cell Killing',
}

export const PREPROCESSING_ASSAYS: TPreprocessingAssay[] = [
  {
    idByProcessType: EPreprocessingAssayType.cytokineSecretion,
    name: 'Cytokine secretion',
  },
  {
    idByProcessType: EPreprocessingAssayType.cellKilling,
    name: 'Cell killing',
  },
];

export const SURFACE_RECEPTOR_ASSAY = {
  idByProcessType: 'Surface Receptor',
  name: 'Surface receptor',
};

export type TChartSettingsPreset = {
  currentChartType: EChartType;
  histogramPreset: Partial<TInitialHistogramSettingsState>;
};

export const PREPROCESSING_ASSAYS_BY_ASSAY = PREPROCESSING_ASSAYS.reduce((acc, el) => {
  acc[el.name] = el;
  return acc;
}, {} as Record<string, TPreprocessingAssay>);

export const CELL_KILLING_PROCESSED_DATASET_XAXIS = 'TARGET_CELLS_DEAD_gate_obj_count';

export const TOAST_DELAY = 5000;

export const PREPROCESSING_ASSAY_TYPE_LIST: string[] = [
  EPreprocessingAssayType.cellKilling,
  EPreprocessingAssayType.cytokineSecretion,
];

export const PAGE_TYPES_WITH_HIDDEN_Y_TICK_LABELS = [EPageWithChartType.matrixView, EPageWithChartType.multiHistogram];

export const DRAGGABLE_TYPES = {
  matrixCard: 'matrixCard',
};

export const SortType = {
  asc: 'asc',
  desc: 'desc',
} as const;

export type TSortType = keyof typeof SortType;

export const MemberRole = {
  admin: 'admin',
  maintainer: 'maintainer',
  viewer: 'viewer',
} as const;

export type TMemberRole = keyof typeof MemberRole;

export const LANE_LETTER_NAME_LIST = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];

export const EMPTY_ARRAY = [];
