import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames/bind';
import 'react-datepicker/dist/react-datepicker.css';
import { Popover as TinyPopover } from 'react-tiny-popover';
import isEqual from 'lodash.isequal';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';
import CheckboxInput from '@/components/common/CheckboxInput';
import styles from './OverlaySensorGraphsPopover.module.scss';

const cn = classnames.bind(styles);

type TDateRangeProps = {
  handleSelectedSensorsChange: (newValues: string[]) => void;
  sensorList: TTelemetryByInstrument[];
  selectedSensorValues: string[];
  disabled?: boolean;
};

const MAX_SENSORS = 2;

const OverlaySensorGraphsPopover: FC<TDateRangeProps> = ({
  handleSelectedSensorsChange,
  sensorList,
  selectedSensorValues,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [newSelectedSensors, setNewSelectedSensors] = useState<string[]>(selectedSensorValues);

  const isChanged = useMemo(
    () => !isEqual(newSelectedSensors, selectedSensorValues),
    [newSelectedSensors, selectedSensorValues]
  );

  const openPopover = useCallback(() => setIsOpen(true), []);
  const closePopover = useCallback(() => setIsOpen(false), []);

  const onCheckFactory = (newSensor: TTelemetryByInstrument) => () => {
    setNewSelectedSensors((prev) => {
      const isSelected = prev.includes(String(newSensor.name));

      if (isSelected) {
        return prev.filter((value) => value !== newSensor.name);
      }

      return [...prev, String(newSensor.name)];
    });
  };

  const onSave = () => {
    handleSelectedSensorsChange(newSelectedSensors);
    closePopover();
  };

  const onClose = () => {
    setNewSelectedSensors(selectedSensorValues);
    closePopover();
  };

  useEffect(() => {
    setNewSelectedSensors(selectedSensorValues);
  }, [selectedSensorValues]);

  return (
    <TinyPopover
      onClickOutside={onClose}
      isOpen={isOpen}
      align="end"
      padding={5}
      positions={['right', 'left', 'bottom']}
      content={
        <div className={cn('popover-content')}>
          <div className={cn('list-wrapper')}>
            <ul className={cn('list')}>
              {sensorList.map((sensor) => (
                <li className={cn('list__item')} key={sensor.name}>
                  <CheckboxInput
                    checked={newSelectedSensors.includes(sensor.name)}
                    onChange={onCheckFactory(sensor)}
                    theme="light"
                    label={sensor.label}
                    className={cn('list__item-checkbox')}
                    multiline
                    disabled={newSelectedSensors.length === MAX_SENSORS && !newSelectedSensors.includes(sensor.name)}
                  />
                </li>
              ))}
            </ul>
          </div>
          <div className={cn('buttons')}>
            <Button onClick={onClose} isFitContent isOutlined className={cn('buttons__item', 'close-btn')}>
              Cancel
            </Button>
            <Button
              disabled={disabled || !isChanged}
              isFitContent
              isOutlined
              onClick={onSave}
              className={cn('buttons__item', 'overlay-btn')}
            >
              Overlay
            </Button>
          </div>
        </div>
      }
      containerStyle={{ marginTop: '20px' }}
      boundaryInset={40}
      reposition
    >
      {/* without div here popover is drawn far from button and JS error fails:
       Warning: Function components cannot be given refs. Attempts to access this ref will fail */}
      <div>
        <Button onClick={openPopover} className={cn('popover-btn')}>
          <span>Overlay sensor graphs</span>
          <icons.ArrowDropdownIcon />
        </Button>
      </div>
    </TinyPopover>
  );
};

export default memo(OverlaySensorGraphsPopover);
