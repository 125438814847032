import { FC, useState, useRef, FocusEvent } from 'react';
import classnames from 'classnames/bind';

import icons from '@/components/common/icons';
import Button from '@/components/common/Button';
import { addWordBreakAfterUnderscores, removeZeroWidthSpace } from '@/helpers';
import { useKeyDown } from '@/hooks/useKeyDown';
import { useDebounce } from '@/hooks';

import styles from './EditableTitle.module.scss';

const cn = classnames.bind(styles);

type TEditableTitle = {
  title: string;
  className?: string;
  onSave?: (newTitle: string) => void;
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
};

const EditableTitle: FC<TEditableTitle> = ({ title, className, onSave, as: Tag = 'h2' }) => {
  const [isEditMode, setIsEditMode] = useState(false);

  const debouncedEditMode = useDebounce(isEditMode, 100);
  const titleRef = useRef<Nullable<HTMLHeadingElement>>(null);
  const containerRef = useRef<Nullable<HTMLDivElement>>(null);

  const enableEditMode = () => {
    if (isEditMode) return;

    setIsEditMode(true);
    titleRef.current?.focus();
  };

  const disableEditMode = () => {
    setIsEditMode(false);

    if (titleRef.current) {
      titleRef.current.innerText = addWordBreakAfterUnderscores(title);
    }

    titleRef.current?.blur();
  };

  const handleSaveClick = () => {
    const titleWithoutWordBreak = removeZeroWidthSpace(title);
    const newTitleWithoutWordBreak = removeZeroWidthSpace(titleRef?.current?.innerText ?? title);

    if (titleRef.current && !newTitleWithoutWordBreak.trim()) {
      titleRef.current.innerText = addWordBreakAfterUnderscores(title);
    }

    if (titleWithoutWordBreak !== newTitleWithoutWordBreak) {
      onSave?.(newTitleWithoutWordBreak);
    }

    setIsEditMode(false);
  };

  const onTitleKeyDown = async (event: React.KeyboardEvent<HTMLHeadingElement>) => {
    if (event.key !== 'Enter') return;

    if (title === titleRef.current?.innerText) {
      event.preventDefault();
      setIsEditMode(false);
      titleRef.current?.blur();
      return;
    }

    handleSaveClick();
    titleRef.current?.blur();
  };

  const onBlur = (event: FocusEvent<HTMLHeadingElement, Element>) => {
    if (event.relatedTarget && containerRef.current?.contains(event.relatedTarget)) {
      event.preventDefault();
      return;
    }

    disableEditMode();
  };

  useKeyDown('Escape', disableEditMode);

  return (
    <div className={cn('editable-title')} ref={containerRef}>
      <div className={cn('editable-title__buttons')}>
        {debouncedEditMode ? (
          <>
            <Button
              className={cn('editable-title__btn', 'edit-btn')}
              onClick={disableEditMode}
              tooltip="Close"
              color="white"
            >
              <icons.CloseIcon width={15} height={12} />
            </Button>
            <Button className={cn('editable-title__btn')} onClick={handleSaveClick} tooltip="Save" color="white">
              <icons.CheckMarkIcon width={15} />
            </Button>
          </>
        ) : (
          <Button className={cn('editable-title__btn')} onClick={enableEditMode} tooltip="Edit title" color="white">
            <icons.EditIcon width={20} />
          </Button>
        )}
      </div>
      <Tag
        contentEditable="true"
        suppressContentEditableWarning
        tabIndex={0}
        onFocus={enableEditMode}
        onBlur={onBlur}
        onKeyDown={onTitleKeyDown}
        className={cn('editable-title__title', className)}
        ref={titleRef}
      >
        {addWordBreakAfterUnderscores(title)}
      </Tag>
    </div>
  );
};

export default EditableTitle;
